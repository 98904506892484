let key = {};

let toasterOption = {
  position: "top-right",
  autoClose: 8000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
let Envname = "prodction";

if (Envname === "prodction") {
  console.log("Set demo Config");
  const API_URL = "https://mob.queenbots.com";
  key = {
    baseUrl: `${API_URL}`,
    frontend: "https://app.queenbots.com",
    ContractAddress: "0x86dDAF32405E01d6A9785dD6Dc89cf33D2c46e7C",
    BUSDContractAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    qbttokenAddress: "0xef048634a3583386d09ffad8b445c7c5a97ce179",
    NetworkId: 56,
    rpcUrl: "https://bsc-dataseed1.binance.org",
  };
} else if (Envname === "demo") {
  console.log("Set demo Config");
  const API_URL = "https://dexbotapi.wealwin.com"; //DEMO
  key = {
    baseUrl: `${API_URL}`,
    frontend: "https://dex-bot-frontend.pages.dev",
    ContractAddress: "0x391e2dAFc7240a77519BE6a56c2bDBd2f4DA6729",
    BUSDContractAddress: "0x4B4Bf5D871Cf6eC659F6a16fe9129f8F1EdF27d3",
    qbttokenAddress: "0xfb0f99d13036f2503b93fd05858796cee5f5451c",
    NetworkId: 97,
    rpcUrl: "https://data-seed-prebsc-1-s2.binance.org:8545",
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost";
  key = {
    baseUrl: `${API_URL}:3040`,
    frontend: "http://localhost:3000",
    ContractAddress: "0x391e2dAFc7240a77519BE6a56c2bDBd2f4DA6729",
    BUSDContractAddress: "0x4B4Bf5D871Cf6eC659F6a16fe9129f8F1EdF27d3",
    qbttokenAddress: "0xfb0f99d13036f2503b93fd05858796cee5f5451c",
    NetworkId: 97,
    rpcUrl: "https://data-seed-prebsc-1-s2.binance.org:8545",
  };
}

export default key;

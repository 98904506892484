import React, { useEffect, useState, useMemo } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import DataTable from "react-data-table-component";
import Web3 from "web3";
import ContractABI from "../ABI/DexbotABI.json";

import { toast } from "react-toastify";
import config from "../lib/config/config";
import isEmpty from "../helper/isEmpty.js";
import {
  KYCVerifications,
  KYCMobileVerify,
  KYCOTPVerify,
  PersonalDetailsKYC,
  IdProofKYC,
  AddressProofKYC,
} from "../Actions/user";
import { connection } from "../helper/connection";
import { useDispatch, useSelector } from "react-redux";
import { Country } from "country-state-city";
import ReactLoading from "react-loading";
import {
  emailRegex,
  namereg,
  numReg,
  specialreg,
  addressreg,
} from "../helper/valRegex.js";

let toasterOption = config.toasterOption;

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Kyc(props) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const [idproof, setidproof] = useState("");
  const [personal, setpersonal] = useState("");
  const [Address, setAddress] = useState("");
  const [selfie_status, setSelfie] = useState("");
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [countrycode, setCountrycode] = useState();
  const [Verifyotp, setVerifyotp] = useState(false);
  const [verify, setverify] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [JoinNowstatus, setJoinNowstatus] = useState(false);
  const [OtpData, setOtpData] = useState("");
  const [state, setState] = useState(false);
  const [option, setOption] = useState(1);
  const [select, setSelect] = useState("");
  const [reason, setreason] = useState("");
  const [selfiereason, setselfiereason] = useState("");
  const [detailNumber, setDetailNumber] = useState("");
  const [frontImage, setFrontImage] = useState("");
  const [file, setfile] = useState("");
  const [file1, setfile1] = useState("");
  const [file3, setfile3] = useState("");
  const [backImage, setBackImage] = useState("");
  const [selfieImage, setSelfieImage] = useState("");
  const [idstatus, setidstatus] = useState("");
  const [data1, setdata1] = useState("");
  const [address, setAddressdetail] = useState("");

  const countryDetails = Country.getAllCountries();

  var countryArr = [];
  countryDetails.map((data, i) => {
    countryArr.push({
      value: data.phonecode,
      label: data.name,
      name: data.name,
    });
  });

  useEffect(() => {
    if (!isEmpty(walletConnection.address)) {
      CheckIsUser();
      // if (JoinNowstatus == true) {
      verification();
      // }
    }
  }, [walletConnection]);

  useEffect(() => {
    if (option == 1) {
      setSelect("Aadhar Card");
    } else if (option == 2) {
      setSelect("Driving License");
    } else if (option == 3) {
      setSelect("Country ID");
    } else if (option == 4) {
      setSelect("Passport");
    } else if (option == 5) {
      setSelect("Country ID");
    } else {
      setSelect("");
    }
  }, [option]);

  useEffect(() => {
    verification();
  }, [state]);

  async function CheckIsUser() {
    let address =
      walletConnection && walletConnection.address && walletConnection.network
        ? walletConnection.address
        : "";
    let provider =
      walletConnection && walletConnection.address && walletConnection.provider
        ? walletConnection.provider
        : undefined;

    try {
      if (typeof provider !== "undefined") {
        var web3 = new Web3(provider);
        var DexbotContract = await new web3.eth.Contract(
          ContractABI,
          config.ContractAddress
        );
        var isRefferal = await DexbotContract.methods.userData(address).call();
        console.log(isRefferal.isUser, "isRefferal");
        setJoinNowstatus(isRefferal.isUser);
      }
    } catch (err) {}
  }

  const verification = async () => {
    let address = {
      address: walletConnection.address,
    };
    const data = await KYCVerifications(address);
    console.log(data, "respDatajghdugf");
    if (!isEmpty(data.data)) {
      setpersonal(data.data.personal_status); // 1 page
      setidproof(data.data.idproof_status); //2 page
      setSelfie(data.data.selfie_status); //2 page
      setAddress(data.data.Address_status); //3 page
      setreason(data.data.idproof_reason);
      setselfiereason(data.data.selfie_reason);
      setidstatus(data.data.Id_status);
      setdata1(data.data.A_status);
    }
  };

  const countryChange = async (e) => {
    countryArr.map((item) => {
      if (item.label == e.target.value) {
        setCountry(e.target.value);
        setCountrycode(item.value);
      }
    });
  };

  const validPositive = (e) => {
    console.log(
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value),
      "valllluee"
    );
    if (
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  const Activate = async () => {
    if (country == "") {
      toast.error(`Kindly select your country`, toasterOption);
      return false;
    }
    console.log(mobileNumber.length, "dhgfyegrhwbfcuytd");

    if (mobileNumber.length != 10) {
      toast.error(`Invalid phone number`, toasterOption);
      return false;
    }

    if (country == "") {
      toast.error(`Kindly select your country`, toasterOption);
      return false;
    }

    if (
      countrycode != "" &&
      mobileNumber != "" &&
      walletConnection.address != ""
    ) {
      const Mobile = {
        address: walletConnection.address,
        mobile: mobileNumber,
        code: countrycode,
      };
      setErrors("");
      setLoader(true);
      try {
        const { data, status, message, error } = await KYCMobileVerify(Mobile);
        console.log(data, status, message, error, "jrfusghfusgu");
        setLoader(false);
        if (status == true) {
          setverify(true);
          toast.warning("OTP sent to your mobile", toasterOption);
        } else {
          toast.error(message, toasterOption);
          // toast.success(message, toasterOption);
        }
      } catch (err) {
        setLoader(false);
        console.log(err, "->>> error on KYCMobileVerify");
      }
    } else {
      toast.error(`Kindly connect wallet`, toasterOption);
    }
  };

  const Verify = async () => {
    const OTPdata = {
      address: walletConnection.address,
      otp: OtpData,
    };
    if (OtpData.length == 4) {
      try {
        setLoader(true);
        const { data, status, msg, error } = await KYCOTPVerify(OTPdata);
        console.log(data, status, msg, error, "data, status, msg, error");
        setLoader(false);
        if (status == "success") {
          setverify(false);
          setVerifyotp(true);
          toast.success("OTP verify successfully", toasterOption);
          setOtpData("");
        } else {
          toast.success("OTP not match !", toasterOption);
          setOtpData("");
        }
      } catch (err) {
        console.log(err, "->>> error on KYCOTPVerify");
      }
    } else {
      toast.error(` invalid OTP!.. `, toasterOption);
    }
  };

  const onSubmit = async () => {
    if (country == "") {
      toast.error("Kindly select your country", toasterOption);
      return false;
    } else if (name == "") {
      toast.error("Enter your name", toasterOption);
      return false;
    } else if (mobileNumber == "") {
      toast.error("Enter your mobile number", toasterOption);
      return false;
    } else if (!Verifyotp) {
      toast.error("Confirm to verify your mobile number", toasterOption);
      return false;
    } else if (email == "") {
      toast.error("Enter your email", toasterOption);
      return false;
    } else if (emailRegex.test(email) == false) {
      toast.error("Email is invalid", toasterOption);
      return false;
    } else {
      const params = {
        address: walletConnection.address,
        country: country,
        countrycode: countrycode,
        Name: name,
        Mobile: mobileNumber,
        email: email,
      };

      try {
        setLoader(true);
        const { data, status, message } = await PersonalDetailsKYC(params);
        console.log("PersonalDetailsKYC data", data);
        setLoader(false);
        if (status) {
          setState(data?.status);
          console.log(data, "data");
          toast.success(data?.message, toasterOption);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (!isEmpty(message) != undefined) {
            toast.error(data?.message, toasterOption);
          }
        }
      } catch (err) {
        console.log(err, "->>> error on PersonalDetailsKYC");
      }
    }
  };

  const uploadFrontImage = (e) => {
    var pickerResult = e.target.files;
    if (pickerResult[0].size > 5000000) {
      setFrontImage("");
      toast.error(
        "File size must be less than or equal to 5 mb",
        toasterOption
      );
      return false;
    } else if (
      pickerResult[0]?.name?.slice(pickerResult[0].name.length - 3) == "jpg" ||
      pickerResult[0]?.name?.slice(pickerResult[0].name.length - 3) == "png" ||
      pickerResult[0]?.name?.slice(pickerResult[0].name.length - 3) == "peg" ||
      pickerResult[0]?.name?.slice(pickerResult[0].name.length - 3) == "jpeg"
    ) {
      setFrontImage(pickerResult[0]);
      setfile(URL.createObjectURL(pickerResult[0]));
    } else {
      toast.error(
        "Invalid file format...Please choose valid format!",
        toasterOption
      );
    }
  };

  const uploadBackImage = (e) => {
    var pickerResult = e.target.files;
    if (pickerResult[0].size > 5000000) {
      setBackImage("");
      toast.error(
        "File size must be less than or equal to 5 mb",
        toasterOption
      );
      return false;
    } else if (
      pickerResult[0]?.name?.slice(pickerResult[0].name.length - 3) == "jpg" ||
      pickerResult[0]?.name?.slice(pickerResult[0].name.length - 3) == "png" ||
      pickerResult[0]?.name?.slice(pickerResult[0].name.length - 3) == "peg" ||
      pickerResult[0]?.name?.slice(pickerResult[0].name.length - 3) == "jpeg"
    ) {
      setBackImage(pickerResult[0]);
      setfile1(URL.createObjectURL(pickerResult[0]));
    } else {
      toast.error(
        "Invalid file format...Please choose valid format!",
        toasterOption
      );
    }
  };

  const selfieUpload = (e) => {
    var pickerResult = e.target.files;
    if (pickerResult[0].size > 5000000) {
      setSelfieImage("");
      toast.error(
        "File size must be less than or equal to 5 mb",
        toasterOption
      );
      return false;
    } else if (
      pickerResult[0]?.name?.slice(pickerResult[0].name.length - 3) == "jpg" ||
      pickerResult[0]?.name?.slice(pickerResult[0].name.length - 3) == "png" ||
      pickerResult[0]?.name?.slice(pickerResult[0].name.length - 3) == "peg" ||
      pickerResult[0]?.name?.slice(pickerResult[0].name.length - 3) == "jpeg"
    ) {
      setSelfieImage(pickerResult[0]);
      setfile3(URL.createObjectURL(pickerResult[0]));
    } else {
      toast.error(
        "Invalid file format...Please choose valid format!",
        toasterOption
      );
    }
  };

  const onSubmit1 = async () => {
    if (isEmpty(name)) {
      toast.error("Enter your name", toasterOption);
      return false;
    } else if (isEmpty(detailNumber)) {
      toast.error(`Enter your ${select} number`, toasterOption);
      return false;
    } else if (option == 1 && detailNumber.length != 16) {
      toast.error("Enter your valid number", toasterOption);
      return false;
    } else if (frontImage == "") {
      toast.error("Front image is required", toasterOption);
      return false;
    } else if (backImage == "") {
      toast.error("Back image is required", toasterOption);
      return false;
    } else if (selfieImage == "") {
      toast.error("Selfie proof is required", toasterOption);
      return false;
    } else {
      let params = {
        address: walletConnection.address,
        type: "Id_proof",
        idproof_type: select,
        idproof_Name: name,
        idproof_proofNumber: detailNumber,
        idproof_frontImage: frontImage,
        idproof_backImage: backImage,
        selfie_frontImage: selfieImage,
      };
      try {
        setLoader(true);
        const { data, status } = await IdProofKYC(params);
        console.log(data, status, "data, status");
        setLoader(false);
        if (status == "success") {
          setState(data.status);
          toast.success(data.message, toasterOption);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } catch (err) {
        setLoader(false);
        console.log(err, "error on ->>IdProofKYC");
      }
    }
  };

  const AddressProofSubmit = async () => {
    if (name == "") {
      toast.error("Enter your name", toasterOption);
      return false;
    } else if (address == "") {
      toast.error("Enter your Address", toasterOption);
      return false;
    } else if (frontImage == "") {
      toast.error("Front image is required", toasterOption);
      return false;
    } else if (backImage == "") {
      toast.error("Back image is required", toasterOption);
      return false;
    } else {
      let params = {
        address: walletConnection.address,
        type: "address_proof",
        Address_type: select,
        Address_Name: name,
        Address_proofNumber: address,
        Address_frontImage: frontImage,
        Address_backImage: backImage,
      };
      try {
        setLoader(true);
        const { data, status } = await AddressProofKYC(params);
        console.log(data, status, "tyrebgnuir");
        setLoader(false);
        if (status == "success") {
          setState(data.status);
          toast.success(data.message, toasterOption);
          setName("");
          setAddressdetail("");
          setFrontImage("");
          setBackImage("");
        }
      } catch (err) {
        setLoader(false);
        console.log(err, "error on ->>IdProofKYC");
      }
    }
  };

  return (
    <div>
      <div>
        {isLoader == true && (
          <ReactLoading type={"spin"} color={"#000000"} className="loading" />
        )}
      </div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="refferal_ban">
        <div className="container">
          <h2 class="main_title mb-0">KYC Verification</h2>
          {/* <h5 class="sub_title mb-5">
            Cras eleifend vehicula lectus id porta.
          </h5> */}
        </div>
        <div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
        </div>
      </div>

      {/* <section className="deposit_box">
          <div className="container">
           <div className="earn reff_box">
                <div className="form_flx d-block">
                <div className="w-100"> 
                  <div className="labl_flx mb-3">
                      <span>Withdraw Amount</span>
                      <span>Balance Amount: <sapn> 0</sapn> BUSD</span>
                    </div>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Enter BUSD Amount"  />
                    <span class="input-group-text bdr" id="basic-addon2">  
                    BUSD
                   </span>
                   <span class="input-group-text" id="basic-addon2">  
                     <button className="primary_btn m-0">MAX</button> 
                   </span>
                 </div>
                 </div>
                </div>
               <div className="text-center">
                <button className="primary_btn m-0 mb-3 pad">Submit</button> 
               </div>
           </div>
           </div>
      </section> */}

      <section className="history_tabs kyc">
        <div className="container">
          {/* <Scrollbars style={{ width: "100%", height: 45 }} className="mb-3" > */}
          <ul class="nav nav-tabs border-0 mb-3" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active "
                id="one-tab"
                data-bs-toggle="tab"
                data-bs-target="#one-tab-pane"
                type="button"
                role="tab"
                aria-controls="one-tab-pane"
                aria-selected="true"
              >
                Personal Details
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="two-tab"
                data-bs-toggle="tab"
                data-bs-target="#two-tab-pane"
                type="button"
                role="tab"
                aria-controls="two-tab-pane"
                aria-selected="false"
              >
                ID Proof
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="three-tab"
                data-bs-toggle="tab"
                data-bs-target="#three-tab-pane"
                type="button"
                role="tab"
                aria-controls="three-tab-pane"
                aria-selected="false"
              >
                {" "}
                Address Proof
              </button>
            </li>
            {/* <li class="nav-item" role="presentation">
             <button class="nav-link" id="four-tab" data-bs-toggle="tab" data-bs-target="#four-tab-pane" type="button" role="tab" aria-controls="four-tab-pane" aria-selected="false">Stake Withdraw</button>
         </li>
         <li class="nav-item" role="presentation">
             <button class="nav-link" id="five-tab" data-bs-toggle="tab" data-bs-target="#five-tab-pane" type="button" role="tab" aria-controls="five-tab-pane" aria-selected="false">Stake Mine Busd Reward</button>
          </li> 
          <li class="nav-item" role="presentation">
             <button class="nav-link" id="six-tab" data-bs-toggle="tab" data-bs-target="#six-tab-pane" type="button" role="tab" aria-controls="six-tab-pane" aria-selected="false">Stake Mine Level Reward</button>
          </li>  */}
          </ul>
          {/* </Scrollbars> */}
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="one-tab-pane"
              role="tabpanel"
              aria-labelledby="one-tab"
              tabindex="0"
            >
              {personal != "completed" ? (
                <>
                  <section className="deposit_box">
                    <div className="earn reff_box">
                      <h5 class="sub_title  mb-4 ps-1 text-light">
                        Personal Details
                      </h5>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form_flx d-block pb-0">
                            <div className="w-100">
                              <div className="labl_flx mb-3">
                                <span>Select your country of residence</span>
                              </div>
                              <div className="mb-3">
                                <select
                                  // type="text"
                                  class="select_country"
                                  id="country"
                                  name="country"
                                  onChange={countryChange}
                                  // value={country}
                                >
                                  <option
                                    style={{ background: "#111111" }}
                                    selected
                                  >
                                    Select Country
                                  </option>
                                  {countryArr.map((country) => {
                                    return (
                                      <option
                                        style={{ background: "#111111" }}
                                        value={country.label}
                                      >
                                        {country.label}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_flx d-block pb-0">
                            <div className="w-100">
                              <div className="labl_flx mb-3">
                                <span>Name</span>
                              </div>
                              <div className="mb-3">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={name}
                                  onChange={(e) => {
                                    if (namereg.test(e.target.value)) {
                                      setName(e.target.value);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_flx d-block">
                            <div className="w-100">
                              <div className="labl_flx mb-3">
                                <span>Mobile Number</span>
                              </div>
                              <div className="row">
                                <div className="col-md-8">
                                  <div class="mb-3">
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={mobileNumber}
                                      onInput={validPositive}
                                      onChange={(e) =>
                                        setMobileNumber(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                {!Verifyotp && (
                                  <div className="col-md-2">
                                    {console.log(
                                      JoinNowstatus,
                                      "JoinNowstatus"
                                    )}
                                    {JoinNowstatus == true &&
                                    walletConnection.address != "" ? (
                                      <>
                                        <button
                                          className="primary_btn m-0 mb-3"
                                          onClick={() => {
                                            Activate();
                                          }}
                                        >
                                          Verify
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          class="primary_btn m-0 mb-3"
                                          data-bs-toggle="modal"
                                          data-bs-target="#selectplan_modal"
                                        >
                                          Verify
                                        </button>
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form_flx d-block">
                            <div className="w-100">
                              <div className="labl_flx mb-3">
                                <span>Email address</span>
                              </div>
                              <div class="mb-3">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {verify == true ? (
                        <>
                          <div className="col-md-6">
                            <div className="form_flx d-block">
                              <div className="w-100">
                                <div className="labl_flx mb-3">
                                  <span>Enter OTP</span>
                                </div>
                                <div className="row">
                                  <div className="col-md-8">
                                    <div class="mb-3">
                                      <input
                                        type="text"
                                        class="form-control"
                                        value={OtpData}
                                        onInput={validPositive}
                                        onChange={(e) =>
                                          setOtpData(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <button
                                      className="primary_btn m-0 mb-3"
                                      onClick={() => {
                                        Verify();
                                      }}
                                    >
                                      Confirm
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="text-center">
                        <button
                          className="primary_btn m-0 mb-3 pad"
                          onClick={() => {
                            onSubmit();
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <>
                  <div
                    class="tab-pane fade show active"
                    id="one-tab-pane"
                    role="tabpanel"
                    aria-labelledby="one-tab"
                    tabindex="0"
                  >
                    <section className="deposit_box">
                      <div className="earn reff_box">
                        <h1 style={{ marginLeft: "310px" }}>
                          Personal Details Verified !
                        </h1>
                      </div>
                    </section>
                  </div>
                </>
              )}
            </div>

            <div
              class="tab-pane fade"
              id="two-tab-pane"
              role="tabpanel"
              aria-labelledby="two-tab"
              tabindex="0"
            >
              {idproof == "Verified" && selfie_status == "Verified" ? (
                <>
                  <div
                    class="tab-pane fade show active"
                    id="one-tab-pane"
                    role="tabpanel"
                    aria-labelledby="one-tab"
                    tabindex="0"
                  >
                    <section className="deposit_box">
                      <div className="earn reff_box">
                        <h1 style={{ marginLeft: "310px" }}>
                          Id Proof has Verified !
                        </h1>
                      </div>
                    </section>
                  </div>
                </>
              ) : idstatus == "completed" &&
                idproof != "Rejected" &&
                selfie_status != "Rejected" ? (
                <>
                  <div
                    class="tab-pane fade show active"
                    id="one-tab-pane"
                    role="tabpanel"
                    aria-labelledby="one-tab"
                    tabindex="0"
                  >
                    <section className="deposit_box">
                      <div className="earn reff_box">
                        <h1 style={{ marginLeft: "310px" }}>
                          ID Proof has been Process !
                        </h1>
                      </div>
                    </section>
                  </div>
                </>
              ) : personal == "completed" ? (
                <>
                  {idproof == "Rejected" ? (
                    <>
                      <div
                        class="tab-pane fade show active"
                        id="one-tab-pane"
                        role="tabpanel"
                        aria-labelledby="one-tab"
                        tabindex="0"
                      >
                        <section className="deposit_box">
                          <div className="earn reff_box">
                            <h1 style={{ marginLeft: "310px" }}>
                              Your document-proof has been rejected
                            </h1>
                          </div>
                        </section>
                      </div>
                    </>
                  ) : selfie_status == "Rejected" ? (
                    <>
                      <div
                        class="tab-pane fade show active"
                        id="one-tab-pane"
                        role="tabpanel"
                        aria-labelledby="one-tab"
                        tabindex="0"
                      >
                        <section className="deposit_box">
                          <div className="earn reff_box">
                            <h1 style={{ marginLeft: "310px" }}>
                              Your selfie-proof has been rejected
                            </h1>
                          </div>
                        </section>
                      </div>
                    </>
                  ) : (
                    <>
                      <section className="deposit_box">
                        <div className="earn reff_box">
                          {/*  pan  */}
                          {/* <div className="row"> */}
                          {/* <div className="col-md-6">
                          <h5 class="sub_title  mb-4   text-light">Enter Your PAN Details</h5>
                          <div className="form_flx d-block pb-0 px-0">
                            <div className="w-100">
                              <div className="labl_flx mb-3">
                                <span>Name</span>
                              </div>
                              <div>
                                <input type="text" class="form-control" />
                              </div>
                            </div>
                          </div>
                          <div className="form_flx d-block px-0">
                            <div className="w-100">
                              <div className="labl_flx mb-3">
                                <span>PAN Number</span>
                              </div>
                              <div>
                                <input type="text" class="form-control" />
                              </div>
                            </div>
                          </div>
                        </div> */}
                          {/* <div className="col-md-6">
                          <h5 class="sub_title  mb-2  text-light">Upload PAN Card</h5>
                          <p className="grey" >To verify your identity, please upload any of your document</p>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="file_box">
                                <input type="file" />
                                <img src={require("../assets/images/file.png")} alt="Queenbots" className="img-fluid" />
                                <p>FRONT</p>
                                <span>(*Note: .JPG/ .JPEG/ .PNG max 5mb)</span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="file_box">
                                <input type="file" />
                                <img src={require("../assets/images/file.png")} alt="Queenbots" className="img-fluid" />
                                <p>BACK</p>
                                <span>(*Note: .JPG/ .JPEG/ .PNG max 5mb)</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                          <h5 class="sub_title  mb-4 text-light">
                            Choose Document Type
                          </h5>
                          <div className="flx_btn">
                            {console.log(option, "optionoptionoption1")}
                            <button
                              className={`${
                                option == 1
                                  ? "primary_btn active"
                                  : "primary_btn"
                              }`}
                              onClick={() => {
                                setOption(1);
                              }}
                            >
                              Aadhar Card
                            </button>

                            {console.log(option, "optionoptionoption222")}

                            <button
                              className={`${
                                option == 2
                                  ? "primary_btn active"
                                  : "primary_btn"
                              }`}
                              onClick={() => {
                                setOption(2);
                              }}
                            >
                              Driving License
                            </button>
                            <button
                              className={`${
                                option == 4
                                  ? "primary_btn active"
                                  : "primary_btn"
                              }`}
                              onClick={() => {
                                setOption(4);
                              }}
                            >
                              Passport
                            </button>
                            <button
                              className={`${
                                option == 5
                                  ? "primary_btn active"
                                  : "primary_btn"
                              }`}
                              onClick={() => {
                                setOption(5);
                              }}
                            >
                              Country ID
                            </button>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h5 class="sub_title  mb-4   text-light">
                                Enter Your {select} Details
                              </h5>
                              <div className="form_flx d-block pb-0 px-0">
                                <div className="w-100">
                                  <div className="labl_flx mb-3">
                                    <span>Name</span>
                                  </div>
                                  <div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      onChange={(e) => {
                                        if (namereg.test(e.target.value)) {
                                          setName(e.target.value);
                                        }
                                      }}
                                      value={name}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form_flx d-block px-0">
                                <div className="w-100">
                                  <div className="labl_flx mb-3">
                                    <span>{select} Number</span>
                                  </div>
                                  <div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      onChange={(e) => {
                                        setDetailNumber(e.target.value);
                                      }}
                                      onInput={validPositive}
                                      maxLength={option == 1 ? 16 : 20}
                                      value={detailNumber}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <h5 class="sub_title  mb-2  text-light">
                                Upload {select} Proof
                              </h5>
                              <p className="grey">
                                To verify your identity, please upload any of
                                your document
                              </p>
                              <div className="row">
                                {frontImage == "" ? (
                                  <div className="col-md-6">
                                    <div className="file_box">
                                      <input
                                        type="file"
                                        onChange={uploadFrontImage}
                                      />
                                      <img
                                        src={require("../assets/images/file.png")}
                                        alt="Queenbots"
                                        className="img-fluid"
                                      />
                                      <p>FRONT</p>
                                      <span>
                                        (*Note: .JPG/ .JPEG/ .PNG max 5mb)
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col-md-6">
                                    <div className="file_box">
                                      <input
                                        type="file"
                                        onChange={uploadFrontImage}
                                      />
                                      <img
                                        src={file}
                                        alt="Queenbots"
                                        className="img-fluid"
                                      />
                                      <p>FRONT</p>
                                    </div>
                                  </div>
                                )}
                                {backImage == "" ? (
                                  <div className="col-md-6">
                                    <div className="file_box">
                                      <input
                                        type="file"
                                        onChange={uploadBackImage}
                                      />
                                      <img
                                        src={require("../assets/images/file.png")}
                                        alt="Queenbots"
                                        className="img-fluid"
                                      />
                                      <p>BACK</p>
                                      <span>
                                        (*Note: .JPG/ .JPEG/ .PNG max 5mb)
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col-md-6">
                                    <div className="file_box">
                                      <input
                                        type="file"
                                        onChange={uploadBackImage}
                                      />
                                      <img
                                        src={file1}
                                        alt="Queenbots"
                                        className="img-fluid"
                                      />
                                      <p>BACK</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <h5 class="sub_title  mb-2  text-light">
                                Selfie With ID Proof
                              </h5>
                              <p className="grey">
                                To verify your identity, please upload any of
                                your document
                              </p>
                              <div className="row">
                                <div className="col-md-12">
                                  {selfieImage == "" ? (
                                    <div className="file_box">
                                      <input
                                        type="file"
                                        onChange={selfieUpload}
                                      />
                                      <img
                                        src={require("../assets/images/file.png")}
                                        alt="Queenbots"
                                        className="img-fluid mb-3"
                                      />
                                      <span>
                                        (*Note: .JPG/ .JPEG/ .PNG max 5mb)
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="file_box">
                                      <img
                                        src={file3}
                                        alt="Queenbots"
                                        className="img-fluid mb-3"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="text-center">
                            <button
                              className="primary_btn m-0 mb-3 mt-2 pad"
                              onClick={() => {
                                onSubmit1();
                              }}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </section>
                    </>
                  )}
                </>
              ) : (
                <>
                  <section className="deposit_box">
                    <div className="earn reff_box">
                      <h1 style={{ marginLeft: "250px" }}>
                        Complete your personal Details !
                      </h1>
                    </div>
                  </section>
                </>
              )}
            </div>

            {Address == "Verified" ? (
              <>
                {" "}
                <div
                  class="tab-pane fade"
                  id="three-tab-pane"
                  role="tabpanel"
                  aria-labelledby="three-tab"
                  tabindex="0"
                >
                  <section className="deposit_box">
                    <div className="earn reff_box">
                      <h1 style={{ marginLeft: "310px" }}>
                        Address Proof has Verified !
                      </h1>
                    </div>
                  </section>
                </div>
              </>
            ) : state == true ||
              (data1 == "completed" && Address != "Rejected") ? (
              <>
                <div
                  class="tab-pane fade"
                  id="three-tab-pane"
                  role="tabpanel"
                  aria-labelledby="three-tab"
                  tabindex="0"
                >
                  <section className="deposit_box">
                    <div className="earn reff_box">
                      <h1 style={{ marginLeft: "310px" }}>
                        Address Proof has been Process !
                      </h1>
                    </div>
                  </section>
                </div>
              </>
            ) : idstatus == "completed" || Address == "Rejected" ? (
              <>
                {Address == "Rejected" && (
                  <div
                    class="tab-pane fade"
                    id="three-tab-pane"
                    role="tabpanel"
                    aria-labelledby="three-tab"
                    tabindex="0"
                  >
                    <section className="deposit_box">
                      <div className="earn reff_box">
                        <h1 style={{ marginLeft: "310px" }}>
                          Address Proof has been rejected
                        </h1>
                      </div>
                    </section>
                  </div>
                )}
                {
                  <div
                    class="tab-pane fade"
                    id="three-tab-pane"
                    role="tabpanel"
                    aria-labelledby="three-tab"
                    tabindex="0"
                  >
                    <section className="deposit_box">
                      <div className="earn reff_box">
                        <h5 class="sub_title  mb-4 text-light">
                          Choose Document Type
                        </h5>
                        <div className="flx_btn">
                          <div className="flx_btn">
                            {console.log(option, "optionoptionoption1")}
                            <button
                              className={`${
                                option == 1
                                  ? "primary_btn active"
                                  : "primary_btn"
                              }`}
                              onClick={() => {
                                setOption(1);
                              }}
                            >
                              Aadhar Card
                            </button>

                            {console.log(option, "optionoptionoption222")}

                            <button
                              className={`${
                                option == 2
                                  ? "primary_btn active"
                                  : "primary_btn"
                              }`}
                              onClick={() => {
                                setOption(2);
                              }}
                            >
                              Driving License
                            </button>
                            <button
                              className={`${
                                option == 4
                                  ? "primary_btn active"
                                  : "primary_btn"
                              }`}
                              onClick={() => {
                                setOption(4);
                              }}
                            >
                              Passport
                            </button>
                            <button
                              className={`${
                                option == 5
                                  ? "primary_btn active"
                                  : "primary_btn"
                              }`}
                              onClick={() => {
                                setOption(5);
                              }}
                            >
                              Country ID
                            </button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <h5 class="sub_title  mb-4   text-light">
                              Enter Your {select} Details
                            </h5>
                            <div className="form_flx d-block pb-0 px-0">
                              <div className="w-100">
                                <div className="labl_flx mb-3">
                                  <span>Name</span>
                                </div>
                                <div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={name}
                                    onChange={(e) => {
                                      if (namereg.test(e.target.value)) {
                                        setName(e.target.value);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form_flx d-block px-0">
                              <div className="w-100">
                                <div className="labl_flx mb-3">
                                  <span>Address Details</span>
                                </div>
                                <div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    onChange={(e) => {
                                      if (addressreg.test(e.target.value)) {
                                        setAddressdetail(e.target.value);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <h5 class="sub_title  mb-2  text-light">
                              Upload ID Proof
                            </h5>
                            <p className="grey">
                              To verify your identity, please upload any of your
                              document
                            </p>
                            <div className="row">
                              {frontImage == "" ? (
                                <div className="col-md-6">
                                  <div className="file_box">
                                    <input
                                      type="file"
                                      onChange={uploadFrontImage}
                                    />
                                    <img
                                      src={require("../assets/images/file.png")}
                                      alt="Queenbots"
                                      className="img-fluid"
                                    />
                                    <p>FRONT</p>
                                    <span>
                                      (*Note: .JPG/ .JPEG/ .PNG max 5mb)
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className="col-md-6">
                                  <div className="file_box">
                                    <input
                                      type="file"
                                      onChange={uploadFrontImage}
                                    />
                                    <img
                                      src={file}
                                      alt="Queenbots"
                                      className="img-fluid"
                                    />
                                    <p>FRONT</p>
                                  </div>
                                </div>
                              )}
                              {backImage == "" ? (
                                <div className="col-md-6">
                                  <div className="file_box">
                                    <input
                                      type="file"
                                      onChange={uploadBackImage}
                                    />
                                    <img
                                      src={require("../assets/images/file.png")}
                                      alt="Queenbots"
                                      className="img-fluid"
                                    />
                                    <p>BACK</p>
                                    <span>
                                      (*Note: .JPG/ .JPEG/ .PNG max 5mb)
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className="col-md-6">
                                  <div className="file_box">
                                    <input
                                      type="file"
                                      onChange={uploadBackImage}
                                    />
                                    <img
                                      src={file1}
                                      alt="Queenbots"
                                      className="img-fluid"
                                    />
                                    <p>BACK</p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="text-center">
                          <button
                            className="primary_btn m-0 mb-3 pad"
                            onClick={() => {
                              AddressProofSubmit();
                            }}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </section>
                  </div>
                }
              </>
            ) : (
              <>
                <div
                  class="tab-pane fade"
                  id="three-tab-pane"
                  role="tabpanel"
                  aria-labelledby="three-tab"
                  tabindex="0"
                >
                  <section className="deposit_box">
                    <div className="earn reff_box">
                      <h1 style={{ marginLeft: "280px" }}>
                        Complete on Your ID Proof !
                      </h1>
                    </div>
                  </section>
                </div>
              </>
            )}

            {/* <div class="tab-pane fade" id="four-tab-pane" role="tabpanel" aria-labelledby="four-tab" tabindex="0">
        <div className="refferal transaction_his"> 
          <div className="box_bdr">
            <h5 className="sub_title  mb-0 ps-1">Stake Withdraw History</h5>
     
          </div> 
        </div>
     
        </div>
        <div class="tab-pane fade" id="five-tab-pane" role="tabpanel" aria-labelledby="five-tab" tabindex="0">
        <div className="refferal transaction_his"> 
          <div className="box_bdr">
            <h5 className="sub_title  mb-0 ps-1">Stake Mine Busd Reward</h5>
           
          </div> 
         </div>
        </div>
        <div class="tab-pane fade" id="six-tab-pane" role="tabpanel" aria-labelledby="six-tab" tabindex="0">
        <div className="refferal transaction_his"> 
          <div className="box_bdr">
            <h5 className="sub_title  mb-0 ps-1">Stake Mine Level Reward</h5>
       
          </div> 
        </div>
        </div> */}
          </div>
        </div>
      </section>

      {/* <Newsletter /> */}
      {/* <section className="subscribe_section mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="subscribe_div">
                <img
                  src={require("../assets/images/subscribe_img.png")}
                  alt="Queenbots"
                  className="img-fluid"
                />
                <h2 className="main_title text-center">Subscribe Newsletter</h2>
                <p className="inner_para text-center">
                  Quisque sollicitudin lectus auctor, porta orci rutrum,
                  vehicula ipsum. Praesent aliquam neque mi, nec dapibus tellus
                  porta sed.{" "}
                </p>
                <input
                  className="form-control primary_inp"
                  placeholder="Enter Your Email"
                />
                <div className="text-center">
                  <button className="primary_btn">Subscribe</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </div>
  );
}

import axios from "axios";
// import lib
import config from "../lib/config/config";

export const getGrapfDatas = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `https://spotapi.que.exchange/api/v1/getMarketPrice?baseCoin=qbt&quoteCoin=busd`,
    });
    console.log(respData, "checkingsryrhRedtryrhspoyutjfnseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      //   status: err.response.data.status,
      //   message: err.response.data.message,
      //   error: err.response.data.error,
    };
  }
};

// export const getGrapfDatas = async () => {
//   try {
//     let respData = await axios({
//       method: "get",
//       url: `${config.baseUrl}/api/routesUser/getAdsPlan`,
//     });
//     console.log(respData, "checking ResponseData");
//     return {
//       loading: true,
//       status: respData.data.status,
//       message: respData.data.message,
//       result: respData.data.Data,
//     };
//   } catch (err) {
//     return {
//       loading: false,
//       //   status: err.response.data.status,
//       //   message: err.response.data.message,
//       //   error: err.response.data.error,
//     };
//   }
// };

export const getRefferalDatas = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/ReferalCalculation`,
      data,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      //   status: err.response.data.status,
      //   message: err.response.data.message,
      //   error: err.response.data.error,
    };
  }
};

export const getEarnDatas = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/get-poolingData`,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      //   status: err.response.data.status,
      //   message: err.response.data.message,
      //   error: err.response.data.error,
    };
  }
};

export const getStakingLimit = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/getStakingLimit`,
    });
    console.log(respData, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      //   status: err.response.data.status,
      //   message: err.response.data.message,
      //   error: err.response.data.error,
    };
  }
};

export const MineWithDrawHistoryApi = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/get-Withdraw-Single-Listwebsite`,
      data,
    });
    console.log(respData.data, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      //   status: err.response.data.status,
      //   message: err.response.data.message,
      //   error: err.response.data.error,
    };
  }
};

export const updateNewletter = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/updateNewletter`,
      data,
    });
    console.log(respData.data, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      //   status: err.response.data.status,
      //   message: err.response.data.message,
      //   error: err.response.data.error,
    };
  }
};

export const getReferralHistory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/ReferalCalculation`,
      data,
    });
    console.log(respData.data, "checking ResponseData121435");
    return {
      status: "success",
      data: respData.data.Datas,
      respData: respData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
      message: err.response.data.message,
    };
  }
};

export const KYCVerifications = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/get-single-kycdata`,
      data,
    });
    console.log(respData.data, "checking ResponseData");
    return {
      loading: true,
      status: "success",
      data: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: "false",
      error: err.response.data.errors,
      message: err.response.data.message,
    };
  }
};

export const KYCMobileVerify = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/verify-phone`,
      data,
    });
    console.log(respData.data, "checking ResponseData");
    return {
      loading: true,
      status: respData.data.status,
      // message: respData.data.message,
      data: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      //   status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const KYCOTPVerify = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/verify-phone-otp`,
      data,
    });
    console.log(respData.data, "checking ResponseData");
    return {
      loading: true,
      status: "success",
      data: respData.data.result,
      msg: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      status: "false",
      error: err.response.data.errors,
      message: err.response.data.message,
    };
  }
};

export const PersonalDetailsKYC = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/KycDataInsert`,
      data,
    });
    console.log(respData.data, "checking ResponseData");
    return {
      loading: true,
      status: true,
      apiStatus: respData.data.status,
      data: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: false,
      error: err.response.data.errors,
      message: err.response.data.message,
    };
  }
};

export const IdProofKYC = async (data) => {
  try {
    const formData = new FormData();
    formData.append("type", data.type);
    formData.append("address", data.address);
    formData.append("idproof_type", data.idproof_type);
    formData.append("idproof_Name", data.idproof_Name);
    formData.append("idproof_proofNumber", data.idproof_proofNumber);
    formData.append("idproof_frontImage", data.idproof_frontImage);
    formData.append("idproof_backImage", data.idproof_backImage);
    formData.append("selfie_frontImage", data.selfie_frontImage);

    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/KycDataInsert`,
      data: formData,
    });
    console.log(respData.data, "checking ResponseData");
    return {
      loading: true,
      status: "success",
      data: respData.data,
      result: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
      message: err.response.data.message,
    };
  }
};

export const AddressProofKYC = async (data) => {
  try {
    const formData = new FormData();
    formData.append("type", data.type);
    formData.append("address", data.address);
    formData.append("Address_type", data.Address_type);
    formData.append("Address_Name", data.Address_Name);
    formData.append("Address_proofNumber", data.Address_proofNumber);
    formData.append("Address_frontImage", data.Address_frontImage);
    formData.append("Address_backImage", data.Address_backImage);

    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/KycDataInsert`,
      data: formData,
    });
    console.log(respData.data, "checking ResponseData");
    return {
      loading: true,
      status: "success",
      data: respData.data,
      result: respData.data.status,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
      message: err.response.data.message,
    };
  }
};

export const getNFCBalanceApi = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/fetchbalanceUser`,
      data,
    });
    console.log(respData.data, "checking ResponseData121435");
    return {
      status: true,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const depositBusdApi = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/depositbusduser`,
      data,
    });
    console.log(respData.data, "checking ResponseData121435");
    return {
      status: true,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const getReferralHistory1 = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/ReferalCalculation`,
      data,
    });
    console.log(respData.data, "checking ResponseData121435");
    return {
      status: "success",
      data: respData.data.Datas,
      respData: respData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
      message: err.response.data.message,
    };
  }
};

export const signatureUserApi = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/signature-user`,
      data,
    });
    console.log(respData.data, "checking ResponseData121435");
    return {
      status: true,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      status: false,
      message: err?.response?.data?.message,
    };
  }
};

export const cardregisterapi = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/verify-card`,
      data,
    });
    console.log(respData.data, "checking ResponseData121435");
    return {
      status: "success",
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: "unsuccess",
      error: err.response.data.errors,
      message: err.response.data.message,
    };
  }
};

export const postactivecard = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/verify-otp`,
      data,
    });
    console.log(respData.data, "checking ResponseData121435");
    return {
      status: "success",
      msg: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      status: "unsuccess",
      message: err.response.data.message,
    };
  }
};

export const getAllHistoryApi = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/with-depos-history`,
      data,
    });
    console.log(respData.data, "checking ResponseData121435");
    return {
      status: true,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      status: false,
      message: err.response.data.message,
    };
  }
};

export const GetMyWithDrawals = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/GetSingleUserAmount`,
      data,
    });
    console.log(respData.data, "checking drgeg");
    return {
      status: "success",
      data: respData.data.data,
    };
  } catch (err) {
    return {
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const getPendingSignaturesApi = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/signature-pending-list-user`,
      data,
    });
    console.log(respData.data, "checkfdgrthing drgeg");
    return {
      status1: true,
      result1: respData.data.result,
      message1: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      status: false,
      message: err.response.data.message,
    };
  }
};

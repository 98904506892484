import Web3 from "web3";
import ContractABI from "../ABI/DexbotABI.json";
import QBTContractABI from "../ABI/QBTContarctABI.json";
import { connection } from "../helper/connection";

import config from "../lib/config/config";

async function convert(n) {
  try {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  } catch (err) {
    return 0;
  }
}

export async function getUserData(connector) {
  try {
    var { provider, address } = await connection();
    console.log(provider, "provider", address);

    var web3 = new Web3(provider);
    var DexbotContract = await new web3.eth.Contract(
      ContractABI,
      config.ContractAddress
    );

    var resp = await DexbotContract.methods.userData(address).call();
    console.log(resp, "resp");

    return {
      resp: resp,
    };
  } catch (err) {}
}

export async function withdrawReferalReward() {
  try {
    var { provider, address } = await connection();
    console.log(provider, "provider", address);

    var web3 = new Web3(provider);
    var DexbotContract = await new web3.eth.Contract(
      ContractABI,
      config.ContractAddress
    );

    var bnbbalance = await web3.eth.getBalance(address);

    bnbbalance = bnbbalance / 10 ** 18;

    try {
      var estimate = await DexbotContract.methods
        .withdrawReferalReward()
        .estimateGas({ from: address });
      var gasFee = parseInt(estimate) + 10000;
      gasFee = gasFee / 10 ** 8;

      if (bnbbalance < gasFee) {
        return {
          status: 0,
          err: "insuffcient balance",
        };
      }

      var resp = await DexbotContract.methods
        .withdrawReferalReward()
        .send({ from: address });
      return {
        resp: resp,
        status: true,
      };
    } catch (err) {
      return {
        resp: "err",
        status: false,
      };
    }
  } catch (err) {}
}

export async function withdrawLevelReward() {
  try {
    var { provider, address } = await connection();
    console.log(provider, "provider", address);

    var web3 = new Web3(provider);
    var DexbotContract = await new web3.eth.Contract(
      ContractABI,
      config.ContractAddress
    );

    var bnbbalance = await web3.eth.getBalance(address);

    bnbbalance = bnbbalance / 10 ** 18;

    try {
      var estimate = await DexbotContract.methods
        .withdrawLevelReferalReward()
        .estimateGas({ from: address });
      var gasFee = parseInt(estimate) + 10000;

      gasFee = gasFee / 10 ** 8;

      if (bnbbalance < gasFee) {
        return {
          status: false,
          err: "insuffcient balance",
        };
      }

      var resp = await DexbotContract.methods
        .withdrawLevelReferalReward()
        .send({ from: address });
      return {
        resp: resp,
        status: true,
      };
    } catch (err) {
      return {
        resp: "err",
        status: false,
      };
    }
  } catch (err) {}
}

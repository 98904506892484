import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { getEarnDatas, getStakingLimit } from "../Actions/user";
import Web3 from "web3";
import ContractABI from "../ABI/DexbotABI.json";
import { getprovider } from ".././pages/seperate/provider";
import config from "../lib/config/config";
import moment from "moment";
import { toast } from "react-toastify";
import {
  getPoolInfo,
  waithdrawReward,
  getTokenBalance,
  getTokenAllowance,
  ApproveToken,
  StakeForm,
} from "./stakeActions";
import { StakeValidations } from "./Validations";
import ReactLoading from "react-loading";
import Newsletter from "../components/Newsletter.js";
import { connection } from "../helper/connection";
import { useSelector } from "react-redux";

let toasterOption = config.toasterOption;
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Earn(props) {
  const [Data, setData] = useState([]);
  const [provider, setprovider] = useState();
  const [address, setaddress] = useState();
  const [poolAry, setPoolarray] = useState([]);
  const [userArray, setUserArray] = useState([]);
  const [rewardArray, setrewardArray] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [stakelimit, setstakelimit] = useState(0);
  const [stakingNames, setStakingNames] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [stakeDatas, setstakeDatas] = useState();
  const [userBal, setuserBal] = useState(0);
  const [userAllowance, setuserAllowance] = useState(0);
  const [toggleCheckBox, setToggleCheckBox] = useState(false);
  const [inputs, setInputs] = useState("");
  const [JoinNowstatus, setJoinNowstatus] = useState(false);

  const walletConnection = useSelector((state) => state.walletConnection);
  console.log("yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy", walletConnection);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    getStakingListNames();
    getpoolinfo();
    getstakinglimit();
    getQbtBalance();
    CheckIsUser();
  }, [walletConnection]);

  useEffect(() => {
    EarnDatas();
  }, [poolAry, walletConnection]);

  async function CheckIsUser() {
    let address =
      walletConnection && walletConnection.address && walletConnection.network
        ? walletConnection.address
        : "";
    let provider =
      walletConnection && walletConnection.address && walletConnection.provider
        ? walletConnection.provider
        : undefined;

    console.log(address, provider, "jdhfgeradw", walletConnection);

    try {
      if (typeof provider !== "undefined") {
        var web3 = new Web3(provider);
        var DexbotContract = await new web3.eth.Contract(
          ContractABI,
          config.ContractAddress
        );
        var isRefferal = await DexbotContract.methods.userData(address).call();
        console.log(isRefferal.isUser, "isRefferal");
        setJoinNowstatus(isRefferal.isUser);
      }
    } catch (err) { }
  }

  const getstakinglimit = async () => {
    var rewardNew = await getStakingLimit();
    console.log(rewardNew, "rewardNew");
    setstakelimit(rewardNew.result);
  };
  const getStakingListNames = async () => {
    try {
      var data = await getEarnDatas();
      // console.log(data, "hfghfjf");
      setStakingNames(data.result);
    } catch (err) {
      console.log("getPoolDatas err");
    }
  };

  const getpoolinfo = async () => {
    var { provider, address } = await connection();
    setprovider(provider);
    setaddress(address);
    var info = await getPoolInfo();
    console.log(info, "infoinfoinfo");
    setUserArray(info.userArray);
    setPoolarray(info.poolArray);
    console.log(info.rewardArr, "info.rewardArr");
    setrewardArray(info.rewardArr);
  };

  async function EarnDatas() {
    // var Stakepool = [];
    // if (poolArray.length != 0 && poolArray.length != undefined) {
    //   poolArray.map((item, index) => {
    //     if (item.isMine == true) {
    //       Stakepool.push(item);
    //     }

    //     console.log(item, "item", index + 1);
    //   });
    //   setPoolarray(Stakepool);
    // }

    if (poolAry != undefined && poolAry.length != 0) {
      let durationArr = [];
      poolAry.map((item, index) => {
        if (item[4] == true && item[5] == false) {
          let durationVal = parseInt(item[1]);
          console.log(item[1], "item[1]");
          durationArr.push({
            value: durationVal,
            label: parseInt(item[1]) / 30 + " Months",
            key: index,
          });
        }
      });
      console.log(durationArr, "durationArr");
      setDurationList(durationArr);
    }
  }

  async function waithdrawreward(type, userdet, poolDet, key) {
    console.log(key, "type, userdet, poolDet, key");
    if (type == "withdraw") {
      var withdrawDate = moment
        .unix(parseInt(userdet[7]))
        .add(poolDet[1], "days")
        .format("YYYY-MM-DD");
        

      if (moment().diff(withdrawDate, "days") < 0) {
        var message = "Please, wait until unstake time ends.";
        toast.error(message, toasterOption);

        return false;
      }
    }

    if (type == "harvest") {
      var harvesttime = moment
        .unix(userdet[8])
        .add(stakelimit[0]?.seconds, "seconds")
        .format("YYYY-MM-DD");

      if (moment().diff(harvesttime, "days") <= 0) {
        var message = "Please, wait until harvest time ends.";

        toast.error(message, toasterOption);

        return false;
      }
    }
    setLoader(true);
    var data = {
      pid: key,
      type: type,
    };
    console.log(data, "datachfhf");
    setLoader(true);

    let resp = await waithdrawReward(data);
    console.log(resp, "resprespresp");
    if (resp.status) {
      setLoader(false);
      toast.success("Successfully Withdraw", toasterOption);
      await getpoolinfo();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      setLoader(false);
      if (resp && resp.err && resp.err == "insuffcient balance") {
        toast.error("Insuffcient gas fees", toasterOption);
        setLoader(false);

        return false;
      } else {
        setLoader(false);
        toast.error("Transaction Rejected Resubmit Again", toasterOption);
        setLoader(false);
      }
    }
  }

  //Stake Form

  async function getQbtBalance() {
    var { balance } = await getTokenBalance();
    if (balance) {
      setuserBal(balance);
    }

    var { allowance } = await getTokenAllowance();
    if (allowance) {
      setuserAllowance(allowance);
    }
  }

  async function handleStakeToggle() {
    if (toggleCheckBox == false) {
      setToggleCheckBox(true);
    } else {
      setToggleCheckBox(false);
    }
  }

  function wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }

  async function submitStake() {
    setLoader(true);
    try {
      var amount = inputs != "" ? parseFloat(inputs) : "";

      if (amount == "") {
        setLoader(false);

        toast.error("Enter amount", toasterOption);
        return false;
      }
      if (userBal < amount) {
        setLoader(false);
        toast.error("Insufficient Balance", toasterOption);
        return false;
      }
      if (parseInt(stakeDatas?.poolDetails[2]) / 1e18 > amount) {
        setLoader(false);
        toast.error(
          "Amount should be greater than the minimum stake value",
          toasterOption
        );
        return false;
      }

      if (parseInt(stakeDatas?.poolDetails[3]) / 1e18 < amount) {
        setLoader(false);
        toast.error(
          "Amount should be lesser than the maximum stake value",
          toasterOption
        );
        return false;
      }
      let pid = stakeDatas?.durationData.key;

      let wallet = address;
      let data = {
        amount,
        pid,
        wallet,
        balance: userBal,
        toggleCheckBox,
      };

      var { status } = await StakeValidations(data);
      if (status) {
        var resp = { approveStatus: true };
        if (userAllowance < amount) {
          let dataNew = {
            amount,
            pid,
            wallet,
            balance: userBal,
            toggleCheckBox,
          };

          var resp = await ApproveToken(dataNew);
          await wait(3000);
        }
        if (resp.approveStatus) {
          var resp = await StakeForm(data);
          setLoader(false);
          if (resp.stakestatus) {
            toast.success("Successfully Staked", toasterOption);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            setLoader(false);
            if (resp && resp.err && resp.err == "insuffcient balance") {
              toast.error("Insuffcient gas fees", toasterOption);
            } else {
              toast.error("Transaction Rejected Resubmit Again", toasterOption);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          }
        } else {
          setLoader(false);
          if (resp && resp.err && resp.err == "insuffcient balance") {
            toast.error("Insuffcient gas fees", toasterOption);
          } else {
            toast.error("Transaction Rejected Resubmit Again", toasterOption);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        }
      } else {
        // alert("Transaction Rejected1");
        setLoader(false);
      }
    } catch (err) {
      toast.error("Transaction Rejected", toasterOption);
      setLoader(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }

  const validPositive = (e) => {
    console.log(
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value),
      "valllluee"
    );
    if (
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  const handleOnchange = (e) => {
    console.log(e.target.value, "e.target.value");
    setInputs(e.target.value);
  };

  console.log(poolAry, "poolAry");
  console.log(userArray, "userArray");
  console.log(durationList, "durationList");

  var today = new Date();

  return (
    <div>
      {poolAry == undefined ||
        (poolAry.length == 0 && (
          <ReactLoading type={"spin"} color={"#000000"} className="loading" />
        ))}
      {isLoader == true && (
        <ReactLoading type={"spin"} color={"#000000"} className="loading" />
      )}
      <div>
        <ScrollToTopOnMount />
        <Navbar />
        <div className="refferal_ban">
          <div className="container">
            <img
              src={require("../assets/images/earn.png")}
              alt="Queenbots"
              className="img-fluid earn_img"
            />
            <h2 class="main_title">EARN</h2>
            {/* <h5 class="sub_title mb-3">
              Cras eleifend vehicula lectus id porta.
            </h5> */}
            {/* <p className="inner_para">
              Vestibulum ligula nulla, fringilla nec risus vitae, dapibus
              aliquet magna. Duis sagittis eros nec risus suscipit, non eleifend
              purus ullamcorper. Quisque sollicitudin lectus auctor, porta orci
              rutrum, vehicula ipsum. Praesent aliquam neque mi, nec dapibus
              tellus porta sed. Aenean interdum viverra ornare. Donec pulvinar
              sem nec metus posuere rhoncus. Pellentesque sollicitudin feugiat
              semper.{" "}
            </p> */}
            <div className="mb-2">
              <ul className="d-flex">
                <li className="active">
                  <Link className="primary_btn" to="/earn">
                    Staking
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link className="primary_btn" to="/mining">
                    Mining
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
          </div>
          {/* <div class="b_container">
  <div class="bubble-wrap">
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
      <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
      <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
      <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
      <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
</div>
</div> */}
        </div>
        <div className="earn">
          <div className="container">
            <div className="row">
              {durationList?.length >= 0 &&
                durationList?.map((item, index) => (
                  <>
                    <div className="col-lg-6 d-flex">
                      <div className="box">
                        <div className="inbox">
                          <div className="flx">
                            <img
                              src={require("../assets/images/stake.png")}
                              alt="Queenbots"
                              className="img-fluid me-3"
                            />
                            <div>
                              {stakingNames && stakingNames.length > 0 && stakingNames.map((nameItems, nameIndex) => {
                                {
                                  console.log(nameItems, "nameItems");
                                }
                                return (
                                  <>
                                    {nameItems.pid != 4 &&
                                      nameItems.pid == index && (
                                        <h3>{nameItems.name}</h3>
                                      )}
                                  </>
                                );
                              })}
                              <p>Start staking, earn more</p>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <p>Staked Amount</p>
                              <span>
                                {userArray &&
                                  userArray[item.key] &&
                                  userArray[item.key][2] &&
                                  userArray[item.key][2]
                                  ? parseInt(userArray[item.key][2]).toFixed(
                                    4
                                  ) / 1e18
                                  : 0}
                              </span>
                            </li>
                            <li>
                              <p>APY</p>
                              {console.log(item, "kkkkk")}
                              <span>
                                {poolAry &&
                                  poolAry[item.key] &&
                                  poolAry[item.key][0] &&
                                  poolAry[item.key][0]
                                  ? (parseInt(poolAry[item.key][0]) /
                                    (parseInt(poolAry[item.key][1]) / 30)) *
                                  12
                                  : 0}
                                %
                              </span>
                            </li>

                            {userArray &&
                              userArray[item.key] &&
                              userArray[item.key][2] &&
                              userArray[item.key][2] &&
                              parseInt(userArray[item.key][2]).toFixed(4) / 1e18 >
                              0 ? (
                              <>
                                <li>
                                  <p>Stake Date</p>
                                  <span>
                                    {userArray &&
                                      userArray[item.key] &&
                                      userArray[item.key][7] &&
                                      userArray[item.key][7]
                                      ? parseInt(userArray[item.key][7]) == 0
                                        ? 0
                                        : moment
                                          .unix(userArray[item.key][7])
                                          .format("YYYY-MM-DD hh:mm A")
                                      : 0}
                                  </span>
                                </li>
                                <li>
                                  <p>Harvest Time</p>
                                  {console.log(userArray[item.key][7], "yff")}
                                  <span>
                                    {userArray &&
                                      userArray[item.key] &&
                                      userArray[item.key][8] &&
                                      userArray[item.key][8]
                                      ? parseInt(userArray[item.key][8]) == 0
                                        ? 0
                                        : moment
                                          .unix(userArray[item.key][8])
                                          .add(
                                            stakelimit[0]?.seconds,
                                            "seconds"
                                          )
                                          .isSameOrAfter(
                                            moment
                                              .unix(
                                                parseInt(
                                                  userArray[item.key][7]
                                                )
                                              )
                                              .add(
                                                poolAry[item.key][1],
                                                "days"
                                              ),
                                            "days"
                                          )
                                          ? moment
                                            .unix(
                                              parseInt(userArray[item.key][7])
                                            )
                                            .add(poolAry[item.key][1], "days")
                                            .format("YYYY-MM-DD hh:mm A")
                                          : moment
                                            .unix(userArray[item.key][8])
                                            .add(
                                              stakelimit[0]?.seconds,
                                              "seconds"
                                            )
                                            .format("YYYY-MM-DD hh:mm A")
                                      : 0}
                                  </span>
                                </li>
                                <li>
                                  <p>Withdraw Date</p>
                                  <span>
                                    {userArray &&
                                      userArray[item.key] &&
                                      userArray[item.key][7] &&
                                      userArray[item.key][7] &&
                                      poolAry &&
                                      poolAry[item.key] &&
                                      poolAry[item.key][1] &&
                                      poolAry[item.key][1]
                                      ? parseInt(userArray[item.key][7]) == 0
                                        ? 0
                                        : moment
                                          .unix(
                                            parseInt(userArray[item.key][7])
                                          )
                                          .add(poolAry[item.key][1], "days")
                                          .format("YYYY-MM-DD hh:mm A")
                                      : 0}
                                  </span>
                                </li>
                                <li>
                                  <p>Remaining Days</p>
                                  <span>
                                    {userArray &&
                                      userArray[item.key] &&
                                      userArray[item.key][7] &&
                                      userArray[item.key][7] &&
                                      poolAry &&
                                      poolAry[item.key] &&
                                      poolAry[item.key][1] &&
                                      moment().diff(
                                        moment
                                          .unix(parseInt(userArray[item.key][7]))
                                          .add(poolAry[item.key][1], "days")
                                      ) < 0
                                      ? poolAry[item.key][1]
                                        ? parseInt(userArray[item.key][7]) == 0
                                          ? 0
                                          : parseInt(
                                            Math.abs(
                                              new Date(
                                                moment(today).format(
                                                  "YYYY-MM-DD hh:mm"
                                                )
                                              ) -
                                              new Date(
                                                moment
                                                  .unix(
                                                    parseInt(
                                                      userArray[item.key][7]
                                                    )
                                                  )
                                                  .add(
                                                    poolAry[item.key][1],
                                                    "days"
                                                  )
                                                  .format(
                                                    "YYYY-MM-DD hh:mm"
                                                  )
                                              )
                                            ) /
                                            (24 * 60 * 60 * 1000)
                                          ) +
                                          " days " +
                                          parseInt(
                                            ((Math.abs(
                                              new Date(
                                                moment(today).format(
                                                  "YYYY-MM-DD hh:mm"
                                                )
                                              ) -
                                              new Date(
                                                moment
                                                  .unix(
                                                    parseInt(
                                                      userArray[item.key][7]
                                                    )
                                                  )
                                                  .add(
                                                    poolAry[item.key][1],
                                                    "days"
                                                  )
                                                  .format(
                                                    "YYYY-MM-DD hh:mm"
                                                  )
                                              )
                                            ) /
                                              (24 * 60 * 60 * 1000)) %
                                              1) *
                                            24
                                          ) +
                                          " hrs"
                                        : 0
                                      : 0}
                                  </span>
                                </li>
                              </>
                            ) : (
                              ""
                            )}
                            <li>
                              <p>Amout Reward</p>
                              <span>
                                {rewardArray?.[item.key] &&
                                  rewardArray?.[item.key] > 0
                                  ? parseFloat(rewardArray?.[item.key]).toFixed(
                                    4
                                  )
                                  : 0}{" "}
                                {"QBT"}
                              </span>
                            </li>
                          </ul>
                          <div className="flx_btn">
                            {console.log(rewardArray?.[item.key], "iutyryfh")}
                            {rewardArray?.[item.key] > 0 ? (
                              <button
                                className="primary_btn harvest"
                                onClick={() => {
                                  waithdrawreward(
                                    "harvest",
                                    userArray[item.key],
                                    poolAry[item.key],
                                    item.key
                                  );
                                }}
                              >
                                Harvest
                              </button>
                            ) : (
                              <button className="primary_btn harvest">
                                Harvest
                              </button>
                            )}
                            {console.log(JoinNowstatus, "JoinNowstatus")}
                            {JoinNowstatus != false && address != "" ? (
                              <>
                                <button
                                  className="primary_btn stakenow"
                                  data-bs-toggle="modal"
                                  data-bs-target="#StakeNow_modal"
                                  onClick={() => {
                                    setstakeDatas({
                                      poolDetails: poolAry[item.key],
                                      durationData: item,
                                    });
                                  }}
                                >
                                  Stake Now
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="primary_btn stakenow"
                                  data-bs-toggle="modal"
                                  data-bs-target="#selectplan_modal"
                                >
                                  Stake Now
                                </button>
                              </>
                            )}

                            {rewardArray?.[item.key] > 0 &&
                              moment().diff(
                                moment
                                  .unix(parseInt(userArray?.[item.key][7]))
                                  .add(poolAry[item.key][1], "minutes")
                              ) >= 0 ? (
                              <>
                                <button
                                  className="primary_btn unstake"
                                  onClick={() => {
                                    waithdrawreward(
                                      "withdraw",
                                      userArray[item.key],
                                      poolAry[item.key],
                                      item.key
                                    );
                                  }}
                                >
                                  Unstake
                                </button>
                              </>
                            ) : (
                              <button className="primary_btn unstake" disabled>
                                Unstake
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </div>
        </div>
        {/* <Newsletter /> */}
        {/* <section className="subscribe_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <div className="subscribe_div">
                  <img
                    src={require("../assets/images/subscribe_img.png")}
                    alt="Queenbots"
                    className="img-fluid"
                  />
                  <h2 className="main_title text-center">
                    Subscribe Newsletter
                  </h2>
                  <p className="inner_para text-center">
                    Quisque sollicitudin lectus auctor, porta orci rutrum,
                    vehicula ipsum. Praesent aliquam neque mi, nec dapibus
                    tellus porta sed.{" "}
                  </p>
                  <input
                    className="form-control primary_inp"
                    placeholder="Enter Your Email"
                  />
                  <div className="text-center">
                    <button className="primary_btn">Subscribe</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {console.log(stakeDatas, "stakeDatas")}

        {/* Select StakeNow Modal */}
        <div
          className="modal modal-lg fade primary_modal primary_modal_chg"
          id="StakeNow_modal"
          tabIndex={-1}
          aria-labelledby="connect_walletModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="bgimg">
                <div className="modal-header border-0 w-100 text-center">
                  <div className="w-100">
                    <h2
                      className="modal-title main_title"
                      id="connect_walletModalLabel"
                    >
                      Stake Now
                    </h2>
                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <img
                      src={require("../assets/images/close.png")}
                      alt="Queenbots"
                      className="img-fluid"
                    />
                  </button>
                </div>
                <div className="modal-body">
                  <div>
                    <div className="m-2 mb-3 row">
                      <div className="col-md-6">
                        <h5>Duration : </h5>
                      </div>
                      <div className="col-md-6">
                        <h5>{stakeDatas?.durationData?.label}</h5>
                      </div>
                    </div>
                    <div className="m-2 mb-3 row">
                      <div className="col-md-6">
                        <h5>Available amount : </h5>
                      </div>
                      <div className="col-md-6">
                        <h5>
                          {userBal && parseFloat(userBal) > 0
                            ? parseFloat(userBal).toFixed(4)
                            : 0}{" "}
                          QBT
                        </h5>
                      </div>
                    </div>
                    <div className="m-2 mb-3 row">
                      <div className="col-md-6">
                        <h5>Deposit Amount : </h5>
                      </div>
                      <div className="col-md-6">
                        <input
                          class="form-control primary_inp"
                          type="text"
                          placeholder="Enter Amount"
                          onChange={handleOnchange}
                          name="BUSDValue"
                          onInput={validPositive}
                          style={{ marginLeft: "-20px", color: "white" }}
                        />
                      </div>
                    </div>
                    <div className="m-2 mb-3 row">
                      <div className="col-md-6">
                        <h5>APY : </h5>
                      </div>
                      <div className="col-md-6">
                        <h5>
                          {stakeDatas?.poolDetails &&
                            stakeDatas?.poolDetails[0] &&
                            stakeDatas?.poolDetails[0]
                            ? (parseInt(stakeDatas?.poolDetails[0]) /
                              (parseInt(stakeDatas?.poolDetails[1]) / 30)) *
                            12
                            : 0}
                          %
                        </h5>
                      </div>
                    </div>
                    {/* <div className="m-2 mb-3 row">
                    <div className="col-md-6">
                      <h5>Duration : </h5>
                    </div>
                    <div className="col-md-6">
                      <h5></h5>
                    </div>
                  </div> */}
                    <div className="m-2 mb-3 row">
                      <div className="col-md-6">
                        <h5>Minimum Stake Amount : </h5>
                      </div>
                      <div className="col-md-6">
                        <h5>
                          {stakeDatas?.poolDetails &&
                            stakeDatas?.poolDetails[2] &&
                            stakeDatas?.poolDetails[2]
                            ? parseInt(stakeDatas?.poolDetails[2]) / 1e18
                            : 0}
                        </h5>
                      </div>
                    </div>
                    <div className="m-2 mb-3 row">
                      <div className="col-md-6">
                        <h5> Maximum Stake Amount : </h5>
                      </div>
                      <div className="col-md-6">
                        <h5>
                          {stakeDatas?.poolDetails &&
                            stakeDatas?.poolDetails[3] &&
                            stakeDatas?.poolDetails[3]
                            ? parseInt(stakeDatas?.poolDetails[3]) / 1e18
                            : 0}
                        </h5>
                      </div>
                    </div>
                    <div className="m-2 mb-3 row">
                      <div className="col-md-12">
                        <p>
                          {" "}
                          Staking will return the staked digital asset to the
                          user's private wallet after the agreed period ends.
                          The value of digital assets fluctuates and is subject
                          to change.
                        </p>
                      </div>
                    </div>
                    {console.log(toggleCheckBox, "toggleCheckBox")}
                    <div className="m-2 mb-3 row">
                      <div className="col-md-12 check_box">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          onChange={() => handleStakeToggle()}
                        />{" "}
                        <span class="form-check-label" for="flexCheckDefault">
                          I have read and agree to the QueenBOT Staking Service
                          Agreement.
                        </span>
                      </div>
                    </div>
                    <div className="m-2 mb-3 row">
                      <div className="col-md-12 text-center">
                        <button
                          className="primary_btn mt-3"
                          onClick={() => submitStake()}
                        >
                          CONFIRM
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}

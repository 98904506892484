import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import { Link } from "react-router-dom";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function PrivacyPolicy(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <div>
        <ScrollToTopOnMount />
        <Navbar />
        <div className="cms_main">
          <div className="container">
            <h2 class="main_title">Privacy Policy</h2>
            <div class="column_attr mfn-inline-editor clearfix">             
              <h3>
                <b>Who we are</b>
              </h3>
              <p>
                <span>Our website address is: https://queenbots.com</span>
              </p>
              <h3>
                <b>Comments</b>
              </h3>
              <p>
                <span>
                  When visitors leave comments on the site we collect the data
                  shown in the comments form, and also the visitor’s IP address
                  and browser user agent string to help spam detection.
                </span>
              </p>
              <p>
                <span>
                  An anonymized string created from your email address (also
                  called a hash) may be provided to the Gravatar service to see
                  if you are using it. The Gravatar service privacy policy is
                  available here: https://automattic.com/privacy/. After
                  approval of your comment, your profile picture is visible to
                  the public in the context of your comment.
                </span>
              </p>
              <h3>
                <b>Media</b>
              </h3>
              <p>
                <span>
                  If you upload images to the website, you should avoid
                  uploading images with embedded location data (EXIF GPS)
                  included. Visitors to the website can download and extract any
                  location data from images on the website.
                </span>
              </p>
              <h3>
                <b>Cookies</b>
              </h3>
              <p>
                <span>
                  If you leave a comment on our site you may opt-in to saving
                  your name, email address and website in cookies. These are for
                  your convenience so that you do not have to fill in your
                  details again when you leave another comment. These cookies
                  will last for one year.
                </span>
              </p>
              <p>
                <span>
                  If you visit our login page, we will set a temporary cookie to
                  determine if your browser accepts cookies. This cookie
                  contains no personal data and is discarded when you close your
                  browser.
                </span>
              </p>
              <p>
                <span>
                  When you log in, we will also set up several cookies to save
                  your login information and your screen display choices. Login
                  cookies last for two days, and screen options cookies last for
                  a year. If you select "Remember Me", your login will persist
                  for two weeks. If you log out of your account, the login
                  cookies will be removed.
                </span>
              </p>
              <p>
                <span>
                  If you edit or publish an article, an additional cookie will
                  be saved in your browser. This cookie includes no personal
                  data and simply indicates the post ID of the article you just
                  edited. It expires after 1 day.
                </span>
              </p>
              <h3>
                <b>Embedded content from other websites</b>
              </h3>
              <p>
                <span>
                  Articles on this site may include embedded content (e.g.
                  videos, images, articles, etc.). Embedded content from other
                  websites behaves in the exact same way as if the visitor has
                  visited the other website.
                </span>
              </p>
              <p>
                <span>
                  These websites may collect data about you, use cookies, embed
                  additional third-party tracking, and monitor your interaction
                  with that embedded content, including tracking your
                  interaction with the embedded content if you have an account
                  and are logged in to that website.
                </span>
              </p>
              <h3>
                <b>Who we share your data with</b>
              </h3>
              <p>
                <span>
                  If you request a password reset, your IP address will be
                  included in the reset email.
                </span>
              </p>
              <h3>
                <b>How long we retain your data</b>
              </h3>
              <p>
                <span>
                  If you leave a comment, the comment and its metadata are
                  retained indefinitely. This is so we can recognize and approve
                  any follow-up comments automatically instead of holding them
                  in a moderation queue.
                </span>
              </p>
              <p>
                <span>
                  For users that register on our website (if any), we also store
                  the personal information they provide in their user profile.
                  All users can see, edit, or delete their personal information
                  at any time (except they cannot change their username).
                  Website administrators can also see and edit that information.
                </span>
              </p>
              <h3>
                <b>What rights you have over your data</b>
              </h3>
              <p>
                <span>
                  If you have an account on this site, or have left comments,
                  you can request to receive an exported file of the personal
                  data we hold about you, including any data you have provided
                  to us. You can also request that we erase any personal data we
                  hold about you. This does not include any data we are obliged
                  to keep for administrative, legal, or security purposes.
                </span>
              </p>
              <h3>
                <b>Where your data is sent</b>
              </h3>
              <p>
                <span>
                  Visitor comments may be checked through an automated spam
                  detection service.
                </span>
              </p>
              <p>&nbsp;</p>
              <p className="mt-5">
                <span>USER AGREEMENT</span>
              </p>
              <p>
                <span>VERSION 1.00</span>
              </p>
              <p>
                <span>LAST REVISED ON: MAY 1, 2022</span>
              </p>
              <p>
                <span>
                  This user agreement (this “Agreement”) sets forth the legally
                  binding terms and conditions Your access to and use of any
                  websites, mobile sites, mobile applications, desktop
                  applications, products, or services (the “Services”) offered
                  by Queenbots Technologies Private Limited (“Queenbots”) and
                  “Binance” (to the extent further described below)
                  (“Queenbots”, “us”, “our”, and “we”). The “User”, “you”,
                  “your” shall refer to any natural person or entity and it's
                  authorized users that subscribes or uses the Services. Certain
                  features of the Site may be subject to additional guidelines,
                  terms, or rules, which will be posted on the Site in
                  connection with such features. All such additional terms,
                  guidelines, and rules are incorporated by reference into this
                  Agreement.
                </span>
              </p>              
              <p>
                <span>
                  In this agreement the following terms, unless the context
                  otherwise indicates, will have the following meanings:
                </span>
              </p>
              <p>
                <b>AML Regulations</b>
                <span>
                  {" "}
                  means India’s Prevention of Money Laundering Act, 2002 and the
                  Rules framed thereunder.
                </span>
                <b>Business Day </b>
                <span>
                  means a day that is not a Saturday, Sunday or public holiday
                  in Bhubaneswar, Odisha, India.
                </span>
                <b>Client Asset </b>
                <span>
                  Account means the account opened by the User on the Queenbots
                  Platform.
                </span>
                <b>
                  Company means Queenbots Technologies Private Limited [CIN no.
                  U72900OR2022PTC039438].Competent Authority{" "}
                </b>
                <span>
                  means the relevant government authority in India, as notified
                  or may be notified, depending on the context.
                </span>
                <b>Force Majeure</b>
                <span>
                  {" "}
                  shall mean and include any cause arising from or attributable
                  to acts, or events, beyond the reasonable control of
                  Queenbots, including natural calamity, strikes, terrorist
                  action or threat, civil commotion, riot, crowd disorder,
                  invasion, war, threat of or preparation for war, fire,
                  explosion, storm, flood, earthquake, subsidence, structural
                  damage, epidemic or other natural disaster, calamity, attacks
                  including through computer viruses, hacking, denial of service
                  attacks, ransomware or other manmade disruptions or any law,
                  order enactment, statutory direction, legislation, regulation,
                  rule or ruling of government or any court of law or of a
                  Government or regulatory authority.
                </span>
                <b>KYC</b>
                <span>
                  {" "}
                  means know your customer requirements as determined by
                  Queenbots or under the AML Regulations.
                </span>
                <b>User</b>
                <span> or</span>
                <b> Client </b>
                <span>or </span>
                <b>You </b>
                <span>or </span>
                <b>Your</b>
                <span>
                  {" "}
                  shall mean guests on the Queenbots Platform or persons
                  availing of the Queenbots Services (as defined below),
                  directly or indirectly.
                </span>
                <b>Queenbots Platform </b>
                <span>
                  shall collectively mean and include Queenbots Android App,
                  Queenbots iOS App (collectively, “Queenbots App”) or Queenbots
                  Web app (“Web version”) and Queenbots website
                  “www.queenbots.com” (“Queenbots Website”), Queenbots OTC
                  Trading Platform and other web assets on the queenbots.com
                  domain, together with the Queenbots Developer Portal, Public
                  APIs and secure authentication for Users (all either existing
                  or available in the future);
                </span>
                <b>Queenbots Services</b>
                <span>
                  {" "}
                  means the services made available from time to time on the
                  Queenbots Platform, as described in these Terms of Use.
                </span>
                <b>BY ACCESSING OR USING THE SERVICES,</b>
                <span>
                  {" "}
                  YOU ARE ACCEPTING THIS AGREEMENT (ON BEHALF OF YOURSELF OR THE
                  ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT
                  YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THIS
                  AGREEMENT (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU
                  REPRESENT). YOU MAY NOT ACCESS OR USE THE SERVICES OR ACCEPT
                  THIS AGREEMENT IF YOU ARE NOT AT LEAST 18 YEARS OLD. IF YOU DO
                  NOT AGREE WITH ALL OF THE PROVISIONS OF THIS AGREEMENT, DO NOT
                  ACCESS AND/OR USE THE SITE. THESE TERMS REQUIRE THE USE OF
                  ARBITRATION (SECTION 13.1) ON AN INDIVIDUAL BASIS TO RESOLVE
                  DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO
                  LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.
                </span>
              </p>
              <p>
                <span>
                  We may amend this Agreement related to the Services from time
                  to time. Amendments will be effective upon our posting of such
                  updated Agreement at this location or the amended policies or
                  supplemental terms on the applicable Service. Your continued
                  access or use of the Services after such posting constitutes
                  your consent to be bound by the Agreement, as amended.
                </span>
              </p>
              <p>
                <span>
                  YOU ACKNOWLEDGE THAT TRADING IN CRYPTOCURRENCIES INVOLVES A
                  HIGH DEGREE OF RISK. CRYPTOCURRENCIES ARE SUBJECT TO CONSTANT
                  AND FREQUENT FLUCTUATIONS IN VALUE AND EXCHANGE RATES, AND THE
                  VALUE OF YOUR CRYPTOCURRENCY ASSETS MAY INCREASE OR DECREASE
                  AT ANY TIME. ACCORDINGLY, YOU MAY SUFFER A COMPLETE LOSS OF
                  THE FUNDS HELD IN YOUR ACCOUNT. YOU ACKNOWLEDGE THAT YOU ARE
                  SOLELY RESPONSIBLE FOR DETERMINING THE NATURE, SUITABILITY,
                  AND APPROPRIATENESS OF THESE TRADING RISKS FOR YOU. YOU
                  ACKNOWLEDGE AND AGREE THAT QUEENBOTS BEARS NO RESPONSIBILITY
                  OR LIABILITY TO YOU OR ANY OTHER PERSON WHATSOEVER FOR ANY
                  LOSSES OR GAINS INCURRED IN CONNECTION WITH YOUR USE OF THE
                  SERVICES. YOU ALSO ACKNOWLEDGE AND AGREE THAT QUEENBOTS DOES
                  NOT GIVE ADVICE OR RECOMMENDATIONS REGARDING THE TRADING OF
                  CRYPTOCURRENCIES, INCLUDING THE SUITABILITY AND
                  APPROPRIATENESS OF, AND INVESTMENT STRATEGIES FOR,
                  CRYPTOCURRENCIES.
                </span>
              </p>
              <p>
                <span>
                  YOU ALSO ACKNOWLEDGE AND AGREE THAT QUEENBOTS AND YOUR ABILITY
                  TO USE THE SERVICES MAY BE DETRIMENTALLY IMPACTED BY
                  REGULATORY ACTION OR CHANGES IN REGULATIONS APPLICABLE TO
                  CRYPTOCURRENCY. YOU AGREE THAT WE MAY DISCLOSE YOUR PERSONAL
                  AND ACCOUNT INFORMATION IF WE BELIEVE THAT IT IS REASONABLY
                  NECESSARY TO COMPLY WITH A LAW, REGULATION, LEGAL PROCESS, OR
                  GOVERNMENTAL REQUEST.
                </span>
              </p>
              <p>
                <span>
                  YOU HEREBY ACKNOWLEDGE AND AGREE THAT QUEENBOTS IS NOT A
                  FINANCIAL INSTITUTION, BANK, CREDIT UNION, TRUST, HEDGE FUND,
                  BROKER OR INVESTMENT OR FINANCIAL ADVISOR, AND IS NOT SUBJECT
                  TO THE SAME LAWS, REGULATIONS, DIRECTIVES OR REQUIREMENTS
                  APPLICABLE TO SUCH PERSONS.
                </span>
              </p>
              <p>
                <span>
                  YOU ACKNOWLEDGE AND AGREE THAT NO ORAL OR WRITTEN INFORMATION
                  OR ADVICE PROVIDED BY QUEENBOTS, ITS OFFICERS, DIRECTORS,
                  EMPLOYEES, OR AGENTS, NOR ANY INFORMATION OBTAINED THROUGH THE
                  SERVICES, WILL OR SHALL CONSTITUTE INVESTMENT, OR FINANCIAL
                  ADVICE. YOU ARE SOLELY AND EXCLUSIVELY RESPONSIBLE FOR ALL
                  TRADING DECISIONS MADE BY YOU WHILE USING THE SERVICES.
                  QUEENBOTS IS MERELY A TECHNOLOGY PLATFORM AND YOU ARE SOLELY
                  AND ENTIRELY RESPONSIBLE FOR THE LEGAL, REGULATORY AND TAX
                  COMPLIANCE OF ALL TRANSACTIONS CARRIED OUT BY YOU USING THE
                  SERVICES.
                </span>
                <b>1. ACCOUNTS</b>
              </p>
              <p>
                <b>1.1 </b>
                <span>
                  Account Creation. You must register for an account ("Account")
                  and submit certain information about yourself as requested by
                  the account registration form in for using certain features of
                  the Site. You represent and warrant that:{" "}
                </span>
                <b>(a) </b>
                <span>
                  all mandatory registration information you enter is true and
                  accurate;{" "}
                </span>
                <b>(b) </b>
                <span>
                  you will maintain the correctness of such information; and{" "}
                </span>
                <b>(c) </b>
                <span>
                  your usage of the Services is and will be consistent with
                  applicable law. You may delete your Account at any time and
                  for any reason by following the Site's instructions. Queenbots
                  may suspend or terminate your Account in accordance with{" "}
                </span>
                <b>Section 9.1.2 Identity Verification.</b>
              </p>
              <p>
                <span>
                  You agree to provide us with the information we need for the
                  purposes of verifying your identification and detecting money
                  laundering, terrorist funding, fraud, or any other financial
                  crime, and to allow us to retain a record of it. Before you
                  are able to use the Services, you must complete certain
                  verification procedures, and your access to the Services may
                  be restricted as a result of information collected on an
                  ongoing basis. The information we request may include certain
                  personal information, including, but not limited to, your
                  name, address, telephone number, e-mail address, date of
                  birth, taxpayer identification number, government
                  identification number, and information regarding your bank
                  account (such as the name of the bank, the account type,
                  routing number, and account number). In providing us with this
                  or any other information that may be required, you confirm
                  that the information is accurate and authentic. You agree to
                  update this information and keep it current at all times. You
                  authorize us to make the inquiries, whether directly or
                  through third parties, that we consider appropriate, in our
                  sole discretion, to verify your identity or protect you and/or
                  us against fraud or other financial crimes, and to take action
                  we reasonably deem necessary based on the results of such
                  inquiries.
                </span>
                <b>1.3 Certain Restrictions.</b>
                <span>
                  {" "}
                  By using the Services, you represent and warrant that:{" "}
                </span>
                <b>(i) </b>
                <span>
                  neither you nor any of your directors, officers, employees,
                  agents, affiliates or representatives is an individual or an
                  entity that is, or is owned or controlled by an individual or
                  entity that is{" "}
                </span>
                <b>(a) </b>
                <span>currently the subject of any Sanctions, or </span>
                <b>(b)</b>
                <span>
                  {" "}
                  located, organized or resident in a Designated Jurisdiction;{" "}
                </span>
                <b>(ii)</b>
                <span>
                  {" "}
                  you have complied in all material respects with all applicable
                  laws relating to Sanctions, anti-terrorism, anti-corruption
                  and anti-money laundering; and (iii) you have instituted and
                  maintained policies and procedures designed to promote and
                  achieve compliance with such laws. For the purpose of this
                  Agreement, “Designated Jurisdiction” means any country or
                  territory to the extent that such country or territory itself
                  is the subject of any Sanction; “Sanction(s)” means any
                  international economic sanction administered or enforced by
                  the United States Government (including OFAC), the United
                  Nations Security Council, the European Union or other relevant
                  sanctions authority.
                </span>
                <b>1.4 Account Responsibilities. </b>
                <span>
                  You are entirely responsible for all activities that occur
                  under your Account, including all repercussions under
                  applicable laws, and for maintaining the confidentiality of
                  your Account login credentials. You agree to alert Queenbots
                  immediately of any unauthorised or suspected unlawful use of
                  your Account, as well as any other security violation.
                  Queenbots cannot and will not be held liable for any loss or
                  damage caused by your failure to follow the above
                  instructions.
                </span>
                <b>1.5 Compliance.</b>
                <span>
                  {" "}
                  You are solely responsible for ensuring that your use of the
                  Services complies with all applicable laws, rules, and
                  regulations, and your right to use the Services is
                  automatically revoked where such use is prohibited or the
                  offering, sale, or provision of the Services conflicts with
                  any applicable law, rule, or regulation.
                </span>
                <b>1.6 Available Only Where Permitted by Law.</b>
                <span>
                  {" "}
                  means know your customer requirements as determined by
                  Queenbots or under the AML Regulations.The Services are only
                  available in jurisdictions where they may be legally offered
                  for sale. The Services are not available to “U.S. Persons”, as
                  such term is defined under Regulation S promulgated under the
                  Securities
                </span>
                <b> Act of 1933</b>
                <span>
                  . By accessing or using the Site, you represent and warrant
                  that you are not a U.S. Person.
                </span>
                <b>2. ACCESS TO THE SERVICES2.1</b>
                <span>
                  {" "}
                  License. Subject to this Agreement, Queenbots grants you a
                  non-transferable, non-exclusive, revocable, limited license to
                  use and access the Services solely for your own personal or
                  internal business purposes, in accordance with the Account
                  type you are currently subscribing to.
                </span>
              </p>
              <p>
                <b>2.2</b>
                <span>
                  {" "}
                  Modification. Queenbots reserves the right, at any time, to
                  modify, suspend, or discontinue the Services (in whole or in
                  part) with or without notice to you. You agree that Queenbots
                  will not be liable to you or to any third party for any
                  modification, suspension, or discontinuation of the Services
                  or any part thereof.
                </span>
              </p>
              <p>
                <b>2.3</b>
                <span>
                  {" "}
                  No Support or Maintenance. You acknowledge and agree that
                  Queenbots will have no obligation to provide you with any
                  support or maintenance in connection with the Services.{" "}
                </span>
                <b>2.4</b>
                <span>
                  {" "}
                  Ownership. You acknowledge that all the intellectual property
                  rights, including copyrights, patents, trademarks, and trade
                  secrets, in the Services and its content are owned by
                  Queenbots or its suppliers. Neither this Agreement (nor your
                  access to the Services) transfers to you or any third party
                  any rights, title or interest in or to such intellectual
                  property rights, except for the limited access rights
                  expressly set forth in{" "}
                </span>
                <b>Section 2.1.</b>
              </p>
              <p>
                <span>
                  Queenbots and its suppliers reserve all rights not granted in
                  this Agreement. There are no implied licenses granted under
                  this Agreement.
                </span>
              </p>
              <p>
                <b>2.5 Feedback. </b>
                <span>
                  If you submit Queenbots with any feedback or recommendations
                  about the Site ("Feedback"), you hereby assign to Queenbots
                  all rights in such Feedback and agree that Queenbots may use
                  and fully exploit such Feedback and related material in any
                  way it sees fit. Any Feedback you send to Queenbots will be
                  treated as non-confidential and non-proprietary. You agree not
                  to send any information or ideas to Queenbots that you
                  consider to be confidential or proprietary.
                </span>
                <b>3. PROHIBITED ACTIVITIES</b>
              </p>
              <p>
                <b>3.1 Prohibited Activities.</b>
                <span>
                  {" "}
                  In connection with your use of Services, you hereby agree that
                  you will not:
                </span>
              </p>
              <p>
                <b>(a)</b>
                <span>
                  {" "}
                  violate (or assist any other party in violating) any
                  applicable law, statute, ordinance, or regulation;
                </span>
              </p>
              <p>
                <b>(b)</b>
                <span>
                  {" "}
                  intentionally try to defraud (or assist in the defrauding of)
                  Queenbots or any other User;
                </span>
              </p>
              <p>
                <b>(c)</b>
                <span>
                  {" "}
                  provide false, inaccurate, or misleading information;
                </span>
              </p>
              <p>
                <b>(d)</b>
                <span>
                  {" "}
                  trade taking advantage of any technical glitch, malfunction,
                  failure, delay, default, or security breach;
                </span>
              </p>
              <p>
                <b>(e) </b>
                <span>
                  take any action that interferes with, intercepts, or
                  expropriates any system, data, or information;
                </span>
              </p>
              <p>
                <b>(f)</b>
                <span>
                  {" "}
                  partake in any transaction involving the proceeds of illegal
                  activity;
                </span>
              </p>
              <p>
                <b>(g)</b>
                <span>
                  {" "}
                  attempt to gain unauthorized access to other Queenbots
                  Accounts, the Services, or any related networks or systems;
                </span>
              </p>
              <p>
                <b>(h)</b>
                <span>
                  {" "}
                  use the Services on behalf of any third party or otherwise act
                  as an intermediary between Queenbots and any third parties;
                </span>
              </p>
              <p>
                <b>(i)</b>
                <span>
                  {" "}
                  use the Services to engage in conduct that is detrimental to
                  Queenbots or to any other User or any other third party;
                </span>
              </p>
              <p>
                <b>(j)</b>
                <span>
                  {" "}
                  collect any user information from other Users, including,
                  without limitation, email addresses;
                </span>
              </p>
              <p>
                <b>(k)</b>
                <span>
                  {" "}
                  defame, harass, or violate the privacy or intellectual
                  property rights of Queenbots or any other User; o{" "}
                </span>
                <b>(l)</b>
                <span>
                  {" "}
                  upload, display or transmit any messages, photos, videos or
                  other media that contain illegal goods, pornographic, violent,
                  obscene or copyrighted images or materials for use as an
                  avatar, in connection with a payment or payment request, or
                  otherwise.
                </span>
              </p>
              <p>
                <b>(m)</b>
                <span> In addition, you agree not to:</span>
              </p>
              <p>
                <b>(i)</b>
                <span>
                  {" "}
                  upload, transmit, or distribute to or through the Services any
                  computer viruses, worms, or any software intended to damage or
                  alter a computer system or data;
                </span>
              </p>
              <p>
                <b>(ii)</b>
                <span>
                  {" "}
                  interfere with, disrupt, or create an undue burden on servers
                  or networks connected to the Services, or violate the
                  regulations, policies or procedures of such networks;
                </span>
              </p>
              <p>
                <b>(iii)</b>
                <span>
                  {" "}
                  attempt to reverse engineer, de-compile, disable, interfere
                  with, disassemble, copy, or disrupt the integrity or the
                  performance of the Services, any third-party use of the
                  Services, or any third-party data contained therein (except to
                  the extent such restrictions are prohibited by applicable law;
                  or
                </span>
              </p>
              <p>
                <b>(iv)</b>
                <span>
                  {" "}
                  access the Services in order to build a competitive product or
                  service or copy any ideas, features, functions, or graphics of
                  the Services.
                </span>
              </p>
              <p>
                <b>3.2 Enforcement.</b>
              </p>
              <p>
                <span>
                  We reserve the right (but have no obligation) to investigate
                  and/or take appropriate action against you in our sole
                  discretion if you engage in Prohibited Activities or violate
                  any other provision of this Agreement or otherwise create
                  liability for us or any other person. Such action may include,
                  at our sole and absolute discretion, cancelling your Account
                  in accordance with
                </span>
                <b> Section 9,</b>
                <span>
                  {" "}
                  reporting you to law enforcement authorities without giving
                  you with any notice of such report, and confiscating any
                  residual balance in a terminated Account.
                </span>                
              </p>
              <p><b>4. THIRD-PARTY PRODUCTS AND SERVICES; OTHER USERS</b></p>
              <p>
                <b>4.1</b>
                <span>
                  {" "}
                  Third-Party Services. You accept that the Service will permit
                  or assist you in accessing, interacting with, and/or
                  purchasing services from numerous supported platforms and
                  other third parties (collectively, "Third-Party Services").
                  Queenbots does not evaluate, approve, monitor, endorse,
                  warrant, or make any statements with respect to Third-Party
                  Services, and Queenbots is not liable for any Third-Party
                  Services. All Third-Party Services are used at your own risk,
                  and you should exercise appropriate caution and prudence when
                  doing so. Any contract entered into, or any transaction
                  completed via any Third-Party Services, is between you and the
                  relevant third party, not with Queenbots. All applicable terms
                  of the Third-Party Services that you access or subscribe to in
                  conjunction with the Services must be followed in full. If any
                  Third-Party Services stop making their programmes available to
                  us on fair terms, we may stop providing those features to you
                  without providing you with a refund, credit, or other form of
                  compensation.
                </span>
              </p>
              <p>
                <b>4.2</b>
                <span>
                  {" "}
                  Smart Token Funds; Traders from the STF. Queenbots enables you
                  to connect with STF Traders that offer and manage Smart Token
                  Funds (collectively referred to as "STF Traders" in these
                  Terms of Service). Your interactions with any STF Trader,
                  including any Smart Token Fund transactions you may enter into
                  with such STF Trader, are solely between you and that STF
                  Trader. While Queenbots is designed to let you communicate and
                  interact with other Users and STF Traders, we are not a party
                  to any transaction you may have with another User or STF
                  Trader, including any offer, buy, or sale of Smart Tokens. We
                  are not an agent, representative, or promoter of any Smart
                  Token Fund or STF Trader, and use of the Services by an STF
                  Trader or with respect to a Smart Token Fund does not imply or
                  indicate any endorsement by us of such STF Trader or Smart
                  Token Fund. We provide no recommendation or endorsement of any
                  Smart Token Fund mentioned on the Services, and we are not
                  liable for any STF Trader or other Service User's conduct or
                  omissions.
                </span>
              </p>
              <p>
                <b>4.3 </b>
                <span>
                  Other Users. We do not guarantee the identity of any user or
                  third party nor do we guarantee that a buyer or seller is
                  authorised to complete or will complete a transaction. Other
                  Users' interactions with you are solely between you and such
                  users. You acknowledge and agree that Queenbots is not liable
                  for any loss or damage incurred as a result of such exchanges.
                  If you and another User have a disagreement, we are under no
                  obligation to intervene.
                </span>
              </p>
              <p>
                <b>4.4</b>
                <span>
                  {" "}
                  Release. You hereby release and forever discharge Queenbots
                  (and our officers, employees, agents, successors, and assigns)
                  from, and waive and relinquish, any and all past, present, and
                  future dispute, claim, controversy, demand, right, obligation,
                  liability, action, and cause of action of any kind and nature
                  (including personal injuries, death, and property damage),
                  that has arisen or arises directly or indirectly out of, or
                  that relates directly or indirectly to, the (including any
                  interactions with, or act or omission of, other Users or any
                  ThirdParty Products and Services).
                </span>
              </p>              
              <b>5. THE QUEENBOTS WALLET (OFFERED AND OPERATED BY BINANCE)</b>
              <p>
                <b>5.1</b>
                <span>
                  {" "}
                  Supported Cryptocurrencies. The Queenbots wallet allows you to
                  send, receive and store cryptocurrency (together, “Wallet
                  Transactions”). The Queenbots wallet is only available in
                  relation to the cryptocurrencies that Queenbots, in its sole
                  discretion, decides to support (“Supported Cryptocurrency” or
                  “Supported Cryptocurrencies”). Information about the Supported
                  Cryptocurrencies can be found on the FAQ page on the Queenbots
                  website. The Supported Cryptocurrencies may change from time
                  to time. Under no circumstances should you attempt to carry
                  out a Queenbots wallet Transaction in relation to a
                  cryptocurrency other than a Supported Cryptocurrency.
                </span>
              </p>
              <p>
                <b>5.2</b>
                <span>
                  {" "}
                  Transaction instructions. Queenbots will process Wallet
                  Transactions according to your instructions. You acknowledge
                  and agree that Queenbots does not:{" "}
                </span>
                <b>(a)</b>
                <span>
                  {" "}
                  guarantee the identification of any user, receiver, or other
                  party to a Queenbots Wallet Transaction; or guarantee the
                  security of any Queenbots Wallet Transaction. You are solely
                  responsible for ensuring that all transaction details are
                  correct, and you should carefully verify all transaction
                  information before submitting transaction instructions to
                  Queenbots; or{" "}
                </span>
                <b>(b)</b>
                <span>
                  {" "}
                  have any control over, or liability for, the delivery,
                  quality, or any other aspect of any goods or services that you
                  may buy from or sell to any third party. Queenbots is not
                  responsible for verifying that any buyer or seller you
                  interact with using your Queenbots wallet completes the
                  transaction or has the necessary power to do so, and will take
                  no action in this regard.
                </span>
              </p>
              <p>
                <b>5.3</b>
                <span>
                  {" "}
                  Receiving cryptocurrency. By supplying the sender with a
                  receive address generated in your Queenbots wallet, you can
                  receive Supported Cryptocurrency into your Queenbots wallet.
                  Only Supported Cryptocurrency sent to a receive address
                  produced by your Queenbots wallet and associated with that
                  Supported Cryptocurrency will be credited to your Queenbots
                  wallet. For example, your Queenbots wallet will be credited
                  with ETH when it is sent to an ETH receive address generated
                  through your Queenbots wallet.
                </span>
              </p>
              <p>
                <b>5.4</b>
                <span>
                  {" "}
                  Fiat Currency P2P transactions. Queenbots allows you to use
                  fiat currency funds purely in exchange for select Supported
                  Cryptocurrencies (such as USDT) and in so-called "P2P
                  transactions" with other Queenbots Users. In a P2P
                  transaction, Queenbots maintains the digital currency in
                  escrow between the two counterparties until the buyer and
                  seller Users have confirmed payment in fiat currency funds. As
                  soon as payment is confirmed, the Supported Cryptocurrency
                  (for example, USDT) is released. In this approach, Queenbots
                  does not take custody of or facilitate the transfer of fiat
                  currency funds, and the transfer of fiat currency funds is
                  solely between Users, with Queenbots bearing no obligation or
                  liability in this regard. Without prejudice to the foregoing,
                  you hereby grant Queenbots the right to resolve issues and
                  disputes among Users in its sole discretion.
                </span>
              </p>
              <p>
                <b>5.5</b>
                <span>
                  {" "}
                  Trading between Supported Cryptocurrencies. Queenbots allows
                  you to trade with other Users between various Supported
                  Cryptocurrencies (e.g., BTC-ETH, BTC-LTC etc.).
                </span>
              </p>
              <p>
                <b>5.6</b>
                <span>
                  {" "}
                  Sharing User Information. Queenbots may be compelled to share
                  your user information with other contractual third parties in
                  the course of completing a Wallet Transaction, or as required
                  by applicable laws or demanded upon a legitimate request by
                  any governmental entity. You hereby irrevocably grant
                  Queenbots full permission and authority to share this
                  information with such contractual third parties, or as
                  required by applicable laws or demanded upon a lawful request
                  by any governmental authority, and you release Queenbots from
                  any liability, error, mistake, or negligence in connection
                  therewith.
                </span>
              </p>
              <p>
                <b>5.7</b>
                <span>
                  {" "}
                  Trade Corrections. You acknowledge and agree that Queenbots
                  may, in its sole discretion, correct, reverse, or cancel any
                  order, trade, transfer, or other transaction or activity in
                  which Queenbots discovers an error or abnormality, whether
                  caused by you, Queenbots, or a third party, or due to
                  technical issues, system issues, software failure, or
                  otherwise. Queenbots makes no promise or warranty that such an
                  endeavour would be successful, and assumes no responsibility
                  or liability for any error(s) or attempt(s) to fix them.
                </span>
                <b>
                  6. THE QUEENBOTS PAIRS (OFFERED AND OPERATED BY QUEENBOTS)
                </b>
                <span>
                  {" "}
                  Queenbots enables Users to trade with each other in trading
                  pairs and use balances which are denominated notionally in
                  Indian Rupees only for convenience in order to enable Users to
                  calculate the value of the relevant Supported Cryptocurrency
                  being traded (“Queenbots Pairs”). Any reference to Indian
                  Rupees or INR on Queenbots should not be construed as a
                  reference to Indian Rupees amounting to legal tender
                  (otherwise referred to as ‘fiat currency’ or ‘real money’)
                  unless specifically provided herein. While P2P transactions
                  are offered and operated by Binance (as described above),
                  non-P2P transactions are offered and operated by Queenbots.
                  Terms applicable to Indian Resident Users for Queenbots Pairs
                  In non-P2P transactions, Users resident in India (as defined
                  under the Foreign Exchange Management Act, 1999) add to their
                  INR balance by depositing Indian Rupees from their registered
                  bank account / payment instrument to Queenbots’s or its
                  partners’ bank account using regulated banking and payment
                  channels, and can redeem their INR balance to their bank
                  accounts / payment instrument by placing a withdrawal request
                  to Queenbots, upon which request Queenbots will transfer the
                  INR balance amount to the Users’ registered bank account /
                  payment instrument, using regulated banking and payment
                  channels, subject to Know Your Customer and other safeguards
                  carried out in Queenbots’s sole discretion, and subject to
                  withdrawal limits published on the website / mobile
                  application from time to time. In this model, Queenbots merely
                  acts as a duly appointed agent of the User to whom the payment
                  is due, and does not operate a payment system. You hereby duly
                  appoint Queenbots as your agent for this purpose. Terms
                  applicable to Non-Indian Resident Users for Queenbots Pairs In
                  all circumstances, Users not resident in India (as defined
                  under the Foreign Exchange Management Act, 1999) (“Foreign
                  Users”) can use limited features of the Services. Foreign
                  Users cannot in any circumstance receive or make payments in
                  Indian Rupees or equivalents or any other fiat currency or
                  equivalents (otherwise referred to as ‘real money’ or ‘fiat
                  currency’). Foreign Users can only use the Services to trade
                  between Supported Cryptocurrencies without any interface to
                  fiat currency. Foreign Users are not entitled to redeem any
                  INR balance with Queenbots for Indian Rupees (unlike Users
                  resident in India who may withdraw their INR balance to their
                  bank accounts) or any other fiat currency. The INR balance of
                  a Foreign User is only denoted on the platform for convenience
                  and can only be used by a Foreign User to further trade in
                  Supported Cryptocurrencies. Foreign Users do not therefore
                  carry out any transactions in fiat currency using the Services
                  and merely hold Supported Cryptocurrencies in their Queenbots
                  wallets and trade between Supported Cryptocurrencies. This
                  Clause 6 applies mutatis mutandis to the Queenbots Pairs
                  offered on Binance.com and all Binance desktop and mobile
                  applications.
                </span>
                <b>7. FEES.</b>
              </p>
              <p>
                <b>7.1</b>
                <span>
                  {" "}
                  General. You agree to pay Queenbots the fees listed, which may
                  be adjusted at our sole discretion from time to time. Any
                  updated fees will be effective for any trades or other
                  transactions that occur after the effective date of the
                  updated fees. You authorise Queenbots to deduct any applicable
                  fees owed by you under this Agreement from your Account.
                </span>
              </p>
              <p>
                <b>7.2</b>
                <span>
                  {" "}
                  Withdrawal / Send fees. You may be charged a fee to send or
                  withdraw a Supported Cryptocurrency from your Queenbots
                  wallet. We currently do not charge fees to deposit or receive
                  Supported Cryptocurrency into your Queenbots wallet.
                </span>
              </p>
              <p>
                <b>7.3</b>
                <span>
                  {" "}
                  Service fees. For users who use the Services to trade
                  Supported Cryptocurrency, Queenbots has a Maker / Taker fee
                  structure. Any fees that will apply to any transaction on the
                  Queenbots Exchange will be communicated to you by Queenbots at
                  the time of the transaction. You accept and consent to the
                  applicable fees by proceeding with any transaction. Upon
                  completion of the transaction, such fees will be visible in
                  your transaction history.
                </span>
                <b>8. CONTESTS</b>
                <span>
                  {" "}
                  We may offer you the opportunity to participate in skill
                  contests ("Contest" or "Contests") from time to time. You
                  accept and agree to be bound and abide by the applicable
                  Contest terms and conditions, which will be announced on the
                  Queenbots website from time to time and incorporated herein by
                  reference (the "Contest Terms"). For whatever reason,
                  including but not limited to product availability, pricing
                  issues, or diminished demand, Queenbots has the right to amend
                  the Contest Terms or stop any contests at any time. Prizes,
                  promotions, and special pricing are subject to change or
                  substitution without notice at any moment. Taxes on any prize
                  or reward are the recipient's sole responsibility.
                </span>
                <b>9. SUSPENSION, TERMINATION, AND CANCELLATION.</b>
                <span>
                  {" "}
                  9.1 General. This Agreement will continue to apply to you
                  until either you or Queenbots terminates it. Any provision of
                  this Agreement that is required to survive by its terms or to
                  give effect to its meaning, as well as any other provisions
                  that are expressly, or by their nature, intended to survive
                  termination, shall survive the expiration or termination of
                  this Agreement.
                </span>
              </p>
              <p>
                <b>9.2</b>
                <span>
                  {" "}
                  Suspension, Termination. In our sole and absolute discretion,
                  we may terminate your Account or suspend your access to the
                  Services at any time and with immediate effect for any reason
                  or no reason. In our sole discretion, we may refuse to process
                  any deposit or withdrawal without prior notice, and we may
                  limit or suspend your use of one or more Services at any time.
                  For example, if we believe a transaction is suspicious, may
                  include fraud or misconduct, violates relevant laws, or
                  violates the terms of this Agreement, we may, in our sole
                  discretion, delay deposits or withdrawals. If you have a
                  balance in an Account that has been suspended, we may freeze
                  that amount for the duration of the suspension. If the Account
                  is terminated due to fraud, violation of law, or violation of
                  this Agreement, Queenbots may, in its discretion, confiscate
                  any balance remaining in the Account and deliver it to any
                  applicable government, law enforcement, or other authorities
                  where circumstances warrant.
                </span>
              </p>
              <p>
                <b>9.3</b>
                <span>
                  {" "}
                  Cancellation. You may cancel your Account at any moment by
                  withdrawing all of your funds and sending us a note through
                  the contact page requesting cancellation. You remain liable
                  for all transactions made while the Account was open even if
                  this Agreement and your Account are terminated.
                </span>
                <b>10. DISCLAIMERS</b>
                <span>
                  {" "}
                  THE SERVICES ARE PROVIDED ON AN “AS-IS” AND “AS AVAILABLE”
                  BASIS, AND QUEENBOTS EXPRESSLY DISCLAIMS ANY AND ALL
                  WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS,
                  IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS
                  OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                  QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE (AND OUR
                  SUPPLIERS) MAKE NO WARRANTY THAT THE SERVICES WILL MEET YOUR
                  REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY,
                  SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE,
                  FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR
                  SAFE. IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT
                  TO THE SITE, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO
                  NINETY (90) DAYS FROM THE DATE OF FIRST USE. QUEENBOTS
                  DISCLAIMS ANY AND ALL RESPONSIBILITY OR LIABILITY IN RELATION
                  TO THE CONTENT MADE AVAILABLE THROUGH THE SERVICES, INCLUDING
                  THE CUSTOMER CONTENT, OR ANY CONTENT OR SERVICES PROVIDED BY
                  THIRD PARTIES. QUEENBOTS DOES NOT CONTROL OR VET CUSTOMER
                  CONTENT AND IS NOT RESPONSIBLE FOR WHAT USERS POST, TRANSMIT,
                  OR SHARE ON OR THROUGH THE SERVICES. QUEENBOTS IS NOT
                  RESPONSIBLE OR LIABLE IN ANY MANNER FOR ANY THIRD-PARTY
                  SERVICES ASSOCIATED WITH OR UTILIZED IN CONNECTION WITH THE
                  SERVICES, INCLUDING THE FAILURE OF ANY SUCH THIRD-PARTY
                  SERVICES OR SUPPORTED PLATFORMS. SOME JURISDICTIONS DO NOT
                  ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE
                  EXCLUSION MAY NOT APPLY TO YOU. SOME JURISDICTIONS DO NOT
                  ALLOWLIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE
                  ABOVE LIMITATION MAY NOT APPLY TO YOU.
                </span>
                <b>11. INDEMNIFICATION.</b>
                <span>
                  {" "}
                  You agree to defend, indemnify, and hold Queenbots (and its
                  officers, employees, and agents) harmless from any third-party
                  claim or demand arising out of{" "}
                </span>
                <b>(a)</b>
                <span> your use of the Services, </span>
                <b>(b)</b>
                <span> your violation of this Agreement,</span>
                <b> (c)</b>
                <span>
                  {" "}
                  your violation of applicable laws or regulations, or
                </span>
                <b> (d)</b>
                <span>
                  {" "}
                  Third-Party Services. Queenbots maintains the right to assume
                  sole defence and control of any issue for which you are
                  obligated to indemnify us, at your expense, and you agree to
                  cooperate with our defence of these claims. You undertake not
                  to settle any dispute without Queenbots' prior written
                  authorization. Queenbots will use reasonable efforts to notify
                  you of any such claim, action or proceeding upon becoming
                  aware of it.
                </span>
                <b>12. LIMITATION OF LIABILITY.</b>
                <span>
                  {" "}
                  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                  WILL QUEENBOTS, ITS AFFILIATES AND THEIR RESPECTIVE
                  SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES,
                  ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS OR CONTRACTORS
                  BE LIABLE FOR ANY INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE,
                  CONSEQUENTIAL OR SIMILAR DAMAGES OR LIABILITIES WHATSOEVER
                  (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF DATA,
                  INFORMATION, REVENUE, PROFITS OR OTHER BUSINESS OR FINANCIAL
                  BENEFIT) ARISING OUT OF OR IN CONNECTION WITH THE SERVICES,
                  ANY PERFORMANCE OR NON-PERFORMANCE OF THE SERVICES, OR ANY
                  OTHER PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF
                  OF QUEENBOTS AND ITS AFFILIATES, WHETHER UNDER CONTRACT,
                  STATUTE, STRICT LIABILITY OR OTHER THEORY EVEN IF QUEENBOTS
                  HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES EXCEPT TO
                  THE EXTENT OF A FINAL JUDICIAL DETERMINATION THAT SUCH DAMAGES
                  WERE A RESULT OF QUEENBOTS’S GROSS NEGLIGENCE, FRAUD, WILLFUL
                  MISCONDUCT OR INTENTIONAL VIOLATION OF LAW. SOME JURISDICTIONS
                  DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL
                  ORCONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY
                  TO YOU. NOTWITHSTANDING THE FOREGOING, IN NO EVENT WILL THE
                  LIABILITY OF QUEENBOTS, ITS AFFILIATES AND THEIR RESPECTIVE
                  SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES,
                  ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS OR CONTRACTORS
                  ARISING OUT OF OR IN CONNECTION THE SERVICES, ANY PERFORMANCE
                  OR NON-PERFORMANCE OF THE SERVICES, OR ANY OTHER PRODUCT,
                  SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF QUEENBOTS OR
                  ITS AFFILIATES WHETHER UNDER CONTRACT, STATUTE, STRICT
                  LIABILITY OR OTHER THEORY, EXCEED THE AMOUNT OF THE FEES PAID
                  BY YOU TO QUEENBOTS UNDER THIS AGREEMENT IN THE TWELVE-MONTH
                  PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THECLAIM
                  FOR LIABILITY.
                </span>
                <b>13. GENERAL13.1</b>
                <span>
                  {" "}
                  Dispute Resolution. Please read this Arbitration Agreement
                  carefully. It is part of your contract with Queenbots and
                  affects your rights. It contains procedures for MANDATORY
                  BINDING ARBITRATION AND A CLASS ACTION WAIVER.
                </span>
              </p>
              <p>
                <b>(a)</b>
                <span>
                  {" "}
                  Any dispute, controversy or claim arising out of or relating
                  to this contract, or the breach, termination or invalidity
                  thereof, shall be settled by arbitration as follows:
                </span>
              </p>
              <p>
                <b>(i)</b>
                <span>
                  {" "}
                  In the case of disputes, controversies or claims involving
                  Binance: You and Binance agree to resolve any claims relating
                  to this Agreement (including any question regarding their
                  existence, validity, termination, or any services or products
                  provided and any representations made by us) through final and
                  binding arbitration, except as set forth under Exceptions to
                  Agreement to Arbitrate below. You agree to first give us an
                  opportunity to resolve any claims by contacting us on our
                  website / mobile / desktop applications. If we are not able to
                  resolve your claims within 60 days of receiving the notice,
                  you may seek relief through arbitration or in the Small Claims
                  Tribunals of Singapore{" "}
                </span>
                <b>(“SCT”), </b>
                <span>
                  as set forth below. Either you or Binance may submit a dispute
                  (after having made good faith efforts to resolve such dispute)
                  for final and binding resolution by arbitration under the
                  arbitration rules of the Singapore International Arbitration
                  Centre
                </span>
                <b> (“SIAC”),</b>
                <span>
                  {" "}
                  which are deemed to be incorporated into these Terms by
                  reference. The arbitration tribunal shall consist of a sole
                  arbitrator to be appointed by the President of SIAC. The
                  language of the arbitration hearings shall be English and the
                  seat, or legal place, of arbitration shall be Singapore.
                  JUDGMENT ON ANY ARBITRAL AWARD MAY BE GIVEN IN ANY COURT
                  HAVING JURISDICTION OVER THE PARTY (OR OVER THE ASSETS OF THE
                  PARTY) AGAINST WHOM SUCH AN AWARD IS RENDERED. Exceptions.
                  Either party may instead choose to assert the claims in the
                  SCT if the claims fall within the jurisdiction of the SCT, and
                  either party may seek injunctive relief or other urgent
                  equitable relief in a court of competent jurisdiction.
                  However, for the avoidance of doubt, where the claims fall
                  outside of the jurisdiction of the SCT, the claims will be
                  referred to and finally resolved by SIAC arbitration.
                </span>
              </p>
              <p>
                <b>(ii)</b>
                <span>
                  {" "}
                  In the case of disputes, controversies or claims involving
                  Queenbots, you and Queenbots agree to resolve any claims
                  relating to this Agreement (including any question regarding
                  their existence, validity, termination, or any services or
                  products provided and any representations made by us) through
                  final and binding arbitration. You agree to first give us an
                  opportunity to resolve any claims by contacting us on our
                  website / mobile / desktop applications. If we are not able to
                  resolve your claims within 60 days of receiving the notice,
                  you may seek relief through arbitration as set forth below.
                  Either you or Queenbots may submit a dispute (after having
                  made good faith efforts to resolve such dispute) for final and
                  binding resolution by arbitration under the arbitration rules
                  of the Singapore International Arbitration Centre (“SIAC”),
                  which are deemed to be incorporated into these Terms by
                  reference, read with the Indian Arbitration and Conciliation
                  Act, 1996. The arbitration tribunal shall consist of a sole
                  arbitrator to be appointed by the President of SIAC. The
                  language of the arbitration hearings shall be English and the
                  seat of arbitration shall be Singapore.
                </span>
              </p>
              <p>
                <b>(b)</b>
                <span>
                  {" "}
                  Waiver of Jury Trial. THE PARTIES HEREBY WAIVE THEIR RIGHTS TO
                  GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, as
                  applicable, instead electing that all claims and disputes
                  shall be resolved by arbitration under this Arbitration
                  Agreement. Arbitration procedures are typically more limited,
                  more efficient and less costly than rules applicable in a
                  court and are subject to very limited review by a court. In
                  the event any litigation should arise between you and
                  Queenbots in any state or federal court in a suit to vacate or
                  enforce an arbitration award or otherwise, YOU AND QUEENBOTS
                  WAIVE ALL RIGHTS TO A JURY TRIAL, instead electing that the
                  dispute be resolved by a judge.
                </span>
              </p>
              <p>
                <b>(c)</b>
                <span>
                  {" "}
                  Waiver of Class or Consolidated Actions. ALL CLAIMS AND
                  DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST
                  BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A
                  CLASS BASIS, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER
                  CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH
                  THOSE OF ANY OTHER CUSTOMER OR USER.
                </span>
              </p>
              <p>
                <b>(d). Confidentiality.</b>
                <span>
                  {" "}
                  All aspects of the arbitration proceeding, including but not
                  limited to the award of the arbitrator and compliance
                  therewith, shall be strictly confidential. The parties agree
                  to maintain confidentiality unless otherwise required by law.
                  This paragraph shall not prevent a party from submitting to a
                  court of law any information necessary to enforce this
                  Agreement, to enforce an arbitration award, or to seek
                  injunctive or equitable relief,
                </span>
              </p>
              <p>
                <b>(e)</b>
                <span>
                  {" "}
                  Severability. If any part or parts of this Arbitration
                  Agreement are found under the law to be invalid or
                  unenforceable by a court of competent jurisdiction, then such
                  specific part or parts shall be of no force and effect and
                  shall be severed and the remainder of the Agreement shall
                  continue in full force and effect.
                </span>
              </p>
              <p>
                <b>(f)</b>
                <span>
                  {" "}
                  Right to Waive. Any or all of the rights and limitations set
                  forth in this Arbitration Agreement may be waived by the party
                  against whom the claim is asserted. Such waiver shall not
                  waive or affect any other portion of this Arbitration
                  Agreement.
                </span>
              </p>
              <p>
                <b>(g)</b>
                <span>
                  {" "}
                  Survival of Agreement. This Arbitration Agreement will survive
                  the termination of your relationship with Queenbots.
                </span>
              </p>
              <p>
                <b>(h)</b>
                <span>
                  {" "}
                  Emergency Equitable Relief. Notwithstanding the foregoing,
                  either party may seek emergency equitable relief before a
                  court of competent jurisdiction in order to maintain the
                  status quo pending arbitration. A request for interim measures
                  shall not be deemed a waiver of any other rights or
                  obligations under this Arbitration Agreement.
                </span>
              </p>
              <p>
                <b>(i)</b>
                <span>
                  {" "}
                  Claims Not Subject to Arbitration. Notwithstanding the
                  foregoing, claims of defamation and infringement or
                  misappropriation of the other party’s patent, copyright,
                  trademark or trade secrets shall not be subject to this
                  Arbitration Agreement.
                </span>
              </p>
              <p>
                <b>13.2</b>
                <span>
                  {" "}
                  Applicable Law. The laws of India excluding its choice of law
                  provisions, will govern these Terms and any dispute that
                  arises between you and Queenbots.
                </span>
              </p>
              <p>
                <b>13.3</b>
                <span>
                  {" "}
                  Force Majeure. Any and all market movements, shifts, or
                  volatility; computer, server, or Internet malfunctions;
                  security breaches or cyberattacks; criminal acts; delays or
                  defaults caused by common carriers; acts of God or the state's
                  enemies; lawful acts of public authorities; any and all market
                  movements, shifts, or volatility; any and all market
                  movements, shifts, or volatility; any and all market
                  movements, shifts, or volatility; any and all market
                  movements, shifts, or volatility; any and all market
                  movements, shifts In the event of force majeure, Queenbots
                  will be released from all performance obligations, and this
                  Agreement shall come to a end completely and definitively.
                </span>
              </p>
              <p>
                <span>
                  13.4 Electronic Communications. Whether you use the Site or
                  send us emails, or Queenbots posts notifications on the Site
                  or communicates with you via email, you and Queenbots
                  communicate using electronic means. You (a) consent to receive
                  communications from Queenbots in an electronic format for
                  contractual purposes; and (b) agree that all terms and
                  conditions, agreements, notices, disclosures, and other
                  communications that Queenbots provides to you electronically
                  satisfy any legal requirement that such communications would
                  satisfy if they were written in hardcopy. The preceding has no
                  affect on your non-waivable rights.{" "}
                </span>
                <b>13.5 Entire Agreement. </b>
                <span>
                  This Agreement, together with Queenbots’s Privacy Policy,
                  constitute the entire agreement between you and us regarding
                  the use of the Services. Our failure to exercise or enforce
                  any right or provision of this Agreement shall not operate as
                  a waiver of such right or provision. The section titles in
                  this Agreement are for convenience only and have no legal or
                  contractual effect. The word “including” means “including
                  without limitation”. If any provision of this Agreement is,
                  for any reason, held to be invalid or unenforceable, the other
                  provisions of this Agreement will be unimpaired and the
                  invalid or unenforceable provision will be deemed modified so
                  that it is valid and enforceable to the maximum extent
                  permitted by law. You have an independent contractor
                  relationship with Queenbots, and neither side is an agent or
                  partner of the other. You may not assign, subcontract,
                  delegate, or otherwise transfer this Agreement or your rights
                  and duties hereunder without Queenbots' prior written
                  agreement, and any attempted assignment, subcontract,
                  delegation, or transfer in violation of the foregoing will be
                  null and void. Queenbots may freely assign this Agreement. The
                  terms and conditions set forth in this Agreement shall be
                  binding upon assignees.
                </span>
              </p>
              <p>
                <b>Privacy Policy</b>
              </p>
              <p>
                <span>
                  queenbots built the queenbots app as a Free app. This SERVICE
                  is provided by queenbots at no cost and is intended for use as
                  is.
                </span>
              </p>
              <p>
                <span>
                  This page is used to inform visitors regarding our policies
                  with the collection, use, and disclosure of Personal
                  Information if anyone decided to use our Service.
                </span>
              </p>
              <p>
                <span>
                  If you choose to use our Service, then you agree to the
                  collection and use of information in relation to this policy.
                  The Personal Information that we collect is used for providing
                  and improving the Service. We will not use or share your
                  information with anyone except as described in this Privacy
                  Policy.
                </span>
              </p>
              <p>
                <span>
                  The terms used in this Privacy Policy have the same meanings
                  as in our Terms and Conditions, which are accessible at
                  queenbots unless otherwise defined in this Privacy Policy.
                </span>
              </p>
              <p>
                <b>Information Collection and Use</b>
              </p>
              <p>
                <span>
                  For a better experience, while using our Service, we may
                  require you to provide us with certain personally identifiable
                  information, including but not limited to device id. The
                  information that we request will be retained by us and used as
                  described in this privacy policy.
                </span>
              </p>
              <p>
                <span>
                  The app does use third-party services that may collect
                  information used to identify you.
                </span>
              </p>
              <p>
                <span>
                  Link to the privacy policy of third-party service providers
                  used by the app
                </span>
              </p>
              <ul>
                <li aria-level="1">
                  <a href="https://www.google.com/policies/privacy/">
                    <span>Google Play Services</span>
                  </a>
                </li>
              </ul>
              <p>
                <b>Log Data</b>
              </p>
              <p>
                <span>
                  We want to inform you that whenever you use our Service, in a
                  case of an error in the app we collect data and information
                  (through third-party products) on your phone called Log Data.
                  This Log Data may include information such as your device
                  Internet Protocol (“IP”) address, device name, operating
                  system version, the configuration of the app when utilizing
                  our Service, the time and date of your use of the Service, and
                  other statistics.
                </span>
              </p>
              <p>
                <b>Cookies</b>
              </p>
              <p>
                <span>
                  Cookies are files with a small amount of data that are
                  commonly used as anonymous unique identifiers. These are sent
                  to your browser from the websites that you visit and are
                  stored on your device's internal memory.
                </span>
              </p>
              <p>
                <span>
                  This Service does not use these “cookies” explicitly. However,
                  the app may use third-party code and libraries that use
                  “cookies” to collect information and improve their services.
                  You have the option to either accept or refuse these cookies
                  and know when a cookie is being sent to your device. If you
                  choose to refuse our cookies, you may not be able to use some
                  portions of this Service.
                </span>
              </p>
              <p>
                <b>Service Providers</b>
              </p>
              <p>
                <span>
                  We may employ third-party companies and individuals due to the
                  following reasons:
                </span>
              </p>
              <ul>
                <li aria-level="1">
                  <span>To facilitate our Service;</span>
                </li>
                <li aria-level="1">
                  <span>To provide the Service on our behalf;</span>
                </li>
                <li aria-level="1">
                  <span>To perform Service-related services; or</span>
                </li>
                <li aria-level="1">
                  <span>
                    To assist us in analyzing how our Service is used.
                  </span>
                </li>
              </ul>
              <p>
                <span>
                  We want to inform users of this Service that these third
                  parties have access to their Personal Information. The reason
                  is to perform the tasks assigned to them on our behalf.
                  However, they are obligated not to disclose or use the
                  information for any other purpose.
                </span>
              </p>
              <p>
                <b>Security</b>
              </p>
              <p>
                <span>
                  We value your trust in providing us your Personal Information,
                  thus we are striving to use commercially acceptable means of
                  protecting it. But remember that no method of transmission
                  over the internet, or method of electronic storage is 100%
                  secure and reliable, and we cannot guarantee its absolute
                  security.
                </span>
              </p>
              <p>
                <b>Links to Other Sites</b>
              </p>
              <p>
                <span>
                  This Service may contain links to other sites. If you click on
                  a third-party link, you will be directed to that site. Note
                  that these external sites are not operated by us. Therefore,
                  we strongly advise you to review the Privacy Policy of these
                  websites. We have no control over and assume no responsibility
                  for the content, privacy policies, or practices of any
                  third-party sites or services.
                </span>
              </p>
              <p>
                <b>Children’s Privacy</b>
              </p>
              <p>
                <span>
                  These Services do not address anyone under the age of 13. We
                  do not knowingly collect personally identifiable information
                  from children under 13 years of age. In the case we discover
                  that a child under 13 has provided us with personal
                  information, we immediately delete this from our servers. If
                  you are a parent or guardian and you are aware that your child
                  has provided us with personal information, please contact us
                  so that we will be able to do the necessary actions.
                </span>
              </p>
              <p>
                <b>Changes to This Privacy Policy</b>
              </p>
              <p>
                <span>
                  We may update our Privacy Policy from time to time. Thus, you
                  are advised to review this page periodically for any changes.
                  We will notify you of any changes by posting the new Privacy
                  Policy on this page.
                </span>
              </p>
              <p>
                <span>This policy is effective as of 2023-01-30</span>
              </p>
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import DataTable from "react-data-table-component";

import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import config from "../lib/config/config";
import { connection } from "../helper/connection";
import ContractABI from "../ABI/DexbotABI.json";
import BUSDContractABI from "../ABI/BusdABI.json";
import QBTContractABI from "../ABI/QBTContarctABI.json";
import { toast } from "react-toastify";
import { getStakingLimit } from "../Actions/user";
import ReactLoading from "react-loading";
import {
  getPoolInfo,
  getStakeReward,
  ApproveToken,
  StakeForm,
  waithdrawReward,
} from "./stakeActions";
import moment from "moment";
import Newsletter from "../components/Newsletter.js";
import { useSelector } from "react-redux";

let toasterOption = config.toasterOption;

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Mining(props) {
  const [provider, setprovider] = useState();
  const [address, setaddress] = useState();
  const [userBal, setuserBal] = useState(0);
  const [userAllowance, setuserAllowance] = useState(0);
  const [poolAry, setPoolarray] = useState([]);
  const [userArray, setUserArray] = useState([]);
  const [rewardArray, setrewardArray] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [poolDetails, setPoolDetails] = useState([]);
  const [key, setKey] = useState(0);
  const [reward, setReward] = useState(0);
  const [stakelimit, setstakelimit] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [JoinNowstatus, setJoinNowstatus] = useState(false);
  const walletConnection = useSelector((state) => state.walletConnection);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    getQbtBalance();
    getpoolinfo();
    getstakinglimit();
    CheckIsUser();
  }, [walletConnection]);

  useEffect(() => {
    if (durationList[0] != undefined) {
      setUserData(durationList[0]);
    }
  }, [durationList]);

  useEffect(() => {
    EarnDatas();
  }, [poolAry]);

  async function CheckIsUser() {
    let address =
      walletConnection && walletConnection.address && walletConnection.network
        ? walletConnection.address
        : "";
    let provider =
      walletConnection && walletConnection.address && walletConnection.provider
        ? walletConnection.provider
        : undefined;

    console.log(address, provider, "jdhfgeradw", walletConnection);

    try {
      if (typeof provider !== "undefined") {
        var web3 = new Web3(provider);
        var DexbotContract = await new web3.eth.Contract(
          ContractABI,
          config.ContractAddress
        );
        var isRefferal = await DexbotContract.methods.userData(address).call();
        console.log(isRefferal.isUser, "isRefferal");
        setJoinNowstatus(isRefferal.isUser);
      }
    } catch (err) {}
  }

  const getpoolinfo = async () => {
    var info = await getPoolInfo();
    console.log(info, "infoinfoinfo");
    setUserArray(info.userArray);
    setPoolarray(info.poolArray);
    setrewardArray(info.rewardArr);
  };

  async function EarnDatas() {
    if (poolAry != undefined && poolAry.length != 0) {
      let durationArr = [];
      poolAry.map((item, index) => {
        if (item[4] == true && item[5] == true) {
          let durationVal = parseInt(item[1]);
          durationArr.push({
            value: durationVal,
            label: (parseInt(item[1]) / 30).toString() + " Months",
            key: index,
          });
        }
      });
      setDurationList(durationArr);
    }
  }

  const getstakinglimit = async () => {
    var rewardNew = await getStakingLimit();
    setstakelimit(rewardNew.data);
  };

  const setUserData = async (val) => {
    setKey(val.key);
    setUserDetails(userArray[val.key]);
    setPoolDetails(poolAry[val.key]);
    var rewardNew = await getStakeReward({ pid: val.key });
    console.log(rewardNew, "rewardNewrewardNew");
    setReward(rewardNew.resp);
  };

  async function getQbtBalance() {
    var { provider, address } = await connection();
    console.log(provider, "provider", address);
    setprovider(provider);
    setaddress(address);
    if (provider) {
      var web3 = new Web3(provider);
      try {
        if (typeof provider !== "undefined") {
          //balance
          var QBTContract = await new web3.eth.Contract(
            QBTContractABI,
            config.qbttokenAddress
          );
          var QBTtokenBln = await QBTContract.methods.balanceOf(address).call();

          setuserBal(QBTtokenBln / 10 ** 18);

          //Token balance
          var DexbotContract = await new web3.eth.Contract(
            ContractABI,
            config.ContractAddress
          );
          var tokenBal = await DexbotContract.methods
            .allowance(address, config.ContractAddress)
            .call();
          console.log(tokenBal, "tokenBal");
          setuserAllowance(tokenBal);
        }
      } catch (err) {}
    }
  }

  function wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }

  const validPositive = (e) => {
    console.log(
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value),
      "valllluee"
    );
    if (
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  async function submitStake() {
    setLoader(true);
    try {
      var amount = searchQuery ? searchQuery : "";
      if (amount == "") {
        setLoader(false);
        toast.error("Enter amount", toasterOption);
        return false;
      }
      if (parseFloat(userBal) < parseFloat(amount)) {
        setLoader(false);
        toast.error("User Balance is low", toasterOption);
        return false;
      }
      if (parseFloat(amount) < parseInt(poolDetails[2]) / 1e18) {
        setLoader(false);
        toast.error(
          "Amount should be greater than the minimum mine value",
          toasterOption
        );
        return false;
      }
      if (parseFloat(amount) > parseInt(poolDetails[3]) / 1e18) {
        setLoader(false);
        toast.error(
          "Amount should be lesser than the maximum mine value",
          toasterOption
        );
        return false;
      }

      let pid = key;
      let wallet = address;
      let data = {
        amount,
        pid,
        wallet,
      };

      // var approveStatus = true;
      var resp = { approveStatus: true };
      if (userAllowance < amount) {
        let dataNew = {
          amount,
          pid,
          wallet,
        };
        var resp = await ApproveToken(dataNew);
        await wait(3000);
      }

      if (resp.approveStatus) {
        var resp = await StakeForm(data);
        if (resp.stakestatus) {
          setLoader(false);
          toast.success("Successfully Mined", toasterOption);
          getpoolinfo();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setLoader(false);
          if (resp && resp.err && resp.err == "insuffcient balance") {
            toast.error("Insuffcient gas fees", toasterOption);
          } else {
            setLoader(false);
            toast.error("Transaction Rejected Resubmit Again", toasterOption);
          }
        }
      } else {
        if (resp && resp.err && resp.err == "insuffcient balance") {
          setLoader(false);
          toast.error("Insuffcient gas fees", toasterOption);
        } else {
          setLoader(false);
          toast.error("Transaction Rejected Resubmit Again", toasterOption);
        }
      }
    } catch (err) {
      toast.error("Transaction Rejected Resubmit Again", toasterOption);
      setLoader(false);
    }
  }

  const findTime = (num) => {
    if (num < 1) {
      return "0";
    }
    const qualifier = (num) => (num > 1 ? "s" : "");
    const numToStr = (num, unit) =>
      num > 0
        ? ` ${num}
   ${unit}${qualifier(num)}`
        : "";
    const oneMinute = 60;
    const oneHour = oneMinute * 60;
    const oneDay = oneHour * 24;
    const oneYear = oneDay * 365;
    const times = {
      year: ~~(num / oneYear),
      day: ~~((num % oneYear) / oneDay),
      hour: ~~((num % oneDay) / oneHour),
      minute: ~~((num % oneHour) / oneMinute),
      second: num % oneMinute,
    };
    let str = "";
    for (let [key, value] of Object.entries(times)) {
      str += numToStr(times[key], key);
    }
    const arr = str.trim().split(" ");
    const res = [];
    arr.forEach((x, i) => {
      if (i % 2 === 0 && i !== 0) res.push(i === arr.length - 2 ? "" : ",");
      res.push(x);
    });
    return res.join(" ").replace(/\s,/g, ",");
  };

  async function waithdrawreward(type) {
    setLoader(true);
    var dateNew = moment
      .unix(parseInt(userDetails?.[8]))
      .add(stakelimit?.[0]?.seconds, "minutes");
    if (moment().diff(dateNew) < 0 && type == "harvest") {
      var diff = await findTime(stakelimit?.[0]?.seconds);
      toast.error("Not enabled please wait", toasterOption);
      setLoader(false);

      return false;
    }

    var data = {
      pid: key,
      type: type,
    };
    var resp = await waithdrawReward(data);

    if (resp.status) {
      if (durationList && durationList[0] != undefined) {
        setUserData(durationList[0]);
      }
      toast.success("Successfully Withdraw", toasterOption);

      getpoolinfo();
      setLoader(false);
    } else {
      if (durationList[0] != undefined) {
        setUserData(durationList[0]);
      }
      if (resp && resp.err && resp.err == "insuffcient balance") {
        toast.error("Insuffcient gas fees", toasterOption);
        setLoader(false);
      } else {
        if (durationList && durationList[0] != undefined) {
          setUserData(durationList[0]);
        }
        toast.error("Transaction Rejected Resubmit Again ", toasterOption);
        setLoader(false);
      }
    }
  }

  console.log(poolAry, "poolAry");
  console.log(userArray, "userArray");
  console.log(durationList, "durationList");

  return (
    <div>
      {" "}
      {poolAry == undefined ||
        (poolAry.length == 0 && (
          <ReactLoading type={"spin"} color={"#000000"} className="loading" />
        ))}
      {isLoader == true && (
        <ReactLoading type={"spin"} color={"#000000"} className="loading" />
      )}
      <div>
        <ScrollToTopOnMount />
        <Navbar />
        <div className="refferal_ban">
          <div className="container">
            <img
              src={require("../assets/images/earn.png")}
              alt="Queenbots"
              className="img-fluid earn_img"
            />
            <h2 class="main_title">MINING</h2>
            {/* <h5 class="sub_title mb-3">
              Cras eleifend vehicula lectus id porta.
            </h5> */}
            {/* <p className="inner_para">
              Vestibulum ligula nulla, fringilla nec risus vitae, dapibus
              aliquet magna. Duis sagittis eros nec risus suscipit, non eleifend
              purus ullamcorper. Quisque sollicitudin lectus auctor, porta orci
              rutrum, vehicula ipsum. Praesent aliquam neque mi, nec dapibus
              tellus porta sed. Aenean interdum viverra ornare. Donec pulvinar
              sem nec metus posuere rhoncus. Pellentesque sollicitudin feugiat
              semper.{" "}
            </p> */}
            <div className="mb-2">
              <ul className="d-flex">
                <li>
                  <Link className="primary_btn" to="/earn">
                    Staking
                  </Link>
                </li>
                <li className="active">
                  {" "}
                  <Link className="primary_btn" href="/mining">
                    Mining
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
          </div>
        </div>

        <div className="earn">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-flex">
                <div className="box minebox">
                  <div className="inbox">
                    <div className="flx">
                      <img
                        src={require("../assets/images/stake.png")}
                        alt="Queenbots"
                        className="img-fluid me-3"
                      />
                      <div>
                        <h3>MINING</h3>
                        <p>Start staking, earn more</p>
                      </div>
                    </div>
                    <div>
                      <div className="form_flx">
                        <div className="w-100">
                          <div className="flx">
                            {console.log(userBal, "userBal")}
                            <span>Available Balance</span>
                            <p>{userBal.toFixed(4)} QBT</p>
                          </div>
                          <input
                            class="form-control primary_inp"
                            type="text"
                            placeholder="Enter No.of QBT"
                            onInput={validPositive}
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                        </div>
                        {console.log(
                          JoinNowstatus,
                          address,
                          "JoinNowstatus,address"
                        )}
                        <button
                          className="primary_btn stakenow"
                          onClick={() => {
                            if (JoinNowstatus != false && address != "") {
                              submitStake();
                            } else {
                              toast.error("Please join now", toasterOption);
                            }
                          }}
                        >
                          Mine
                        </button>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <p>Mine Amount</p>
                        <span>
                          {userDetails && userDetails[2] && userDetails[2]
                            ? parseInt(userDetails[2]) / 1e18
                            : 0}
                        </span>
                      </li>
                      <li>
                        <p>APY</p>
                        <span>
                          {poolDetails && poolDetails[0] && poolDetails[0]
                            ? (parseInt(poolDetails[0]) /
                                (parseInt(poolDetails[1]) / 30)) *
                              12
                            : 0}{" "}
                          %
                        </span>
                      </li>
                      <li>
                        <p>Minimum Mine Amount</p>
                        <span>
                          {poolDetails && poolDetails[2] && poolDetails[2]
                            ? parseInt(poolDetails[2]) / 1e18
                            : 0}
                        </span>
                      </li>
                      <li>
                        <p>Maximum Mine Amount</p>
                        <span>
                          {poolDetails && poolDetails[3] && poolDetails[2]
                            ? parseInt(poolDetails[3]) / 1e18
                            : 0}
                        </span>
                      </li>
                      {userDetails &&
                      userDetails[2] &&
                      userDetails[2] &&
                      parseInt(userDetails[2]) / 1e18 > 0 ? (
                        <>
                          <li>
                            <p>Amount Reward</p>
                            <span>
                              {reward ? parseFloat(reward).toFixed(6) : 0}{" "}
                              {"QBT"}
                            </span>
                          </li>
                          <li>
                            <p>Mine Date</p>
                            <span>
                              {userDetails && userDetails[7] && userDetails[7]
                                ? parseInt(userDetails[7]) == 0
                                  ? 0
                                  : moment
                                      .unix(userDetails[7])
                                      .format("YYYY-MM-DD hh:mm A")
                                : 0}
                            </span>
                          </li>
                          <li>
                            <p>Harvest Time</p>
                            <span>
                              {userDetails &&
                              userDetails?.[7] &&
                              userDetails?.[7]
                                ? parseInt(userDetails?.[7]) == 0
                                  ? 0
                                  : moment
                                      .unix(userDetails?.[7])
                                      .add(stakelimit?.[0]?.seconds, "seconds")
                                      .format("YYYY-MM-DD hh:mm A")
                                : 0}
                            </span>
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                    </ul>
                    <div className="flx_btn">
                      {reward > 0 && (
                        <button
                          className="primary_btn stakenow"
                          onClick={() => {
                            waithdrawreward("withdraw");
                          }}
                        >
                          Stop Mining
                        </button>
                      )}
                      {reward > 0 && (
                        <button
                          className="primary_btn harvest"
                          onClick={() => {
                            waithdrawreward("harvest");
                          }}
                        >
                          Harvest
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Newsletter /> */}
        {/* <section className="subscribe_section mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <div className="subscribe_div">
                  <img
                    src={require("../assets/images/subscribe_img.png")}
                    alt="Queenbots"
                    className="img-fluid"
                  />
                  <h2 className="main_title text-center">
                    Subscribe Newsletter
                  </h2>
                  <p className="inner_para text-center">
                    Quisque sollicitudin lectus auctor, porta orci rutrum,
                    vehicula ipsum. Praesent aliquam neque mi, nec dapibus
                    tellus porta sed.{" "}
                  </p>
                  <input
                    className="form-control primary_inp"
                    placeholder="Enter Your Email"
                  />
                  <div className="text-center">
                    <button className="primary_btn">Subscribe</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <Footer />
      </div>
    </div>
  );
}

import Web3 from "web3";
import { toast } from "react-toastify";
import config from "../../lib/config/config";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { toastAlert } from "../../helper/toastAlert";
// toast.configure();
let toasterOption = config.toasterOption;

export async function getprovider() {
  if (
    sessionStorage.getItem("walltype") &&
    sessionStorage.getItem("walltype") != null &&
    sessionStorage.getItem("walltype") != undefined &&
    sessionStorage.getItem("walltype") != "" &&
    sessionStorage.getItem("walltype") == "trust"
  ) {
    const provider = new WalletConnectProvider({
      rpc: {
        97: "https://data-seed-prebsc-1-s2.binance.org:8545/",
      },
      chainId: 97,
    });

    provider.on("connect", () => { });

    await provider.enable();
    let web3 = new Web3(provider);
    var network = await web3.eth.net.getId();
    var result = await web3.eth.getAccounts();
    if (config.networkVersion == network) {
      var data = {
        provider: provider,
        address: web3.utils.toChecksumAddress(result[0]),
      };
      return data;
    } else {
      // await provider.disconnect();
      let result = await web3.eth.getAccounts();
      let data = {
        provider: window.web3.currentProvider,
        address: web3.utils.toChecksumAddress(result[0]),
      };
      return data;
    }
  } else {
    if (window.ethereum) {
      console.log("ethereum");
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          // await window.ethereum.enable();
          const web3 = new Web3(window.web3.currentProvider);

          if (window.web3) {
            const isLocked = !(await window.ethereum._metamask.isUnlocked());
            if (isLocked) {
              var data = {
                address: "0x0000000000000000000000000000000000000001", //this default address for read contract
                provider: config.liverpcUrls,
              };
              return data;
            }

            if (
              window.web3.currentProvider.networkVersion ==
              config.networkVersion
            ) {
              console.log("account");
              var result = await web3.eth.getAccounts();
              var data = {
                provider: window.web3.currentProvider,
                address: web3.utils.toChecksumAddress(result[0]),
              };
              console.log(data, "datadatadata");
              return data;
            } else {
              var result = await web3.eth.getAccounts();
              let data = {
                provider: window.web3.currentProvider,
                address: web3.utils.toChecksumAddress(result[0]),
              };
              toastAlert("warn", "Please connect binance mainnet", "wallet");

              return data;
            }
          }
        }
      } catch (err) {
        console.log(err, "error");
      }
    }
    // else {
    //   let web3 = new Web3(config.liverpcUrls);
    //   var network = await web3.eth.net.getId();
    //   var result = await web3.eth.getAccounts();
    //   var data = {
    //     address: "0x0000000000000000000000000000000000000001", //this default address for read contract
    //     provider: config.liverpcUrls,
    //   };
    //   return data;
    // }
  }
}

// try {
//   window.addEventListener("load", (event) => {
//     event.preventDefault();
//     if (sessionStorage.getItem("tfnukukpannft") == "yes") {
//       if (window.ethereum) {
//         window.ethereum.on("accountsChanged", async function (accounts) {
//           await window.ethereum.request({ method: "eth_requestAccounts" });
//           window.location.reload(true);
//           sessionStorage.setItem("account", accounts[0]);
//         });

//         window.ethereum.on("networkChanged", async function (networkId) {
//           if (networkId == config.networkVersion) {
//             window.location.reload(true);
//             // getprovider();
//           } else {
//             toast.warn("Please connect binance Mainnet", toasterOption);
//             try {
//               let web3 = new Web3(window.web3.currentProvider);
//               await web3.currentProvider.request({
//                 method: "wallet_switchEthereumChain",
//                 params: [{ chainId: "0x97" }],
//               });
//               let address = await web3.eth.getAccounts();
//               console.log(address[0], "address");
//               sessionStorage.setItem("account", address[0]);
//               toast.success("Wallet connected 🦊 ", toasterOption);
//               window.location.reload(false);
//             } catch (switchError) {
//               console.log("some error on switching", switchError);
//             }
//           }
//         });
//       }
//     }
//   });
// } catch (e) {
//   console.log("error on wallet watch function");
// }

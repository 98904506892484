import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import moment from "moment";
import Newsletter from "../components/Newsletter.js";
import { connection } from "../helper/connection";
import { useNavigate } from "react-router-dom";
//Actions
import {
  getGrapfDatas,
  getReferralHistory,
  getNFCBalanceApi,
  cardregisterapi,
  postactivecard,
  GetMyWithDrawals,
} from "../Actions/user";

import ReactLoading from "react-loading";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import { toast } from "react-toastify";
import config from "../lib/config/config";
import { getprovider } from ".././pages/seperate/provider";
import Web3 from "web3";
import BUSDContractABI from "../ABI/BusdABI.json";
import ContractABI from "../ABI/DexbotABI.json";
import {
  getUserData,
  withdrawReferalReward,
  withdrawLevelReward,
} from "./dashboadActions";
import { getPoolInfo } from "./stakeActions";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../helper/isEmpty.js";

let toasterOption = config.toasterOption;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Dashboard(props) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const [labels, setDate] = useState([]);
  const [Value, setValue] = useState([]);
  const [loader, setLoader] = useState(false);
  const [TokenType, setTokenType] = useState("BNB");
  const [provider, setprovider] = useState();
  const [address, setaddress] = useState("");
  const [BNBBaln, setBNBBaln] = useState(0);
  const [BUSDBaln, setBUSDBaln] = useState(0);
  const [QBTTokenValue, setQBTTokenValue] = useState(0);
  const [busdValue, setbusdValue] = useState(0);
  const [BNB, setBNB] = useState(0);
  const [BUSD, setBUSD] = useState(0);
  const [busdIncome, setBUSDIncome] = useState(0);
  const [referralIncome, setReferralIncome] = useState(0);
  const [levelIncome, setLevelIncome] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [totalStake, setTotalStake] = useState();
  const [totalMine, settotalMine] = useState();
  const walletConnection = useSelector((state) => state.walletConnection);
  const [JoinNowstatus, setJoinNowstatus] = useState(false);
  const [tokenRate, settokenRate] = useState(0);
  const [cardno, setcardno] = useState("");
  const [ShowOTP, setShowOTP] = useState(false);
  const [OtpData, setOtp] = useState("");
  const [balanceShow, setBalanceShow] = useState(false);
  const [withDrawal, setWithDrawal] = useState();
  const [totalReward, setTotalReward] = useState();

  //for nfc
  const [nfcUserDetails, setNfcUserDetails] = useState({
    kycStatus: false,
    verifyOtp: false,
    cardType: "",
    cardNumber: "",
    pinNumber: "",
  });

  const [nfcCardBalances, setNfcCardBalances] = useState({
    nfcBusdBal: "",
    nfcQbtBal: "",
  });

  const navigate = useNavigate();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      // title: {
      //   display: true,
      //   text: "Chart.js Line Chart",
      // },
    },
  };

  async function GrapfDatas() {
    const { status, message, error, result } = await getGrapfDatas();

    console.log(result, "resultresult");

    const last3 = result.slice(-10);
    console.log(last3, "last3");

    //getmonth
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var mm = [];

    if (last3 && last3.length > 0) {
      await last3.map((item) => {
        let name = month[parseInt(item._id.month) - 1] + " " + item._id.day;

        console.log(name, "name");
        mm.push(name);
      });
    }

    setDate(mm);

    //getValue
    var vv = [];
    if (result && result.length > 0) {
      await result.map((item) => {
        var name = item.marketPrice;
        vv.push({ ...{ name } });
      });
    }
    setValue(vv);

    console.log(vv, "mmmmmmm");
  }

  const data = {
    labels,
    datasets: [
      {
        label: "Price",
        data: Value.length > 0 && Value.map((item) => item.name),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  useEffect(() => {
    GrapfDatas();
    getTokenBal();
    getQbtValue();
    getUserDataFunc();
    CheckIsUser();
    if (walletConnection.address != "") {
      referralPerFunc();
      WithDrawalsData();
    }
  }, [walletConnection]);

  useEffect(() => {
    getQbtValue();
  }, [busdValue]);

  const WithDrawalsData = async () => {
    try {
      let params = {
        address: walletConnection.address,
      };
      const { status, data } = await GetMyWithDrawals(params);
      console.log(status, data, "status, datagewbjyufhj");
      if (status == "success") {
        setWithDrawal(data);
      }
    } catch (err) {
      console.log(err, "err------->>>>....");
    }
  };

  async function CheckIsUser() {
    let address =
      walletConnection && walletConnection.address && walletConnection.network
        ? walletConnection.address
        : "";
    let provider =
      walletConnection && walletConnection.address && walletConnection.provider
        ? walletConnection.provider
        : undefined;

    console.log(address, provider, "jdhfgeradw", walletConnection);

    try {
      if (typeof provider !== "undefined") {
        var web3 = new Web3(provider);
        var DexbotContract = await new web3.eth.Contract(
          ContractABI,
          config.ContractAddress
        );
        var isRefferal = await DexbotContract.methods.userData(address).call();
        console.log(isRefferal.isUser, "isRefferal");
        setJoinNowstatus(isRefferal.isUser);
      }
    } catch (err) {}
  }
  const getUserDataFunc = async () => {
    let data = await getUserData();
    console.log(data, "datadata");
    setBUSDIncome(parseInt(data?.resp?.[10]) / 1e18);
    setReferralIncome(parseInt(data?.resp?.[4]) / 1e18);
    setLevelIncome(parseInt(data?.resp?.[9]) / 1e18);
    setSelectedPlan(parseInt(data?.resp?.[3]));
    var info = await getPoolInfo();
    var stakedTotal = 0;
    var minedTotal = 0;
    var stkedArray = info.poolArray;
    info?.userArray?.map((item, index) => {
      if (stkedArray[index][4] == true && stkedArray[index][5] == false) {
        stakedTotal = stakedTotal + parseInt(item[2]) / 1e18;
      } else if (stkedArray[index][4] == true && stkedArray[index][5] == true) {
        minedTotal = minedTotal + parseInt(item[2]) / 1e18;
      }
    });
    var rewardTotal = 0;

    info?.rewardArr?.map((itemNew) => {
      rewardTotal = rewardTotal + itemNew;
    });
    setTotalReward(rewardTotal.toFixed(6));
    setTotalStake(stakedTotal);
    settotalMine(minedTotal);
  };

  const validPositive = (e) => {
    console.log(
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value),
      "valllluee"
    );
    if (
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  const getTokenBal = async () => {
    var { provider, address } = await connection();
    console.log(provider, address, "vedhaaaa");

    if (provider) {
      setprovider(provider);
      setaddress(address);
      var web3 = new Web3(provider);
      try {
        if (typeof provider !== "undefined") {
          //BNB baln
          var balance = await web3.eth.getBalance(address);
          var currbal = balance / 1e18;
          setBNBBaln(currbal.toFixed(5));
          //BUSD baln
          var BUSDContract = await new web3.eth.Contract(
            BUSDContractABI,
            config.BUSDContractAddress
          );
          var BUSDtokenBln = await BUSDContract.methods
            .balanceOf(address)
            .call();
          setBUSDBaln(BUSDtokenBln / 1e18);
        }
      } catch (err) {
        console.log(err, "errrrr");
      }
    }
  };

  const getQbtValue = async () => {
    var web3 = new Web3(config.rpcUrl);

    var DexbotContract = new web3.eth.Contract(
      ContractABI,
      config.ContractAddress
    );
    var amount = 1 * 10 ** 18;

    var tokenBal = await DexbotContract.methods
      .getTokenfromBnb(amount.toString())
      .call();
    // tokenBal = tokenBal * 1000000;
    setBNB(tokenBal / 1e18);

    var tokenBal1 = await DexbotContract.methods
      .getTokenfromBusd(amount.toString())
      .call();
    setBUSD(tokenBal1 / 1e18);
    var amt = tokenBal1 / 1e18;
    settokenRate(1 / amt);
    console.log(tokenBal, "tokenBal");
  };

  const handlechange1 = async (e) => {
    if (e.target.value == "BUSD") {
      setTokenType("BUSD");
      setQBTTokenValue(0);

      if (provider) {
        var web3 = new Web3(provider);

        var DexbotContract = await new web3.eth.Contract(
          ContractABI,
          config.ContractAddress
        );
        var amount = 1 * 10 ** 18;

        if (busdValue > 0) {
          var tokenBal1 = await DexbotContract.methods
            .getTokenfromBusd(amount.toString())
            .call();
          setBUSD(tokenBal1 / 1e18);

          setQBTTokenValue((tokenBal1 / 1e18) * busdValue);
        }
      }
    } else if (e.target.value == "BNB") {
      setTokenType("BNB");
      setQBTTokenValue(0);

      if (provider) {
        var web3 = new Web3(provider);

        var DexbotContract = await new web3.eth.Contract(
          ContractABI,
          config.ContractAddress
        );
        var amount = 1 * 10 ** 18;

        if (busdValue > 0) {
          var tokenBal = await DexbotContract.methods
            .getTokenfromBnb(amount.toString())
            .call();
          setQBTTokenValue((tokenBal / 1e18) * busdValue * 1e6);
        }
      }
    }
  };

  const handlechange = async (e) => {
    try {
      if (typeof provider !== "undefined") {
        if (e.target.name == "BUSDValue") {
          if (e.target.value == "") {
            // setbusdValue("");
            setQBTTokenValue(0);
            return false;
          }
        }

        if (provider) {
          var web3 = new Web3(provider);

          var DexbotContract = await new web3.eth.Contract(
            ContractABI,
            config.ContractAddress
          );
          var amount = 1 * 10 ** 18;

          if (e.target.name == "BUSDValue" && e.target.value > 0) {
            setbusdValue(e.target.value);
            var busdValue = parseFloat(e.target.value);

            if (TokenType == "BNB") {
              var tokenBal = await DexbotContract.methods
                .getTokenfromBnb(amount.toString())
                .call();
              setQBTTokenValue((tokenBal / 1e18) * busdValue * 1e6);
            } else if (TokenType == "BUSD") {
              console.log(BUSD * busdValue, "kkshdugdug");
              var tokenBal1 = await DexbotContract.methods
                .getTokenfromBusd(amount.toString())
                .call();
              setBUSD(tokenBal1 / 1e18);

              setQBTTokenValue((tokenBal1 / 1e18) * busdValue);
            }
          } else {
            setQBTTokenValue(0);
          }
        }
      }
    } catch (err) {
      console.log("errror:", err);
    }
  };

  async function convert(n) {
    try {
      var sign = +n < 0 ? "-" : "",
        toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead, decimal, pow] = n
        .toString()
        .replace(/^-/, "")
        .replace(/^([0-9]+)(e.*)/, "$1.$2")
        .split(/e|\./);
      return +pow < 0
        ? sign +
            "0." +
            "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
            lead +
            decimal
        : sign +
            lead +
            (+pow >= decimal.length
              ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
              : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
    } catch (err) {
      return 0;
    }
  }

  const handleBuyNow = async () => {
    if (provider != undefined && address != "") {
      var web3 = new Web3(provider);
      var BUSDContract = await new web3.eth.Contract(
        BUSDContractABI,
        config.BUSDContractAddress
      );

      var DexbotContract = await new web3.eth.Contract(
        ContractABI,
        config.ContractAddress
      );

      console.log(busdValue, "busdValue");

      if (busdValue == "") {
        toast.error("Enter deposit amount", toasterOption);
        return false;
      }
      if (busdValue <= 0 && busdValue != "") {
        setQBTTokenValue(0);
        toast.error("Enter greater than 0", toasterOption);
        return false;
      }

      try {
        setLoader(true);
        var amt = await convert(100000 * 1e18);
        console.log(amt, "amt");

        var datacalcul = busdValue * 1e18;
        var amt1 = datacalcul.toString();
        amt1 = await convert(amt1);
        console.log(amt1, "amt1");

        if (TokenType == "BUSD") {
          if (busdValue > BUSDBaln) {
            toast.error("Insufficient balance", toasterOption);
            setLoader(false);
            return false;
          }
          await BUSDContract.methods
            .approve(config.ContractAddress, amt)
            .send({ from: address })
            .then(async (result) => {
              console.log(result, "result");
              if (result && result != "") {
                toast.success("Approved Successfully", toasterOption);
                setLoader(true);
                console.log(amt1 / 1e18, "amt1");
                await DexbotContract.methods
                  .depositBUSD(amt1)
                  .send({ from: address })
                  .then(async (result) => {
                    console.log(result, "result");
                    if (result && result != "") {
                      toast.success(
                        "Token Purchased Successfully",
                        toasterOption
                      );
                      setLoader(false);
                      setTimeout(() => {
                        window.location.reload();
                      }, 2000);
                    }
                  })
                  .catch((error) => {
                    console.log(error, "error");
                    toast.error("Token Purchased failed", toasterOption);
                    setLoader(false);
                    setTimeout(() => {
                      window.location.reload();
                    }, 2000);
                  });
              }
            })
            .catch((error) => {
              console.log(error, "error");
              toast.error("Approve failed", toasterOption);
              setLoader(false);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            });
        } else {
          if (busdValue > BNBBaln) {
            toast.error("Insufficient balance", toasterOption);
            setLoader(false);
            return false;
          }
          await DexbotContract.methods
            .depositBNB()
            .send({ value: amt1, from: address })
            .then(async (result) => {
              console.log(result, "result");
              if (result && result != "") {
                toast.success("Token Purchase Successfully", toasterOption);
                setLoader(false);
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              }
            })
            .catch((error) => {
              console.log(error, "error");
              toast.error("Token Purchase failed", toasterOption);
              setLoader(false);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            });
        }
      } catch (err) {
        console.log("errror:", err);
        setLoader(false);
      }
    }
  };

  const getReferralRewards = async () => {
    setLoader(true);
    let resp = await withdrawReferalReward();
    setLoader(false);
    if (resp.status) {
      setTimeout(() => {
        getUserDataFunc();
      }, 5000);
      toast.success("Successfully Withdraw", toasterOption);
    } else {
      setTimeout(() => {
        getUserDataFunc();
      }, 5000);
      if (resp.err && resp.err && resp.err == "insuffcient balance") {
        toast.error("Insuffcient gas fees", toasterOption);
      } else {
        setTimeout(() => {
          getUserDataFunc();
        }, 5000);
        toast.error("Transaction Rejected Resubmit Again", toasterOption);
      }
    }
  };

  const getLevelRewards = async () => {
    setLoader(true);
    let resp = await withdrawLevelReward();
    console.log(resp, "respresp");
    setLoader(false);
    if (resp.status) {
      toast.success("Successfully Withdraw", toasterOption);

      setTimeout(() => {
        getUserDataFunc();
      }, 5000);
    } else {
      if (resp && resp.err && resp.err == "insuffcient balance") {
        toast.error("Insuffcient gas fees", toasterOption);

        setTimeout(() => {
          getUserDataFunc();
        }, 5000);
      } else {
        toast.error("Transaction Rejected Resubmit Again", toasterOption);

        setTimeout(() => {
          getUserDataFunc();
        }, 5000);
      }
    }
  };
  ///KYC**************************
  const referralPerFunc = async () => {
    setLoader(true);
    try {
      let params = {
        address: walletConnection.address,
      };
      const { respData } = await getReferralHistory(params);
      console.log(respData, "respDatajghdugf");
      setLoader(false);
      if (respData?.data?.user[0].length != 0) {
        setNfcUserDetails({
          kycStatus: respData?.data?.user[0]?.kycStatus,
          verifyOtp: respData?.data?.user[0]?.otpVerifyStatus,
          cardType: respData?.data?.user[0]?.cardtype,
          cardNumber: respData?.data?.user[0]?.cardNumber,
          pinNumber: respData?.data?.user[0]?.pinNumber,
        });
      }
    } catch (err) {
      setLoader(false);
      console.log(err, "referralPerFunc err");
    }
  };

  useEffect(() => {
    if (!isEmpty(nfcUserDetails?.cardNumber)) {
      getNfcCardBalance();
    }
  }, [nfcUserDetails]);

  const getNfcCardBalance = async () => {
    setLoader(true);
    try {
      let params = {
        cardNumber: nfcUserDetails?.cardNumber,
      };
      const { status, result } = await getNFCBalanceApi(params);
      console.log(status, result, "respDsdgratajghdugf");

      setLoader(false);
      if (status) {
        setNfcCardBalances({
          nfcBusdBal: result?.busd,
          nfcQbtBal: result?.qbt,
        });
      }
    } catch (err) {
      setLoader(false);
      console.log(err, "Home in getNFCBalanceApi");
    }
  };

  const oncontinue = async () => {
    if (cardno != "") {
      const params = {
        address: walletConnection.address,
        CardNumber: cardno,
        plan: selectedPlan,
      };
      const { status, message } = await cardregisterapi(params);
      if (status == "success") {
        toast.success("You have receive OTP", toasterOption);
        setShowOTP(true);
      } else {
        toast.error(message, toasterOption);
      }
    } else {
      toast.error("Enter your card no !", toasterOption);
    }
  };

  const Activate = async () => {
    const reqdata = {
      address: walletConnection.address,
      otp: OtpData,
      CardNumber: cardno,
      plan: selectedPlan,
    };
    setLoader(true);
    const { status, msg } = await postactivecard(reqdata);
    setLoader(false);
    console.log(status, "resultresult");
    if (status == "success") {
      setcardno("");
      toast.success(`NFC card activated Successfully`, toasterOption);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      setOtp("");
    } else {
      toast.error(`Invalid OTP !`, toasterOption);
      setOtp("");
    }
  };

  return (
    <div>
      {" "}
      {loader == true && (
        <ReactLoading type={"spin"} color={"#000000"} className="loading" />
      )}
      <div>
        <ScrollToTopOnMount />
        <Navbar />
        <div className="refferal_ban">
          <div className="container">
            <h2 class="main_title">DASHBOARD</h2>

            <div className="slide_ban">
              <Slider {...settings}>
                <div>
                  <div className="outbox">
                    <div className="box">
                      <div className="row">
                        <div className="col-lg-7">
                          <h2>QUEENBOTS</h2>
                          <h5>CRYPTOCURRENCY</h5>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse ultrices
                            gravida. Risus commodo viverra maecenas accumsan
                            lacus vel facilisis.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="outbox">
                    <div className="box">
                      <div className="row">
                        <div className="col-lg-7">
                          <h2>QUEENBOTS</h2>
                          <h5>CRYPTOCURRENCY</h5>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse ultrices
                            gravida. Risus commodo viverra maecenas accumsan
                            lacus vel facilisis.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="outbox">
                    <div className="box">
                      <div className="row">
                        <div className="col-lg-7">
                          <h2>QUEENBOTS</h2>
                          <h5>CRYPTOCURRENCY</h5>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse ultrices
                            gravida. Risus commodo viverra maecenas accumsan
                            lacus vel facilisis.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          <div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
          </div>
        </div>

        <div className="refferal dashboard">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-flex">
                <div className="box aff_box w-100">
                  <p className="mb-4">QBT Token Price List</p>

                  <Line options={options} data={data} />

                  {/* <img src={require("../assets/images/chart.png")} className="img-fluid w-100" alt="img"/> */}

                  <div className="fle_x">
                    <div>
                      <h5 className="h5tag">
                        Price: <span> $ {tokenRate.toFixed(4)}</span>
                      </h5>
                    </div>
                    <div>
                      {JoinNowstatus != false && address != "" ? (
                        <>
                          {" "}
                          <button
                            class="primary_btn"
                            data-bs-toggle="modal"
                            data-bs-target="#BuyQbt_modal"
                          >
                            Buy QBT
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            class="primary_btn"
                            data-bs-toggle="modal"
                            data-bs-target="#selectplan_modal"
                          >
                            Buy QBT
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex">
                <div className="car_d">
                  <div className="h_flx">
                    <p>NFC Card</p>
                    <div>
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => setBalanceShow(!balanceShow)}
                      >
                        {walletConnection.address.slice(0, 10)}..
                        {walletConnection.address.slice(36, 42)}
                      </button>
                      <ul
                        class={`${balanceShow ? "show" : ""} dropdown-menu `}
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a class="dropdown-item">
                            My QBT Balance :{" "}
                            {levelIncome > 0
                              ? levelIncome.toFixed(6)
                              : levelIncome}{" "}
                            QBT
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item">
                            My Staking :{" "}
                            {totalStake > 0
                              ? parseFloat(totalStake).toFixed(4)
                              : 0}{" "}
                            QBT
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item">
                            My Rewards : {totalReward > 0 ? totalReward : 0} QBT
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item">
                            My withdrawals :{" "}
                            {withDrawal &&
                            withDrawal[0]?.totalAmount != undefined
                              ? (withDrawal[0]?.totalAmount).toFixed(5)
                              : 0}{" "}
                            {""}
                            QBT
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card_box">
                    <div>
                      <img
                        src={require("../assets/images/nfc.png")}
                        className="img-fluid mb-3"
                        alt="img"
                      />
                      {console.log(nfcUserDetails, "nfcUserDetails")}
                      {nfcUserDetails?.verifyOtp ? (
                        <>
                          <p>
                            {" "}
                            Card No:{" "}
                            {nfcUserDetails?.cardNumber
                              ? nfcUserDetails?.cardNumber?.length > 20
                                ? nfcUserDetails?.cardNumber?.slice(0, 10) +
                                  ".."
                                : nfcUserDetails?.cardNumber
                              : "0"}
                          </p>
                          <p>
                          USDT Balance:{" "}
                            {nfcCardBalances?.nfcBusdBal > 0
                              ? parseFloat(nfcCardBalances?.nfcBusdBal).toFixed(
                                  4
                                )
                              : 0}
                          </p>
                          <p>
                            QBT Balance:{" "}
                            {nfcCardBalances?.nfcQbtBal > 0
                              ? parseFloat(nfcCardBalances?.nfcQbtBal).toFixed(
                                  4
                                )
                              : 0}
                          </p>
                          <div className="flx">
                            <div onClick={() => navigate("/deposit")}>
                              <img
                                src={require("../assets/images/plus.png")}
                                className="img-fluid"
                                alt="img"
                              />
                              <span>Deposit</span>
                            </div>
                            <div onClick={() => navigate("/withdraw")}>
                              <img
                                src={require("../assets/images/arw.png")}
                                className="img-fluid ms-3"
                                alt="img"
                              />
                              <span>Withdraw</span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {console.log(
                            nfcUserDetails,
                            "nfcUserDetails?.kycStatus"
                          )}
                          <p className="pt-3 pb-4 me-1">
                            You have a Card! Activate Now
                          </p>
                          {nfcUserDetails?.kycStatus == true ? (
                            <>
                              <button
                                class="primary_btn d-block active_btn"
                                data-bs-toggle="modal"
                                data-bs-target="#activate_nfc_card"
                              >
                                Activate Now
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                class="primary_btn d-block active_btn"
                                // data-bs-toggle="modal"
                                // data-bs-target="#activate_nfc_card"
                                onClick={() => {
                                  toast.error(
                                    "Confirm to verify your KYC",
                                    toasterOption
                                  );
                                }}
                              >
                                Activate Now
                              </button>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <img
                        src={require("../assets/images/logo_v2.png")}
                        className="img-fluid logo"
                        alt="img"
                      />
                    </div>
                  </div>
                  {/* comig soon img  */}
                  {/* <img
                    src={require("../assets/images/card_comingsoon.png")}
                    className="img-fluid mt-md-5"
                    alt="img"
                  /> */}
                </div>
              </div>
            </div>

            <div className="box_bdr">
              <div className="earn head_flx">
                <h5 className="sub_title  mb-0 ps-1">Wallet</h5>
                <div class="flx_btn">
                  {console.log(
                    referralIncome,
                    busdIncome,
                    "referralIncome,busdIncome"
                  )}
                  <button
                    class="primary_btn stakenow"
                    onClick={() => {
                      if (referralIncome + busdIncome > 0) {
                        getReferralRewards();
                      } else {
                        toast.error("Referral Income is low", toasterOption);
                      }
                    }}
                  >
                    Withdraw USDT
                  </button>
                  <button
                    class="primary_btn harvest"
                    onClick={() => {
                      if (levelIncome > 0) {
                        getLevelRewards();
                      } else {
                        toast.error("Referral Income is low", toasterOption);
                      }
                    }}
                  >
                    Withdraw QBT
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <div className="left_box">
                    <div className="box">
                      <div>
                        <p> Total</p>
                        <h6>QBT Balance</h6>
                        <h5 className="sub_title">
                          {levelIncome > 0
                            ? levelIncome.toFixed(4)
                            : levelIncome}{" "}
                          QBT
                        </h5>
                      </div>
                    </div>
                    <div className="box">
                      <div>
                        <p>Total</p>
                        <h6>Referral Balance</h6>
                        <h5 className="sub_title">
                          {referralIncome + busdIncome} USDT
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="c_box">
                    <p>Your Plan</p>
                    <span>{selectedPlan == 1 ? "King" : "Queen"}</span>
                  </div>
                  <div className="c_box">
                    <p>Direct Referral Income</p>
                    <span>
                      {referralIncome > 0
                        ? referralIncome.toFixed(4)
                        : referralIncome}{" "}
                      USDT
                    </span>
                  </div>
                  <div className="c_box">
                    <p>Staking Referral Income</p>
                    <span>
                      {busdIncome > 0 ? busdIncome.toFixed(4) : busdIncome} USDT
                    </span>
                  </div>
                  <div className="c_box">
                    <p>Staking and Mining Level Income</p>
                    <span>
                      {levelIncome > 0 ? levelIncome.toFixed(4) : levelIncome}{" "}
                      QBT
                    </span>
                  </div>
                  <div className="c_box">
                    <p>Total Withdrawal QBT</p>
                    <span>
                      {levelIncome > 0 ? levelIncome.toFixed(4) : levelIncome}{" "}
                      QBT
                    </span>
                  </div>
                  <div className="c_box">
                    <p>Total Withdrawal USDT</p>
                    <span>{referralIncome + busdIncome} USDT</span>
                  </div>
                  <div className="c_box">
                    <p>Total in Mining</p>
                    <span>{totalMine > 0 ? totalMine : 0} QBT</span>
                  </div>
                  <div className="c_box">
                    <p>Total in Staking</p>
                    <span>{totalStake > 0 ? totalStake : 0} QBT</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Newsletter /> */}

        {/* <section className="subscribe_section mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <div className="subscribe_div">
                  <img
                    src={require("../assets/images/subscribe_img.png")}
                    alt="Queenbots"
                    className="img-fluid"
                  />
                  <h2 className="main_title text-center">
                    Subscribe Newsletter
                  </h2>
                  <p className="inner_para text-center">
                    Quisque sollicitudin lectus auctor, porta orci rutrum,
                    vehicula ipsum. Praesent aliquam neque mi, nec dapibus
                    tellus porta sed.{" "}
                  </p>
                  <input
                    className="form-control primary_inp"
                    placeholder="Enter Your Email"
                  />
                  <div className="text-center">
                    <button className="primary_btn">Subscribe</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* Select QBTBuy Modal */}
        <div
          className="modal modal-lg fade primary_modal primary_modal_chg"
          id="BuyQbt_modal"
          tabIndex={-1}
          aria-labelledby="connect_walletModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="bgimg">
                <div className="modal-header border-0 w-100 text-center">
                  <div className="w-100">
                    <h2
                      className="modal-title main_title"
                      id="connect_walletModalLabel"
                    >
                      BUY QBT
                    </h2>
                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <img
                      src={require("../assets/images/close.png")}
                      alt="Queenbots"
                      className="img-fluid"
                    />
                  </button>
                </div>
                <div className="modal-body">
                  <div>
                    <div className="m-2 mb-3 row">
                      <div className="col-md-4">
                        <h4>Select Token Type :</h4>
                      </div>
                      <div className="col-md-4">
                        <select
                          class="form-select form-select-lg mb-3"
                          style={{
                            marginTop: "-10px",
                          }}
                          onChange={handlechange1}
                        >
                          <option value="BNB">BNB</option>
                          <option value="BUSD">USDT</option>
                        </select>
                      </div>
                      <div className="col-md-4">
                        {TokenType == "BNB" ? (
                          <>
                            <h4>Balance : {BNBBaln}</h4>
                          </>
                        ) : (
                          <>
                            <h5>Balance : {BUSDBaln.toFixed(2)}</h5>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="earn reff_box">
                  <div className="form_flx">
                    <div className="w-100" style={{ padding: "20px" }}>
                      <div className="labl">
                        <span>Deposit Amount</span>
                      </div>
                      <input
                        class="form-control primary_inp"
                        type="text"
                        placeholder="Enter Amount"
                        onChange={handlechange}
                        name="BUSDValue"
                        // value={busdValue}
                        onInput={validPositive}
                      />
                    </div>
                    <div className="w-100" style={{ padding: "20px" }}>
                      <div className="labl">
                        <span>QBT</span>
                      </div>
                      <input
                        class="form-control primary_inp"
                        type="text"
                        value={QBTTokenValue}
                      />
                    </div>
                  </div>
                </div>
                <div className="bottom">
                  <button
                    className="primary_btn mt-3 mb-4"
                    onClick={() => handleBuyNow()}
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Select Plan Modal */}
        <div
          className="modal modal-lg fade primary_modal primary_modal_chg activate_nfc_modal"
          id="activate_nfc_card"
          tabIndex={-1}
          aria-labelledby="connect_walletModalLabel"
          aria-hidden="true"
          // data-bs-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="bgimg">
                <div className="modal-header border-0 w-100 text-center">
                  <div>
                    <h2
                      className="modal-title main_title"
                      id="connect_walletModalLabel"
                    >
                      Activate NFC Card
                    </h2>
                  </div>
                  <button
                    type="button"
                    className="btn-close plan_close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <img
                      src={require("../assets/images/close.png")}
                      alt="Queenbots"
                      className="img-fluid"
                    />
                  </button>
                </div>
                <div className="modal-body pb-2">
                  <div className="earn reff_box">
                    <div className="form_flx d-block">
                      {!ShowOTP ? (
                        <div className="w-100 pb-4">
                          <div className="labl">
                            <span>Enter your card number</span>
                          </div>
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              class="form-control"
                              value={cardno}
                              onChange={(e) => setcardno(e.target.value)}
                            />
                            <span class="input-group-text" id="basic-addon2">
                              <button
                                className="primary_btn m-0"
                                onClick={() => {
                                  oncontinue();
                                }}
                              >
                                Send OTP
                              </button>
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="w-100">
                          <div className="labl">
                            <span> Enter your OTP</span>
                          </div>
                          <input
                            class="form-control primary_inp"
                            type="text"
                            onInput={validPositive}
                            value={OtpData}
                            maxLength="4"
                            onChange={(e) => setOtp(e.target.value)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {ShowOTP && (
                  <div className="bottom mb-3">
                    <button
                      className="primary_btn mt-3 mb-4"
                      onClick={() => {
                        Activate();
                      }}
                    >
                      Join Now
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

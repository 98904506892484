import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import DataTable from "react-data-table-component";
import { getRefferalDatas } from "../Actions/user";
import config from "../lib/config/config";
import { toast } from "react-toastify";
import Newsletter from "../components/Newsletter.js";
import { connection } from "../helper/connection";
import { useSelector } from "react-redux";

let toasterOption = config.toasterOption;
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Refferal(props) {
  const [Data, setData] = useState([]);
  const [address, setaddress] = useState([]);
  const [referralHistory, setReferralHistory] = useState([]);
  const walletConnection = useSelector((state) => state.walletConnection);

  useEffect(() => {
    AOS.init();
  }, []);

  const columns = [
    {
      name: "Level",
      selector: (row) => row.Level,
    },
    {
      name: "Team Size",
      selector: (row) => row.TeamSize,
    },
    {
      name: "Volume USDT",
      selector: (row) => row.VolumeBUSD,
    },
    {
      name: "%",
      selector: (row) => row.percentage,
    },
  ];

  console.log(Data[0]?.L2, "kkkk");

  const data = [
    {
      id: 1,
      Level: 1,
      TeamSize: Data[0]?.L1 == undefined ? 0 : Data[0]?.L1,
      VolumeBUSD: Data[0]?.V1 == undefined ? 0 : Data[0]?.V1,
      percentage: "4%",
    },
    {
      id: 2,
      Level: 2,
      TeamSize: Data[0]?.L2 == undefined ? 0 : Data[0]?.L2,
      VolumeBUSD: Data[0]?.V2 == undefined ? 0 : Data[0]?.V2,
      percentage: "2%",
    },
    {
      id: 3,
      Level: 3,
      TeamSize: Data[0]?.L3 == undefined ? 0 : Data[0]?.L3,
      VolumeBUSD: Data[0]?.V3 == undefined ? 0 : Data[0]?.V3,
      percentage: "2%",
    },
    {
      id: 4,
      Level: 4,
      TeamSize: Data[0]?.L4 == undefined ? 0 : Data[0]?.L4,
      VolumeBUSD: Data[0]?.V4 == undefined ? 0 : Data[0]?.V4,
      percentage: "2%",
    },
    {
      id: 5,
      Level: 5,
      TeamSize: Data[0]?.L5 == undefined ? 0 : Data[0]?.L5,
      VolumeBUSD: Data[0]?.V5 == undefined ? 0 : Data[0]?.V5,
      percentage: "1%",
    },
    {
      id: 6,
      Level: 6,
      TeamSize: Data[0]?.L6 == undefined ? 0 : Data[0]?.L6,
      VolumeBUSD: Data[0]?.V6 == undefined ? 0 : Data[0]?.V6,
      percentage: "1%",
    },
    {
      id: 7,
      Level: 7,
      TeamSize: Data[0]?.L7 == undefined ? 0 : Data[0]?.L7,
      VolumeBUSD: Data[0]?.V7 == undefined ? 0 : Data[0]?.V7,
      percentage: "1%",
    },
    {
      id: 8,
      Level: 8,
      TeamSize: Data[0]?.L8 == undefined ? 0 : Data[0]?.L8,
      VolumeBUSD: Data[0]?.V8 == undefined ? 0 : Data[0]?.V8,
      percentage: "1%",
    },
    {
      id: 9,
      Level: 9,
      TeamSize: Data[0]?.L9 == undefined ? 0 : Data[0]?.L9,
      VolumeBUSD: Data[0]?.V9 == undefined ? 0 : Data[0]?.V9,
      percentage: "1%",
    },
  ];

  useEffect(() => {
    getAddress();
  }, [walletConnection]);

  async function getAddress() {
    var { address } = await connection();
    console.log("adaetrterdress", address);
    setaddress(address);

    if (address != "" && address != null && address != undefined) {
      setaddress(address);
      RefferalDatas(address);
    }
  }
  async function CopyFunction(text) {
    navigator.clipboard.writeText(text);

    toast.success("Copied!", toasterOption);
  }

  async function RefferalDatas(add) {
    var reqData = {
      address: add,
    };
    console.log(reqData, "reqData");
    const { status, message, error, result } = await getRefferalDatas(reqData);
    if (result) {
      console.log(result, "resultresult");
      setData(result.user);
      setReferralHistory(result.Datas);
    }
  }

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="refferal_ban">
        <div className="container">
          <img
            src={require("../assets/images/refferal_img.png")}
            alt="Queenbots"
            className="img-fluid"
          />
          <h2 class="main_title">REFERRAL</h2>
          {/* <h5 class="sub_title mb-5">
            Cras eleifend vehicula lectus id porta.
          </h5> */}
        </div>
        <div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
        </div>
      </div>

      <div className="refferal">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="box aff_box">
                <p>Your Referral ID</p>
                <div class="input-group">
                  <input type="text" class="form-control" value={address} />
                  <span
                    class="input-group-text"
                    onClick={() => CopyFunction(address)}
                  >
                    <img
                      src={require("../assets/images/copy.png")}
                      className="img-fluid"
                      alt="img"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="box aff_box">
                <p>Your Referral Link</p>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    value={`${config.frontend}/${address}`}
                  />
                  <span
                    class="input-group-text"
                    onClick={() =>
                      CopyFunction(`${config.frontend}/${address}`)
                    }
                  >
                    <img
                      src={require("../assets/images/copy.png")}
                      className="img-fluid"
                      alt="img"
                    />
                    {/* <img
                      src={require("../assets/images/share.png")}
                      className="img-fluid ms-3"
                      alt="img"
                    /> */}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="left_box">
                <div className="box">
                  <div>
                    <p>Your Total</p>
                    <h6>Referral Income</h6>
                    <h5 className="sub_title">{Data[0]?.referalIncome} USDT</h5>
                  </div>
                  <img
                    src={require("../assets/images/r1.png")}
                    className="img-fluid"
                    alt="img"
                  />
                </div>
                <div className="box">
                  <div>
                    <p>Your Total</p>
                    <h6>Referral Member</h6>
                    <h5 className="sub_title">{referralHistory?.length}</h5>
                  </div>
                  <img
                    src={require("../assets/images/r2.png")}
                    className="img-fluid"
                    alt="img"
                  />
                </div>
                <div className="box">
                  <div>
                    <p>Your Total</p>
                    <h6>Team Size</h6>
                    <h5 className="sub_title">
                      {Data[0]?.Usercount == undefined ? 0 : Data[0]?.Usercount}
                    </h5>
                  </div>
                  <img
                    src={require("../assets/images/r3.png")}
                    className="img-fluid"
                    alt="img"
                  />
                </div>
                <div className="box">
                  <div>
                    <p>Your Total</p>
                    <h6>Volume</h6>
                    <h5 className="sub_title">{`${
                      Data[0]?.UserAmount == undefined ? 0 : Data[0]?.UserAmount
                    } USDT`}</h5>
                  </div>
                  <img
                    src={require("../assets/images/r4.png")}
                    className="img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="box_bdr">
                <h5 className="sub_title  mb-0 ps-1">Referral Earnings</h5>
                <DataTable columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Newsletter /> */}
      {/* <section className="subscribe_section mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="subscribe_div">
                <img
                  src={require("../assets/images/subscribe_img.png")}
                  alt="Queenbots"
                  className="img-fluid"
                />
                <h2 className="main_title text-center">Subscribe Newsletter</h2>
                <p className="inner_para text-center">
                  Quisque sollicitudin lectus auctor, porta orci rutrum,
                  vehicula ipsum. Praesent aliquam neque mi, nec dapibus tellus
                  porta sed.{" "}
                </p>
                <input
                  className="form-control primary_inp"
                  placeholder="Enter Your Email"
                />
                <div className="text-center">
                  <button className="primary_btn">Subscribe</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </div>
  );
}

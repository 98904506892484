import Web3 from "web3";
import ContractABI from "../ABI/DexbotABI.json";
import QBTContractABI from "../ABI/QBTContarctABI.json";

import { connection } from "../helper/connection";

import config from "../lib/config/config";

async function convert(n) {
  try {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
      "0." +
      "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
      lead +
      decimal
      : sign +
      lead +
      (+pow >= decimal.length
        ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
        : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  } catch (err) {
    return 0;
  }
}

export async function getPoolInfo() {
  try {
    // const { status, message, error, result } = await getEarnDatas();
    // if (result) {
    //   console.log(result, "resultresult");
    //   setData(result);
    // }
    var { provider, address } = await connection();
    console.log(provider, "provider", address);

    var web3 = new Web3(provider);
    var DexbotContract = await new web3.eth.Contract(
      ContractABI,
      config.ContractAddress
    );
    var PoolLen = await DexbotContract.methods.getPoollength().call();
    console.log(PoolLen, "PoolLen");

    var userArray = [];
    var poolArray = [];
    var rewardArr = [];

    for (let i = 0; i < PoolLen; i++) {
      var poolinfo = await DexbotContract.methods.poolInfo(i).call();
      console.log(poolinfo, "poolinfo");

      var resp = await DexbotContract.methods
        .getrewardAmount(i, address)
        .call();

      console.log(resp, "resp");
      resp = resp && resp ? parseInt(resp) : 0;
      var balance = resp / 10 ** 18;
      balance = await convert(balance);
      console.log(balance, "balance");
      rewardArr.push(balance);
      poolArray.push(poolinfo);

      var userinfo = await DexbotContract.methods.userInfo(i, address).call();
      userArray.push(userinfo);
    }

    return {
      isstake: true,
      poolLength: PoolLen,
      userArray: userArray,
      poolArray: poolArray,
      rewardArr: rewardArr,
    };
  } catch (err) {
    return {
      isstake: false,
      poolLength: 0,
    };
  }
}

export async function getStakeReward(data) {
  try {
    var { provider, address } = await connection();
    console.log(provider, "provider", address);

    var web3 = new Web3(provider);
    var DexbotContract = await new web3.eth.Contract(
      ContractABI,
      config.ContractAddress
    );
    console.log(data.pid.toString(), address, "dataaaa");
    var resp = await DexbotContract.methods
      .getrewardAmount(data.pid, address)
      .call();
    console.log(resp, "resprespresp");
    resp = resp && resp ? parseInt(resp) : 0;
    console.log(resp, "respresp11");
    var balance = resp / 10 ** 18;
    balance = await convert(balance);
    console.log(balance, "balancebalancebalance");

    return {
      resp: balance,
    };
  } catch (err) { }
}

export async function ApproveToken(data) {
  try {
    var { provider, address } = await connection();
    console.log(provider, "provider", address);

    var web3 = new Web3(provider);
    var QBTContract = await new web3.eth.Contract(
      QBTContractABI,
      config.qbttokenAddress
    );

    var balance = await web3.eth.getBalance(address);
    var bnbbalance = balance / 1000000000000000000;

    var approveAmt = parseFloat(data.amount) * 10 ** 18;
    approveAmt = await convert(approveAmt);
    try {
      var estimate = await QBTContract.methods
        .approve(config.ContractAddress, approveAmt.toString())
        .estimateGas({ from: address });
      var gasFee = parseInt(estimate) + 10000;
      gasFee = gasFee / 10 ** 8;

      if (bnbbalance < gasFee) {
        return {
          approveStatus: false,
          err: "insuffcient balance",
        };
      }

      var resp = await QBTContract.methods
        .approve(config.ContractAddress, approveAmt.toString())
        .send({ gasLimit: 250000, from: address });

      return {
        approveStatus: true,
      };
    } catch (err) {
      return {
        approveStatus: false,
      };
    }
  } catch (err) {
    return {
      approveStatus: false,
    };
  }
}
export async function waithdrawReward(data) {
  try {
    var { provider, address } = await connection();
    console.log(provider, "muvithaa", address);

    var web3 = new Web3(provider);
    var DexbotContract = await new web3.eth.Contract(
      ContractABI,
      config.ContractAddress
    );
    console.log(DexbotContract, "DexbotContractkghfgh");
    var balance = await web3.eth.getBalance(address);
    var currbal = balance / 1000000000000000000;

    console.log(data.pid, data.type, "jxhfguhgug");
    var estimate = await DexbotContract.methods
      .withdrawStakeRewards(
        data.pid.toString(),
        data.type == "harvest" ? false : true
      )
      .estimateGas({ from: address });

    console.log(estimate, "estimate");
    var gasFee = parseInt(estimate) + 10000;
    gasFee = gasFee / 10 ** 8;
    console.log(gasFee, "gasFee");
    if (currbal < gasFee) {
      return {
        status: false,
        err: "insuffcient balance",
      };
    }
    var resp = await DexbotContract.methods
      .withdrawStakeRewards(
        data.pid.toString(),
        data.type == "harvest" ? false : true
      )
      .send({ from: address });

    return {
      status: true,
      resp: resp,
    };
  } catch (err) {
    return {
      status: false,
    };
  }
}

export async function StakeForm(data) {
  try {
    var { provider, address } = await connection();
    console.log(provider, "provider", address);

    var web3 = new Web3(provider);
    var DexbotContract = await new web3.eth.Contract(
      ContractABI,
      config.ContractAddress
    );

    var balance = await web3.eth.getBalance(address);
    var bnbbalance = balance / 1000000000000000000;

    var stakeAmt = parseFloat(data.amount) * 10 ** 18;
    stakeAmt = await convert(stakeAmt);

    try {
      var estimate = await DexbotContract.methods
        .stake(stakeAmt.toString(), data.pid.toString())
        .estimateGas({ from: address });

      var gasFee = parseInt(estimate) + 10000;
      gasFee = gasFee / 10 ** 8;

      if (bnbbalance < gasFee) {
        return {
          stakestatus: false,
          err: "insuffcient balance",
        };
      }

      var resp = await DexbotContract.methods
        .stake(stakeAmt.toString(), data.pid.toString())
        .send({ from: address });

      return {
        stakestatus: true,
      };
    } catch (err) {
      return {
        stakestatus: false,
      };
    }
  } catch (err) {
    return {
      stakestatus: false,
    };
  }
}

export async function getTokenBalance(connector) {
  try {
    var { provider, address } = await connection();
    console.log(provider, "provider", address);
    var web3 = new Web3(provider);

    if (typeof provider !== "undefined") {
      //balance
      var QBTContract = await new web3.eth.Contract(
        QBTContractABI,
        config.qbttokenAddress
      );
      var QBTtokenBln = await QBTContract.methods.balanceOf(address).call();
    }

    var balance = QBTtokenBln / 10 ** 18;

    return {
      balanceOf: balance,
      balance: balance,
    };
  } catch (err) {
    return {
      balanceOf: 0,
      balance: 0,
    };
  }
}

export async function getTokenAllowance(connector) {
  try {
    var { provider, address } = await connection();
    console.log(provider, "provider", address);
    var web3 = new Web3(provider);

    if (typeof provider !== "undefined") {
      //Token balance
      var DexbotContract = await new web3.eth.Contract(
        ContractABI,
        config.ContractAddress
      );
      var tokenBal = await DexbotContract.methods
        .allowance(address, config.ContractAddress)
        .call();
      console.log(tokenBal, "tokenBal");
    }
    var allowance = tokenBal / 10 ** 18;
    return {
      allowance: allowance,
    };
  } catch (err) {
    return {
      allowance: 0,
    };
  }
}

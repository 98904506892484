export const numReg = /^[0-9]+$/;
export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
export const Emailreg = /\S+@\S+\.\S+/;

export const addressreg = /^[a-zA-Z0-9\s,'-]*$/;
export const namereg = /^[A-Za-z\s]*$/;

export const AadharNoreg = /^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/;
export const specialreg = /^[a-zA-Z0-9\s,'-]*$/;
// /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{6,}$/g;

import React, { useState } from "react";
import { updateNewletter } from "../Actions/user";
import { toast } from "react-toastify";
import config from "../lib/config/config";

let toasterOption = config.toasterOption;

function Newsletter() {
  const [email, setemail] = useState("");

  console.log(email, "email");

  const handlesubm = async () => {
    try {
      var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

      if (email === "") {
        toast.error("Please Enter your mail address", toasterOption);
        return false;
      }
      if (!regex.test(email)) {
        toast.error("Please Enter a valid mail address", toasterOption);
        return false;
      }

      const submitdata = await updateNewletter({ email: email });

      console.log(submitdata, "surya");
      toast.success("Thank You for your interest", toasterOption);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <section className="subscribe_section mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="subscribe_div">
                <img
                  src={require("../assets/images/subscribe_img.png")}
                  alt="Queenbots"
                  className="img-fluid"
                />
                <h2 className="main_title text-center">Subscribe Newsletter</h2>
                <p className="inner_para text-center">
                  With a sleek and user-friendly interface backed by the security and efficiency of blockchain, the Queenbots platform is easy to use and intuitive.
                </p>
                <input
                  className="form-control primary_inp"
                  placeholder="Enter Your Email"
                  onChange={(e) => setemail(e.target.value)}
                />
                <div className="text-center">
                  <button className="primary_btn" onClick={handlesubm}>
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Newsletter;

import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import DataTable from "react-data-table-component";
import moment from "moment";
import { withdrawBUSDFunc, claimContractFunc } from "./BuyTokenNFC.js";
import { useDispatch, useSelector } from "react-redux";
import config from "../lib/config/config";
import { toast } from "react-toastify";
import {
  getReferralHistory1,
  getReferralHistory,
  getNFCBalanceApi,
  signatureUserApi,
  getAllHistoryApi,
  getPendingSignaturesApi,
} from "../Actions/user";
import { DepositValidations } from "./Validations.js";
import ReactLoading from "react-loading";

let toasterOption = config.toasterOption;

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Withdraw(props) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const [isLoader, setLoader] = useState(false);
  const [busdNfcBal, setBusdNfcBal] = useState(0);
  const [cardNumber, setCardnumber] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [inputs, setInputs] = useState("");
  const [historyList, setHistoryList] = useState([]);
  const [historyList1, setHistoryList1] = useState([]);
  const [nfcuserstatus, setNFCStatus] = useState("");

  useEffect(() => {
    referralPerFunc();
    NFCBALANCE();
    getTransactions();
    referralPerFunc1();
  }, [walletConnection?.address, cardNumber]);

  useEffect(() => {
    if (userDetails.length != 0) {
      final();
    }
  }, [userDetails]);

  //Card Details (kycstatus)
  const final = () => {
    if (userDetails != "") {
      userDetails.find((val) => {
        setCardnumber(val.cardNumber);
      });
    }
  };

  const referralPerFunc1 = async () => {
    try {
      let params = {
        address: walletConnection.address,
      };
      const { respData } = await getReferralHistory(params);
      setLoader(false);
      if (respData?.data?.user[0].length != 0) {
        setNFCStatus(respData?.data?.user[0]?.cardNo);
      }
    } catch (err) {
      setLoader(false);
      console.log(err, "referralPerFunc err");
    }
  };

  const getTransactions = async () => {
    setLoader(true);
    let params = {
      address: walletConnection.address,
    };
    try {
      const { status, result } = await getAllHistoryApi(params);
      console.log(result, "hdguerghtb");
      setLoader(false);
      if (status) {
        setHistoryList(result?.withdraw);
      }
      console.log(params, "kjiyuwbhfns");

      const { status1, result1 } = await getPendingSignaturesApi(params);
      console.log(result1, "status1, result1 ");
      setLoader(false);
      if (status1) {
        if (result1) {
          setHistoryList1(result1);
        } else {
          setHistoryList1([]);
        }
      }
    } catch (err) {
      setLoader(false);
      console.log("errerr", err);
    }
  };

  //UserDetails
  const referralPerFunc = async () => {
    // setLoader(true);

    try {
      let params = {
        address: walletConnection?.address,
      };
      const { respData } = await getReferralHistory1(params);
      setLoader(false);
      respData != null && setUserDetails(respData.data.user);
    } catch (err) {
      setLoader(false);
      console.log(err, "Withdraw in referralPerFunc err");
    }
  };

  const NFCBALANCE = async () => {
    // setLoader(true);
    try {
      let params = {
        cardNumber: cardNumber,
      };
      const { status, result, message } = await getNFCBalanceApi(params);
      console.log(status, result, message, "status, result, message");
      setLoader(false);
      if (status) {
        setBusdNfcBal(result?.busd);
      }
    } catch (err) {
      setLoader(false);
      console.log(err, "eee NFCBALANCE");
    }
  };

  const validPositive = (e) => {
    console.log(
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value),
      "valllluee"
    );
    if (
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  const OnSubmit = async () => {
    let wallet = walletConnection.address ? walletConnection?.address : "";
    if (inputs != "") {
      let data = {
        amount: inputs,
        wallet,
        balance: busdNfcBal,
      };
      var { status } = await DepositValidations(data);
      if (status) {
        buyToken();
      } else {
        console.log("errr");
      }
    } else {
      toast.error("Enter USDT amount !", toasterOption);
    }
  };

  async function buyToken() {
    try {
      var wallet = walletConnection.address ? walletConnection?.address : "";
      var amount = inputs;

      if (busdNfcBal >= amount) {
        // if (stausFromApproval.approveStatus == 1) {
        let signParams = {
          address: wallet,
          Busd: inputs,
        };

        setLoader(true);
        const { status, result, message } = await signatureUserApi(signParams);
        setLoader(false);
        if (status) {
          let withdrawParams = {
            address: wallet,
            amount: inputs,
            signature: result?.signature,
            nonce: result?.nonce,
          };

          await withdrawBusdContractCall(withdrawParams);
        } else {
          setLoader(false);
          toast.error(message, toasterOption);
        }
      } else {
        setLoader(false);
        toast.error("User balance is low", toasterOption);
      }
    } catch (err) {
      setLoader(false);
      toast.error("Transaction Rejected Resubmit Again", toasterOption);
    }
  }

  const withdrawBusdContractCall = async (params) => {
    setLoader(true);
    const { status, transactionHash } = await withdrawBUSDFunc(params);
    if (status == true) {
      setLoader(false);
      setInputs("");
      toast.success("Withdraw Successfully", toasterOption);
      NFCBALANCE();
      // setTimeout(() => {
      //   window.location.reload();
      // }, 3000);
    } else {
      setLoader(false);
      toast.error("Transaction Rejected Resubmit Again", toasterOption);
    }
  };
  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  const columns1 = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
    },
    {
      name: "Date",
      selector: (row) => moment(row?.createdAt).format("LLL"),
    },
    {
      name: "Transaction Hash",
      selector: (row) => (
        <a
          style={{ color: "white" }}
          target="_blank"
          href={`https://testnet.bscscan.com/tx/${row?.Hash}`}
        >
          {halfAddrShow(row?.Hash)}
        </a>
      ),
    },
    {
      name: "Amount",
      selector: (row) => row?.Busd,
    },
    {
      name: "Send Address",
      selector: (row) => halfAddrShow(row?.address),
    },

    {
      name: "status",
      selector: (row) => <p style={{ color: "green" }}>{row?.status}</p>,
    },
  ];

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
    },
    {
      name: "Date",
      selector: (row) => moment(row?.createdAt).format("LLL"),
    },
    {
      name: "Amount",
      selector: (row) => row?.amount / 1e18,
    },
    {
      name: "Address",
      selector: (row) => halfAddrShow(row?.address),
    },
    {
      name: "Signature",
      selector: (row) => halfAddrShow(row?.signature),
    },
    {
      name: "Action",
      selector: (row) => (
        <button
          className="primary_btn m-0 mb-3 pad"
          onClick={() => {
            claimFunc(row);
          }}
        >
          Claim
        </button>
      ),
    },
  ];

  const claimFunc = async (data) => {
    setLoader(true);
    let claimParams = {
      address: walletConnection.address,
      amount: data?.amount,
      signature: data?.signature,
      nonce: data?.nonce,
      type: data?.type,
    };
    console.log(claimParams, "claimParams");

    const { status } = await claimContractFunc(claimParams);
    console.log(status, "claimParams");

    setLoader(false);
    if (status) {
      toast.success("Claimed successfully", toasterOption);
      if (walletConnection.address != "") {
        getTransactions(walletConnection.address);
      }
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      setLoader(false);
      toast.error("Failed to Claim", toasterOption);
    }
  };

  return (
    <div>
      {isLoader == true && (
        <ReactLoading type={"spin"} color={"#000000"} className="loading" />
      )}
      <div>
        <ScrollToTopOnMount />
        <Navbar />
        <div className="refferal_ban">
          <div className="container">
            <h2 class="main_title mb-0">Withdraw</h2>
            {/* <h5 class="sub_title mb-5">
            Cras eleifend vehicula lectus id porta.
          </h5> */}
          </div>
          <div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
          </div>
        </div>

        <section className="deposit_box">
          <div className="container">
            <div className="earn reff_box">
              <div className="form_flx d-block">
                <div className="w-100">
                  <div className="labl_flx mb-3">
                    <span>Withdraw Amount</span>
                    <span>
                      Balance Amount:{" "}
                      <sapn>
                        {" "}
                        {busdNfcBal ? parseFloat(busdNfcBal).toFixed(3) : 0}
                      </sapn>{" "}
                      USDT
                    </span>
                  </div>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter USDT Amount"
                      onInput={validPositive}
                      onChange={(e) => setInputs(e.target.value)}
                      value={inputs}
                    />
                    <span class="input-group-text bdr" id="basic-addon2">
                    USDT
                    </span>
                    <span class="input-group-text" id="basic-addon2">
                      <button
                        className="primary_btn m-0"
                        onClick={() => setInputs(busdNfcBal.toString())}
                      >
                        MAX
                      </button>
                    </span>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  className="primary_btn m-0 mb-3 pad"
                  onClick={() => {
                    if (nfcuserstatus != "") {
                      OnSubmit();
                    } else {
                      toast.warning("Activate NFC card", toasterOption);
                    }
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="history_tabs">
          <div className="container">
            <div className="refferal transaction_his">
              <div className="box_bdr">
                <div className="flx d-flex">
                  <h5 className="sub_title  mb-0 ps-1">Wihdraw History</h5>
                  {/* <select className="select_box" >
                    <option className="d-none">Filter</option>
                    <option>completed</option>
                    <option>Pending</option>
                  </select> */}
                </div>
                <DataTable columns={columns1} data={historyList} pagination />
              </div>
            </div>
          </div>
        </section>

        {console.log(historyList1, "historyList1")}
        <section className="history_tabs">
          <div className="container">
            <div className="refferal transaction_his">
              <div className="box_bdr">
                <div className="flx d-flex">
                  <h5 className="sub_title  mb-0 ps-1">
                    Cancelled Transaction
                  </h5>
                  {/* <select className="select_box" >
                    <option className="d-none">Filter</option>
                    <option>completed</option>
                    <option>Pending</option>
                  </select> */}
                </div>
                <DataTable columns={columns} data={historyList1} pagination />
              </div>
            </div>
          </div>
        </section>

        {/* <Newsletter /> */}
        {/* <section className="subscribe_section mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="subscribe_div">
                <img
                  src={require("../assets/images/subscribe_img.png")}
                  alt="Queenbots"
                  className="img-fluid"
                />
                <h2 className="main_title text-center">Subscribe Newsletter</h2>
                <p className="inner_para text-center">
                  Quisque sollicitudin lectus auctor, porta orci rutrum,
                  vehicula ipsum. Praesent aliquam neque mi, nec dapibus tellus
                  porta sed.{" "}
                </p>
                <input
                  className="form-control primary_inp"
                  placeholder="Enter Your Email"
                />
                <div className="text-center">
                  <button className="primary_btn">Subscribe</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

        <Footer />
      </div>
    </div>
  );
}

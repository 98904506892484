import Web3 from "web3";
import ContractABI from "../ABI/DexbotABI.json";
import QBTContractABI from "../ABI/QBTContarctABI.json";
import { toast } from "react-toastify";

import { getprovider } from ".././pages/seperate/provider";
import config from "../lib/config/config";
let toasterOption = config.toasterOption;

export function StakeValidations(data) {
  var status = true;

  if (data.amount == "") {
    toast.error("Enter amount", toasterOption);

    status = false;
  } else if (data.amount <= 0) {
    toast.error("Enter valid amount", toasterOption);

    status = false;
  } else if (parseFloat(data.balance) < parseFloat(data.amount)) {
    toast.error("Insufficient Balance", toasterOption);

    status = false;
  }

  if (!data.toggleCheckBox) {
    toast.error("Please agree to the QueenBot Staking Service", toasterOption);

    status = false;
  }

  if (data.wallet == "") {
    toast.error("Please login your wallet", toasterOption);

    status = false;
  }

  return {
    status,
  };
}

export async function DepositValidations(data) {
  var status = true;
  if (data.amount == "") {
    toast.error("Enter amount", toasterOption);
    status = false;

  } else if (data.amount <= 0) {
    toast.error("Enter valid amount", toasterOption);
    status = false;

  } else if (parseFloat(data.balance) < parseFloat(data.amount)) {
    toast.error("Insufficient Balance", toasterOption);
    status = false;
  }

  if (data.wallet == "") {
    toast.error("Please login your wallet", toasterOption);
    status = false;
  }

  return {
    status,
  };
}

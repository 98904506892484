import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import DataTable from "react-data-table-component";
import {
  getTokenBalance,
  getTokenBalanceNFC,
  sendTokenTransactionFunc,
} from "./BuyTokenNFC.js";
import { useDispatch, useSelector } from "react-redux";
import config from "../lib/config/config";
import { toast } from "react-toastify";
import {
  depositBusdApi,
  getAllHistoryApi,
  getReferralHistory,
} from "../Actions/user";
import { DepositValidations } from "./Validations.js";
import ReactLoading from "react-loading";
import moment from "moment";

let toasterOption = config.toasterOption;

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Deposit(props) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const [isLoader, setLoader] = useState(false);

  const [BUSDbalance, setBUSDbalance] = useState({
    busd: "",
  });
  const [userBal, setuserBal] = useState({ tokenBalance: 0 });

  const [inputs, setInputs] = useState("");
  const [historyList, setHistoryList] = useState([]);
  const [nfcuserstatus, setNFCStatus] = useState("");

  const getBalanceDetails = async () => {
    let balanceDetails = await getTokenBalanceNFC();
    setBUSDbalance({
      busd: balanceDetails.balance,
    });
    setuserBal({ tokenBalance: balanceDetails.balance });
  };

  // async function getBusdBalance() {
  //   var { balance } = await getTokenBalance();
  //   setuserBal({ tokenBalance: balance });
  // }

  const validPositive = (e) => {
    console.log(
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value),
      "valllluee"
    );
    if (
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    getBalanceDetails();
    // getBusdBalance();
    getTransactions();
    referralPerFunc();
  }, [walletConnection]);

  const referralPerFunc = async () => {
    try {
      let params = {
        address: walletConnection.address,
      };
      const { respData } = await getReferralHistory(params);
      setLoader(false);
      if (respData?.data?.user[0].length != 0) {
        setNFCStatus(respData?.data?.user[0]?.cardNo);
      }
    } catch (err) {
      setLoader(false);
      console.log(err, "referralPerFunc err");
    }
  };

  const getTransactions = async () => {
    setLoader(true);
    let params = {
      address: walletConnection.address,
    };
    try {
      const { status, result } = await getAllHistoryApi(params);
      console.log(result, "hdguerghtb");
      setLoader(false);
      if (status) {
        setHistoryList(result?.deposit);
      }
    } catch (err) {
      setLoader(false);
      console.log("errerr", err);
    }
  };

  const OnSubmit = async () => {
    if (inputs != "") {
      let data = {
        amount: inputs,
        wallet: walletConnection?.address,
        balance: userBal.tokenBalance,
      };
      var { status } = await DepositValidations(data);
      if (status) {
        buyToken();
      } else {
        console.log("errr");
      }
    } else {
      toast.error("Enter USDT amount !", toasterOption);
    }
  };

  async function buyToken() {
    setLoader(true);
    try {
      let wallet = walletConnection.address ? walletConnection.address : "";
      let amount = inputs;
      let merchant = config.ContractAddress;
      let data = {
        merchant,
        amount,
        wallet,
      };

      if (userBal.tokenBalance >= amount) {
        var { status, transactionHash } = await sendTokenTransactionFunc(data);
        console.log(status, transactionHash, "status, messagehygue");

        if (status == true) {
          //api call here
          let params = {
            from: wallet,
            hash: transactionHash,
            amount: amount,
          };

          console.log(params, "params");
          const { status, message } = await depositBusdApi(params);
          console.log(status, message, "status, messagehygue");
          setLoader(false);
          if (status) {
            setInputs("");
            getBalanceDetails();
            toast.success("Successfully Deposited", toasterOption);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            toast.error(message, toasterOption);
            setLoader(false);
          }
        } else {
          // if (err && err == "insuffcient balance") {
          //   setLoader(false);
          //   toast.error("Insuffcient gas fees", toasterOption);
          // } else {
          setLoader(false);
          toast.error("Transaction Rejected Resubmit Again", toasterOption);

          // }
        }
      } else {
        setLoader(false);
        toast.error("User balance is low", toasterOption);
      }
    } catch (err) {
      setLoader(false);
      toast.error("Transaction Rejected Resubmit Again", toasterOption);
    }
  }

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  const columns1 = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
    },
    {
      name: "Date",
      selector: (row) => moment(row?.createdAt).format("LLL"),
    },
    {
      name: "Transaction Hash",
      selector: (row) => (
        <a style={{color: "white"}} target="_blank" href={`https://testnet.bscscan.com/tx/${row?.Hash}`}>
          {halfAddrShow(row?.Hash)}
        </a>
      ),
    },
    {
      name: "Amount",
      selector: (row) => row?.Busd,
    },
    {
      name: "Send Address",
      selector: (row) => halfAddrShow(row?.address),
    },

    {
      name: "status",
      selector: (row) => <p style={{ color: "green" }}>{row?.status}</p>,
    },
  ];

  return (
    <div>
      {isLoader == true && (
        <ReactLoading type={"spin"} color={"#000000"} className="loading" />
      )}
      <div>
        <ScrollToTopOnMount />
        <Navbar />
        <div className="refferal_ban">
          <div className="container">
            <h2 class="main_title mb-0">Deposit</h2>
            {/* <h5 class="sub_title mb-5">
            Cras eleifend vehicula lectus id porta.
          </h5> */}
          </div>
          <div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
            <div class="snow"></div>
          </div>
        </div>

        <section className="deposit_box">
          <div className="container">
            <div className="earn reff_box">
              <div className="form_flx d-block">
                <div className="w-100">
                  <div className="labl_flx mb-3">
                    <span>Deposit Amount</span>
                    <span>
                      Balance Amount:{" "}
                      <sapn>
                        {" "}
                        {BUSDbalance.busd
                          ? parseFloat(BUSDbalance.busd).toFixed(3)
                          : 0}
                      </sapn>{" "}
                      USDT
                    </span>
                  </div>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter USDT Amount"
                      onInput={validPositive}
                      onChange={(e) => setInputs(e.target.value)}
                      value={inputs}
                    />
                    <span class="input-group-text bdr" id="basic-addon2">
                    USDT
                    </span>
                    <span class="input-group-text" id="basic-addon2">
                      <button
                        className="primary_btn m-0"
                        onClick={() => setInputs(BUSDbalance.busd.toString())}
                      >
                        MAX
                      </button>
                    </span>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  className="primary_btn m-0 mb-3 pad"
                  onClick={() => {
                    if (nfcuserstatus != "") {
                      OnSubmit();
                    } else {
                      toast.warning("Activate NFC card", toasterOption);
                    }
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="history_tabs">
          <div className="container">
            <div className="refferal transaction_his">
              <div className="box_bdr">
                <div className="flx d-flex">
                  <h5 className="sub_title  mb-0 ps-1">Deposit History</h5>
                  {/* <select className="select_box" >
                    <option className="d-none">Filter</option>
                    <option>completed</option>
                    <option>Pending</option>
                  </select> */}
                </div>
                <DataTable columns={columns1} data={historyList} pagination />
              </div>
            </div>
          </div>
        </section>

        {/* <Newsletter /> */}
        {/* <section className="subscribe_section mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="subscribe_div">
                <img
                  src={require("../assets/images/subscribe_img.png")}
                  alt="Queenbots"
                  className="img-fluid"
                />
                <h2 className="main_title text-center">Subscribe Newsletter</h2>
                <p className="inner_para text-center">
                  Quisque sollicitudin lectus auctor, porta orci rutrum,
                  vehicula ipsum. Praesent aliquam neque mi, nec dapibus tellus
                  porta sed.{" "}
                </p>
                <input
                  className="form-control primary_inp"
                  placeholder="Enter Your Email"
                />
                <div className="text-center">
                  <button className="primary_btn">Subscribe</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

        <Footer />
      </div>
    </div>
  );
}
